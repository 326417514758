import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acStartStormwaterListener} from 'state/firestore/actions.js';

const useStormwaterDocs = () => {
  const dispatch = useDispatch();
  const stormwaterDocs = useSelector(state => state.firestore.stormwater);

  useEffect(() => {
    if (!stormwaterDocs) dispatch(acStartStormwaterListener);
  }, [dispatch, stormwaterDocs]);

  return stormwaterDocs;
};

export default useStormwaterDocs;
