/* eslint-disable no-useless-computed-key */
import React, {useMemo, useState} from 'react';
import {ChevronLeft, ChevronRight, ExpandMore, Check} from '@material-ui/icons';
import {
  Accordion,
  AccordionSummary,
  Typography,
  Box,
  makeStyles,
  Button,
  Grid,
} from '@material-ui/core';
import moment from 'moment';
import {useInspections} from 'hooks/';

function determineDateToShow(indexOfDay, weekIndex) {
  // only 3 weeks of data will be visible on this chart. this returns the string used by the table as the weekday header.
  return moment().day(indexOfDay - (7 * (2 - weekIndex))).format('dddd M/D');
}
// this must be updated if a new daily form is added to the list of inspecitons.
// it is used as a guide to create the data displayed on the table
const defaultDataMap = {
  [moment().day(-13).format('MMDDYY')]: {
    'Daily Catalyst Area Inspection': {
      FormName: 'Daily Catalyst Area Inspection',
      ['1']: false,
      ['2']: false,
      ['3']: false,
      ['4']: false,
      ['5']: false,
      ['6']: false,
      ['0']: false,
    },
    'Daily Lead Acid Battery Area Inspection': {
      FormName: 'Daily Lead Acid Battery Area Inspection',
      ['1']: false,
      ['2']: false,
      ['3']: false,
      ['4']: false,
      ['5']: false,
      ['6']: false,
      ['0']: false,
    },
    'Daily Maintenance Area Inspection': {
      FormName: 'Daily Maintenance Area Inspection',
      ['1']: false,
      ['2']: false,
      ['3']: false,
      ['4']: false,
      ['5']: false,
      ['6']: false,
      ['0']: false,
    },
    'Daily Non-Lead Acid Area Inspection': {
      FormName: 'Daily Non-Lead Acid Area Inspection',
      ['1']: false,
      ['2']: false,
      ['3']: false,
      ['4']: false,
      ['5']: false,
      ['6']: false,
      ['0']: false,
    },
    'Daily Precious Metals Area Inspection': {
      FormName: 'Daily Precious Metals Area Inspection',
      ['1']: false,
      ['2']: false,
      ['3']: false,
      ['4']: false,
      ['5']: false,
      ['6']: false,
      ['0']: false,
    },
    'Daily Shipping & Receiving Area Inspection': {
      FormName: 'Daily Shipping & Receiving Area Inspection',
      ['1']: false,
      ['2']: false,
      ['3']: false,
      ['4']: false,
      ['5']: false,
      ['6']: false,
      ['0']: false,
    },
    'Daily Wastewater Treatment Area Inspection': {
      FormName: 'Daily Wastewater Treatment Area Inspection',
      ['1']: false,
      ['2']: false,
      ['3']: false,
      ['4']: false,
      ['5']: false,
      ['6']: false,
      ['0']: false,
    },
  },
  [moment().day(-6).format('MMDDYY')]: {
    'Daily Catalyst Area Inspection': {
      FormName: 'Daily Catalyst Area Inspection',
      ['1']: false,
      ['2']: false,
      ['3']: false,
      ['4']: false,
      ['5']: false,
      ['6']: false,
      ['0']: false,
    },
    'Daily Lead Acid Battery Area Inspection': {
      FormName: 'Daily Lead Acid Battery Area Inspection',
      ['1']: false,
      ['2']: false,
      ['3']: false,
      ['4']: false,
      ['5']: false,
      ['6']: false,
      ['0']: false,
    },
    'Daily Maintenance Area Inspection': {
      FormName: 'Daily Maintenance Area Inspection',
      ['1']: false,
      ['2']: false,
      ['3']: false,
      ['4']: false,
      ['5']: false,
      ['6']: false,
      ['0']: false,
    },
    'Daily Non-Lead Acid Area Inspection': {
      FormName: 'Daily Non-Lead Acid Area Inspection',
      ['1']: false,
      ['2']: false,
      ['3']: false,
      ['4']: false,
      ['5']: false,
      ['6']: false,
      ['0']: false,
    },
    'Daily Precious Metals Area Inspection': {
      FormName: 'Daily Precious Metals Area Inspection',
      ['1']: false,
      ['2']: false,
      ['3']: false,
      ['4']: false,
      ['5']: false,
      ['6']: false,
      ['0']: false,
    },
    'Daily Shipping & Receiving Area Inspection': {
      FormName: 'Daily Shipping & Receiving Area Inspection',
      ['1']: false,
      ['2']: false,
      ['3']: false,
      ['4']: false,
      ['5']: false,
      ['6']: false,
      ['0']: false,
    },
    'Daily Wastewater Treatment Area Inspection': {
      FormName: 'Daily Wastewater Treatment Area Inspection',
      ['1']: false,
      ['2']: false,
      ['3']: false,
      ['4']: false,
      ['5']: false,
      ['6']: false,
      ['0']: false,
    },
  },
  [moment().day(1).format('MMDDYY')]: {
    'Daily Catalyst Area Inspection': {
      FormName: 'Daily Catalyst Area Inspection',
      ['1']: false,
      ['2']: false,
      ['3']: false,
      ['4']: false,
      ['5']: false,
      ['6']: false,
      ['0']: false,
    },
    'Daily Lead Acid Battery Area Inspection': {
      FormName: 'Daily Lead Acid Battery Area Inspection',
      ['1']: false,
      ['2']: false,
      ['3']: false,
      ['4']: false,
      ['5']: false,
      ['6']: false,
      ['0']: false,
    },
    'Daily Maintenance Area Inspection': {
      FormName: 'Daily Maintenance Area Inspection',
      ['1']: false,
      ['2']: false,
      ['3']: false,
      ['4']: false,
      ['5']: false,
      ['6']: false,
      ['0']: false,
    },
    'Daily Non-Lead Acid Area Inspection': {
      FormName: 'Daily Non-Lead Acid Area Inspection',
      ['1']: false,
      ['2']: false,
      ['3']: false,
      ['4']: false,
      ['5']: false,
      ['6']: false,
      ['0']: false,
    },
    'Daily Precious Metals Area Inspection': {
      FormName: 'Daily Precious Metals Area Inspection',
      ['1']: false,
      ['2']: false,
      ['3']: false,
      ['4']: false,
      ['5']: false,
      ['6']: false,
      ['0']: false,
    },
    'Daily Shipping & Receiving Area Inspection': {
      FormName: 'Daily Shipping & Receiving Area Inspection',
      ['1']: false,
      ['2']: false,
      ['3']: false,
      ['4']: false,
      ['5']: false,
      ['6']: false,
      ['0']: false,
    },
    'Daily Wastewater Treatment Area Inspection': {
      FormName: 'Daily Wastewater Treatment Area Inspection',
      ['1']: false,
      ['2']: false,
      ['3']: false,
      ['4']: false,
      ['5']: false,
      ['6']: false,
      ['0']: false,
    },
  },
};
function reduceDataMapForTable(dataMap) {
  return Object.values(dataMap).map(week => Object.values(week));
}
function formatInspectionsIntoDataMap(inspecitonList, dataMap) {
  inspecitonList.forEach(inspection => {
    // frequency of 0 is daily, only completed forms will be shown
    if (inspection.Frequency === 0 && inspection.Completed) {
      // the weeks are from 1, -6, and -13 because they are from monday-sunday as opposed to sunday-saturday
      if (moment(inspection.FormData.Date).isSameOrAfter(moment().day(1), 'day')) {
        dataMap[moment().day(1).format('MMDDYY')][inspection.FormTitle][moment(inspection.FormData.Date).day()] = true;
      }
      else if (moment(inspection.FormData.Date).isSameOrAfter(moment().day(-6), 'day')) {
        dataMap[moment().day(-6).format('MMDDYY')][inspection.FormTitle][moment(inspection.FormData.Date).day()] = true;
      }
      else if (moment(inspection.FormData.Date).isSameOrAfter(moment().day(-13), 'day')) {
        dataMap[moment().day(-13).format('MMDDYY')][inspection.FormTitle][moment(inspection.FormData.Date).day()] = true;
      }
    }
  });
  return reduceDataMapForTable(dataMap);
}

const DailyInspectionPanel = () => {
  const [weekIndex, setWeekIndex] = useState(2);
  const styles = useStyles();
  const inspections = useInspections();

  const dataToShow = useMemo(() => {
    return formatInspectionsIntoDataMap(inspections.list, {...defaultDataMap});
  }, [inspections]);

  return <Accordion defaultExpanded={true}>
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Typography variant='h6'>Daily Inspection Summary</Typography>
    </AccordionSummary>
    <Box className={styles.column} width={6/20}>
      <Typography align='center' variant='body2' classes={{root: styles.bold}}>
        Form Name
      </Typography>
    </Box>
    <Box className={styles.column} width={2/20}>
      <Typography align='center' variant='body2' classes={{root: styles.bold}}>
        {determineDateToShow(1, weekIndex)}
      </Typography>
    </Box>
    <Box className={styles.column} width={2/20}>
      <Typography align='center' variant='body2' classes={{root: styles.bold}}>
        {determineDateToShow(2, weekIndex)}
      </Typography>
    </Box>
    <Box className={styles.column} width={2/20}>
      <Typography align='center' variant='body2' classes={{root: styles.bold}}>
        {determineDateToShow(3, weekIndex)}
      </Typography>
    </Box>
    <Box className={styles.column} width={2/20}>
      <Typography align='center' variant='body2' classes={{root: styles.bold}}>
        {determineDateToShow(4, weekIndex)}
      </Typography>
    </Box>
    <Box className={styles.column} width={2/20}>
      <Typography align='center' variant='body2' classes={{root: styles.bold}}>
        {determineDateToShow(5, weekIndex)}
      </Typography>
    </Box>
    <Box className={styles.column} width={2/20}>
      <Typography align='center' variant='body2' classes={{root: styles.bold}}>
        {determineDateToShow(6, weekIndex)}
      </Typography>
    </Box>
    <Box className={styles.column} width={2/20}>
      <Typography align='center' variant='body2' classes={{root: styles.bold}}>
        {determineDateToShow(7, weekIndex)}
      </Typography>
    </Box>
    {dataToShow[weekIndex].map(data => (
      <Grid container justify='center' key={data.FormName} classes={{root: styles.topBorder}}>
        <Box className={styles.column} width={6/20}>
          <Typography align='center' variant='body2' classes={{root: styles.paddingTop}}>
            {data.FormName}
          </Typography>
        </Box>
        <Box className={styles.column} width={2/20}>
          <Grid container justify='center' alignItems='center'>
            {data['1'] ? <Check size='small' /> : null}
          </Grid>
        </Box>
        <Box className={styles.column} width={2/20}>
          <Grid container justify='center' alignItems='center'>
            {data['2'] ? <Check size='small' /> : null}
          </Grid>
        </Box>
        <Box classes={{root: styles.column}} width={2/20}>
          <Grid container justify='center' alignItems='center'>
            {data['3'] ? <Check size='small' /> : null}
          </Grid>
        </Box>
        <Box className={styles.column} width={2/20}>
          <Grid container justify='center' alignItems='center'>
            {data['4'] ? <Check size='small' /> : null}
          </Grid>
        </Box>
        <Box className={styles.column} width={2/20}>
          <Grid container justify='center' alignItems='center'>
            {data['5'] ? <Check size='small' /> : null}
          </Grid>
        </Box>
        <Box className={styles.column} width={2/20}>
          <Grid container justify='center' alignItems='center'>
            {data['6'] ? <Check size='small' /> : null}
          </Grid>
        </Box>
        <Box className={styles.column} width={2/20}>
          <Grid container justify='center' alignItems='center'>
            {data['0'] ? <Check size='small' /> : null}
          </Grid>
        </Box>
      </Grid>
    ))}
    <Grid container justify='center' classes={{root: styles.topBorder}}>
      <Button onClick={() => setWeekIndex(weekIndex - 1)} disabled={weekIndex === 0}><ChevronLeft /></Button>
      <Button onClick={() => setWeekIndex(weekIndex + 1)} disabled={weekIndex === 2}><ChevronRight /></Button>
    </Grid>
  </Accordion>;
};

const useStyles = makeStyles(theme => ({
  column: {
    display: 'inline-block',
    minHeight: '20px',
    padding: '4px',
  },
  topBorder: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  bold: {
    fontWeight: '600',
  },
  paddingTop: {
    paddingTop: '2px',
  },
}));

export default DailyInspectionPanel;
