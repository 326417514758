import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography, AccordionActions} from '@material-ui/core';
import {useUsers} from 'hooks/';
import {StyledTextField} from 'components/';
import {NewCapaModal} from '../CorrectiveActions/';
import {ExpandMore} from '@material-ui/icons';
import {useHistory} from 'react-router-dom';

const CapaPanel = ({selectedRootCause}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const history = useHistory();
  useUsers();

  const navigateToCapa = (e, capa)=> {
    e.stopPropagation();
    history.push(`/corrective-actions/${capa.Ref}`);
  };

  return <Accordion defaultExpanded={false}>
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Typography>Supplemental CAPA&apos;s</Typography>
    </AccordionSummary>
    <AccordionDetails>
      {selectedRootCause.SupplementalCapas.length ? (
        <Grid container spacing={2}>
          {selectedRootCause.SupplementalCapas.map(capa => (
            <Grid container spacing={2} key={capa.Ref} style={{margin: '8px', border: '1px solid lightgrey'}}>

              <Fragment>
                <Grid item xs={6} sm={3}>
                  <StyledTextField value={capa.Number} label='CAPA Number' isLink onClick={(e) => navigateToCapa(e, capa)} />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <StyledTextField value={capa.Location} label='Location' />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextField value={capa.Assignees} label='Assignees' />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField value={capa.Observation} label='Observation' />
                </Grid>
              </Fragment>

            </Grid>
          ))}
        </Grid>) :
        <Typography>There are no Supplemental CAPA&apos;s</Typography>
      }
    </AccordionDetails>
    <AccordionActions>
      {selectedRootCause.Status !== 'Completed' ? <Button variant='outlined' onClick={() => setModalOpen(true)} color='primary'>Create Supplemental CAPA</Button> : null}
    </AccordionActions>
    {modalOpen && <NewCapaModal selectedRootCause={selectedRootCause} close={() => setModalOpen(false)} />}
  </Accordion>;
};

CapaPanel.propTypes = {
  selectedRootCause: PropTypes.object.isRequired,
};

export default CapaPanel;
