import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {SubmitConfirmation} from 'components/';
import {Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText} from '@material-ui/core';
import {Alert, Collapse, Formik} from '@kbi/component-library';
import * as yup from 'yup';
import {useSelector} from 'react-redux';
import {useAxios} from 'hooks/';
const {FormikForm, SubmitButton, FormButton} = Formik;

const MarkAsCompleteModal = ({close, selectedRootCause}) => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');

  const {axios, getAuthToken, appEngine} = useAxios();
  const currentUser = useSelector(state => state.auth.currentUser);

  const formikProps = {
    initialValues: {
      analysis: selectedRootCause?.Analysis || '',
      additionalComments: selectedRootCause?.AdditionalComments || '',
    },
    validationSchema: yup.object().shape({
      analysis: yup.string().required('Analysis is a required field.'),
      additionalComments: yup.string(),
    }),
    onSubmit: async (values, actions) => {
      try {
        const dataForFirestore = createDataForFirestore();

        await axios({
          method: 'post',
          url: `${appEngine}/ehs/firestore/UpdateRootCauseAnalysis`,
          withCredentials: true,
          headers: {
            'Authorization': await getAuthToken(),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          data: {
            formData: dataForFirestore,
            currentUser,
            docId: selectedRootCause.RootCauseId,
          },
        });

        setFormError('');
        setStage(stage + 1);
      }
      catch (err) {
        setFormError('There was an error during submission. Please try again.');
      }
    },
  };

  return (
    <Dialog open={true} maxWidth='sm' fullWidth scroll='body' transitionDuration={{exit: 0}}>
      <FormikForm {...formikProps}>
        {({values}) => (
          <Fragment>
            {stage !== 1 && <DialogTitle>Mark As Complete</DialogTitle>}
            <DialogContent>
              <Collapse in={stage === 0}>
                <DialogContentText>
                  This will lock the Analysis from being editted any further. Only continue if there is no more information to be added.
                </DialogContentText>
              </Collapse>
              <SubmitConfirmation stage={stage === 1 ? 'success' : ''} text='Root Cause Analysis successfully completed.' />
              <Alert in={Boolean(formError)} text={formError} severity='error' />
            </DialogContent>
            {stage === 0 ? (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton variant='text' color='secondary' onClick={close}>Cancel</FormButton>
                <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
              </DialogActions>
            ) : (
              <DialogActions style={{justify: 'space-between'}}>
                <FormButton variant='text' color='primary' onClick={close}>Close</FormButton>
              </DialogActions>
            )}
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

MarkAsCompleteModal.propTypes = {
  close: PropTypes.func.isRequired,
  selectedRootCause: PropTypes.object,
};

export default MarkAsCompleteModal;

function createDataForFirestore() {
  return {
    Status: 'Completed',
  };
}
