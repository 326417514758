import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acStartStormwaterFilesListener} from 'state/firestore/actions.js';

const useStormwaterFiles = () => {
  const dispatch = useDispatch();
  const stormwaterFiles = useSelector(state => state.firestore.stormwaterFiles);

  useEffect(() => {
    if (!stormwaterFiles) dispatch(acStartStormwaterFilesListener);
  }, [dispatch, stormwaterFiles]);

  return stormwaterFiles;
};

export default useStormwaterFiles;
