import React from 'react';
import PropTypes from 'prop-types';
import {withStyles, TextField} from '@material-ui/core';

const StyledTextField = ({isLink, ...props}) => {
  return isLink ?
    <LinkTextField variant='outlined' color='primary' margin='dense' fullWidth multiline inputProps={{readOnly: true}} {...props} /> :
    <MaterialTextField variant='outlined' margin='dense' fullWidth multiline {...props} />;
};

const MaterialTextField = withStyles({
  root: {
    backgroundColor: 'white',
    cursor: 'default',
    fontSize: '0.9rem',
    pointerEvents: 'none',
  },
})(TextField);

const LinkTextField = withStyles({
  'root': {
    'backgroundColor': 'white',
    'cursor': 'pointer',
    'fontSize': '0.9rem',
    '& div': {
      cursor: 'pointer',
    },
    '& textarea': {
      color: '#0000EE',
      cursor: 'pointer',
    },
  },
})(TextField);

StyledTextField.propTypes = {
  isLink: PropTypes.bool,
};

export default StyledTextField;
