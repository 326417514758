import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {SubmitConfirmation} from 'components/';
import {Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Grid, Button} from '@material-ui/core';
import {Clear} from '@material-ui/icons';
import {Alert, Collapse, Formik} from '@kbi/component-library';
import * as yup from 'yup';
import {useSelector} from 'react-redux';
import {FieldArray} from 'formik';
import {useAxios} from 'hooks/';
import get from 'lodash.get';
import {directCauses} from './Consts/';
const {FormikForm, SubmitButton, FormButton, TextField, AutoCompleteValue} = Formik;

yup.addMethod(yup.object, 'uniqueProperty', function(propertyName, message, fieldName) {
  return this.test('unique', message, function(value) {
    if (!value || !get(value, propertyName)) {
      return true;
    }
    if (
      this.parent
        .filter(v => v !== value)
        .some(v => get(v, propertyName) === get(value, propertyName))
    ) {
      throw this.createError({
        path: `${this.path}.${fieldName || propertyName}`,
      });
    }

    return true;
  });
});

const DirectCausesModal = ({close, selectedRootCause}) => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');

  const {appEngine, axios, getAuthToken} = useAxios();
  const currentUser = useSelector(state => state.auth.currentUser);

  const formikProps = {
    initialValues: {
      directCauses: selectedRootCause.DirectCauses.length ?
        selectedRootCause.DirectCauses.map(directCause => ({cause: directCause.DirectCause, explanation: directCause.Explanation})) :
        [
          {
            cause: '',
            explanation: '',
          },
        ],
    },
    validationSchema: yup.object().shape({
      directCauses: yup.array().of(yup.object().shape({
        cause: yup.string().required('Direct Cause is a required field.'),
        explanation: yup.string().required('Explanation is a required field.'),
      }).uniqueProperty('cause', 'You may only select each Direct Cause once.'),
      ),
    }),
    onSubmit: async (values, actions) => {
      try {
        const dataForFirestore = createDataForFirestore(values);

        await axios({
          method: 'post',
          url: `${appEngine}/ehs/firestore/UpdateRootCauseAnalysis`,
          withCredentials: true,
          headers: {
            'Authorization': await getAuthToken(),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          data: {
            formData: dataForFirestore,
            currentUser,
            docId: selectedRootCause.RootCauseId,
          },
        });

        setFormError('');
        setStage(stage + 1);
      }
      catch (err) {
        setFormError('There was an error during submission. Please try again.');
      }
    },
  };

  const directCauseField = {
    options: directCauses,
    optionKey: 'value',
  };

  return (
    <Dialog open={true} maxWidth='md' fullWidth scroll='body' transitionDuration={{exit: 0}}>
      <FormikForm {...formikProps}>
        {({values}) => (
          <Fragment>
            {stage !== 1 && <DialogTitle>Update Direct Causes</DialogTitle>}
            <DialogContent>
              <Collapse in={stage === 0}>
                <DialogContentText>
                  Enter all direct causes, and any details:
                </DialogContentText>
                <Grid container spacing={2}>
                  <FieldArray name='directCauses'>
                    {arrayHelpers => (
                      <Fragment>
                        {values.directCauses.map((field, index) => (
                          <Grid item xs={12} container spacing={2} style={{margin: '8px', border: '1px solid lightgrey'}} key={index}>
                            <Grid item xs={5}>
                              <AutoCompleteValue {...directCauseField} name={`directCauses[${index}].cause`} label='Direct Cause' required />
                            </Grid>
                            <Grid item xs={5}>
                              <TextField name={`directCauses[${index}].explanation`} label='Explanation' required multiline />
                            </Grid>
                            <Grid item xs={2} container alignContent='center' justify='center'>
                              <Button color='secondary' onClick={() => arrayHelpers.remove(index)} disabled={values.directCauses.length === 1}>
                                <Clear />
                              </Button>
                            </Grid>
                          </Grid>
                        ))}
                        <Grid item xs={3}>
                          <Button color='primary' onClick={() => arrayHelpers.push({cause: '', explanation: ''})}>
                            Add Direct Cause
                          </Button>
                        </Grid>
                      </Fragment>
                    )}
                  </FieldArray>
                </Grid>
              </Collapse>
              <SubmitConfirmation stage={stage === 1 ? 'success' : ''} text='Direct Causes successfully updated.' />
              <Alert in={Boolean(formError)} text={formError} severity='error' />
            </DialogContent>
            {stage === 0 ? (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton variant='text' color='secondary' onClick={close}>Cancel</FormButton>
                <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
              </DialogActions>
            ) : (
              <DialogActions style={{justify: 'space-between'}}>
                <FormButton variant='text' color='primary' onClick={close}>Close</FormButton>
              </DialogActions>
            )}
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

DirectCausesModal.propTypes = {
  close: PropTypes.func.isRequired,
  selectedRootCause: PropTypes.object,
};

export default DirectCausesModal;

function createDataForFirestore(values) {
  return {
    DirectCauses: values.directCauses.map(cause => ({
      DirectCause: cause.cause,
      Explanation: cause.explanation,
    })),
  };
}
