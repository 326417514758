// https://material-ui.com/components/tabs/#scrollable-tabs

import React from 'react';
import PropTypes from 'prop-types';
import {Hidden, AppBar, Tabs, Tab, makeStyles} from '@material-ui/core';

function a11yProps(index) {
  return {
    'id': `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ScrollableTabsButtonAuto({viewIndex, setViewIndex}) {
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setViewIndex(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Hidden smDown>
          <Tabs
            value={viewIndex}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            centered
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Cumberland" {...a11yProps(0)} />
            <Tab label="Herbold" {...a11yProps(1)} />
            <Tab label="Torchhood" {...a11yProps(2)} />
          </Tabs>
        </Hidden>
        <Hidden mdUp>
          <Tabs
            value={viewIndex}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label="Cumberland" {...a11yProps(0)} />
            <Tab label="Herbold" {...a11yProps(1)} />
            <Tab label="Torchhood" {...a11yProps(2)} />
          </Tabs>
        </Hidden>
      </AppBar>
    </div>
  );
}

ScrollableTabsButtonAuto.propTypes = {
  viewIndex: PropTypes.number.isRequired,
  setViewIndex: PropTypes.func.isRequired,
};
