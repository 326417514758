import {SET_CURRENT_USER, UNSET_CURRENT_USER, SET_USERS} from './types';
import {Auth, Functions} from 'config.js';

export const acGetCurrentUser = dispatch => {
  // uncomment this line to log out of current user. use this to be able to refresh the currentUser
  // Auth.signOut();
  return new Promise((resolve, reject) => {
    if (Auth.currentUser) {
      Auth.currentUser.getIdTokenResult().then(idTokenResult => {
        const currentUser = {
          email: idTokenResult.claims.email,
          displayName: idTokenResult.claims.name,
          roleCatalyst: idTokenResult.claims.roleCatalyst,
          roleEHS: idTokenResult.claims.roleEHS,
          roleCRM: idTokenResult.claims.roleCRM,
          rolePortalAdmin: idTokenResult.claims.rolePortalAdmin,
          roleTracking: idTokenResult.claims.roleTracking,
          outlookUser: idTokenResult.claims.outlookUser,
          nylasAccessToken: idTokenResult.claims.nylasAccessToken,
          nylasId: idTokenResult.claims.nylasId,
          nylasFolderId: idTokenResult.claims.nylasFolderId,
          token: idTokenResult.token,
          uid: idTokenResult.claims.user_id,
        };
        dispatch({type: SET_CURRENT_USER, payload: currentUser});
        resolve();
      });
    }
    else {
      reject(new Error());
    }
  });
};

export const acLogoutUser = () => {
  return {type: UNSET_CURRENT_USER};
};

export const acGetUsers = dispatch => {
  return Functions.httpsCallable('getUsers')()
    .then(result => {
      let payload = result.data.filter(user => {
        const {active} = user;
        const {roleEHS} = user.customClaims;
        if (roleEHS === 'No Access' || roleEHS === undefined || roleEHS === null) {
          return false;
        }
        else if (!active) {
          return false;
        }
        return true;
      });
      payload = payload.map(user => {
        return {
          displayName: user.displayName,
          email: user.email,
          roleCatalyst: user.customClaims.roleCatalyst,
          roleEHS: user.customClaims.roleEHS,
          roleCRM: user.customClaims.roleCRM,
          rolePortalAdmin: user.customClaims.rolePortalAdmin,
          roleTracking: user.customClaims.roleTracking,
          outlookUser: user.customClaims.outlookUser,
          uid: user.uid,
          nylasAccessToken: user.customClaims.nylasAccessToken,
          nylasId: user.customClaims.nylasId,
          nylasFolderId: user.customClaims.nylasFolderId,
        };
      });
      dispatch({type: SET_USERS, payload});
    })
    .catch(error => {
      console.error('error', error);
    });
};
