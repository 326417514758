import React from 'react';
import PropTypes from 'prop-types';
import {Paper, Typography} from '@material-ui/core';

const ObservationPanel = props => {
  return (
    <Paper style={{padding: '16px'}}>
      <Typography variant='h5' paragraph>Observation</Typography>
      <Typography variant='subtitle1'>{props.selectedCapa.Observation}</Typography>
    </Paper>
  );
};

ObservationPanel.propTypes = {
  selectedCapa: PropTypes.object.isRequired,
};

export default ObservationPanel;
