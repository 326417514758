import React, {useState, Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import {SubmitConfirmation} from 'components/';
import {Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Grid} from '@material-ui/core';
import {SkipNext} from '@material-ui/icons';
import {Alert, Collapse, Formik, Table} from '@kbi/component-library';
import * as yup from 'yup';
import {useSelector} from 'react-redux';
import {useAxios} from 'hooks/';
import {Firestore} from 'config.js';
import moment from 'moment';
const {FormikForm, SubmitButton, FormButton} = Formik;

const PrimaryCapaModal = ({close, selectedRootCause}) => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');
  const [listOfSelectedCapas, setListOfSelectedCapas] = useState([]);
  const [listOfAvailableCapas, setListOfAvailableCapas] = useState(null);
  const [dateRangeNumber, setDateRangeNumber] = useState(90);

  const {axios, getAuthToken, appEngine} = useAxios();
  const currentUser = useSelector(state => state.auth.currentUser);

  useEffect(() => {
    const CapaRef = Firestore.collection('EHS-CAPA')
      .where('RootCauseAnalysisRef', '==', null)
      .where('StartDate', '>=', moment().subtract(dateRangeNumber, 'days').toDate())
      .where('StartDate', '<=', moment().subtract(dateRangeNumber - 90, 'days').toDate());

    CapaRef.get().then(snap => {
      if (snap.size) {
        const arrayOfResults = snap.docs.map(doc => ({
          ...doc.data(),
          CapaId: doc.id,
          StartDate: doc.data().StartDate.toDate(),
          Deadline: doc.data().Deadline.toDate(),
          OriginatorsString: doc.data().Originators.map(user => user.displayName).join(', '),
          AssigneesString: doc.data().Assignees.map(user => user.displayName).join(', '),
        }));

        setListOfAvailableCapas((listOfAvailableCapas?.length ? [...listOfAvailableCapas, ...arrayOfResults] : arrayOfResults));
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeNumber]);

  const formikProps = {
    initialValues: {
    },
    validationSchema: yup.object().shape({}),
    onSubmit: async (values, actions) => {
      try {
        const dataForFirestore = createDataForFirestore(listOfSelectedCapas);

        await axios({
          method: 'post',
          url: `${appEngine}/ehs/firestore/UpdateRootCauseAnalysis`,
          withCredentials: true,
          headers: {
            'Authorization': await getAuthToken(),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          data: {
            formData: dataForFirestore,
            currentUser,
            docId: selectedRootCause.RootCauseId,
          },
        });

        setFormError('');
        setStage(stage + 1);
      }
      catch (err) {
        console.log('err', err );
        setFormError('There was an error during submission. Please try again.');
      }
    },
  };

  const tableProps = {
    data: listOfAvailableCapas || [],
    columns: [
      {Header: 'ID', accessor: 'CapaNumber', type: 'numeric'},
      {Header: 'Start Date', accessor: 'StartDate', type: 'datetime', datetimeFormat: 'MM/DD/YYYY'},
      {Header: 'Status', accessor: 'Status'},
      {Header: 'Location', accessor: 'Location'},
      {Header: 'Action', accessor: 'Action'},
      {Header: 'Originators', accessor: 'OriginatorsString'},
      {Header: 'Assignees', accessor: 'AssigneesString'},
    ],
    sortInitial: [{id: 'StartDate', desc: true}],
    paginationInitialSize: 10,
    title: '',
    actionsBar: [
      {
        icon: SkipNext,
        text: 'Get next 90 days of CAPA\'s',
        onClick: () => setDateRangeNumber(dateRangeNumber + 90),
      },
    ],
    actionsPerRow: [],
    selectRows: true,
    selectRowsInitial: [],
    selectRowHandler: ({dataOfSelectedRows}) => {
      setListOfSelectedCapas(dataOfSelectedRows);
    },
    isLoading: !listOfAvailableCapas,
  };

  return (
    <Dialog open={true} maxWidth='lg' fullWidth scroll='body' transitionDuration={{exit: 0}}>
      <FormikForm {...formikProps}>
        {({values}) => (
          <Fragment>
            {stage !== 1 && <DialogTitle>Add Primary CAPA&apos;s</DialogTitle>}
            <DialogContent>
              <Collapse in={stage === 0}>
                <DialogContentText>
                  Select CAPA&apos;s to link to this root cause analysis:
                </DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Table {...tableProps} />
                  </Grid>
                </Grid>
              </Collapse>
              <SubmitConfirmation stage={stage === 1 ? 'success' : ''} text="Primary CAPA's successfully added." />
              <Alert in={Boolean(formError)} text={formError} severity='error' />
            </DialogContent>
            {stage === 0 ? (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton variant='text' color='secondary' onClick={close}>Cancel</FormButton>
                <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
              </DialogActions>
            ) : (
              <DialogActions style={{justify: 'space-between'}}>
                <FormButton variant='text' color='primary' onClick={close}>Close</FormButton>
              </DialogActions>
            )}
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

PrimaryCapaModal.propTypes = {
  close: PropTypes.func.isRequired,
  selectedRootCause: PropTypes.object,
};

export default PrimaryCapaModal;

function createDataForFirestore(listOfSelectedCapas) {
  return {
    PrimaryCapas: listOfSelectedCapas.map(capa => ({
      Assignees: capa.AssigneesString,
      Number: capa.CapaNumber,
      Ref: capa.CapaId,
      Location: capa.Location,
      Observation: capa.Observation,
    })),
  };
}
