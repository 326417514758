import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Collapse, Typography, Grid} from '@material-ui/core';
import {CheckCircleOutline, DeleteOutline} from '@material-ui/icons';

const SubmitConfirmation = props => {
  const core = {
    textLine: {
      variant: 'h6',
      style: {textAlign: 'center'},
    },
  };
  return (
    <Fragment>
      <Collapse in={props.stage === 'upload'}>
        <Grid container justify='center' direction='column'
          spacing={0}
        >
          <Grid item xs container
            spacing={0}
            justify='center'
          >
            <Typography variant='h6' style={{textAlign: 'center'}}>
              The file is uploading.
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant='h2' style={{textAlign: 'center'}}>
              {props.uploadProgress || 0}%
            </Typography>
          </Grid>
        </Grid>
      </Collapse>
      <Collapse in={props.stage === 'success'}>
        <Grid container justify='center' direction='column'
          spacing={0}
        >
          <Grid item xs container
            justify='center' spacing={0}
          >
            {props.delete ? (
              <DeleteOutline style={{fontSize: '8em'}} />
            ) : (
              <CheckCircleOutline style={{fontSize: '8em'}} />
            )}
          </Grid>
          <Grid item xs>
            {Array.isArray(props.text) ? (
              props.text.map((textLine, index) => (
                <Typography {...core.textLine} key={index} gutterBottom>
                  {textLine}
                </Typography>
              ))
            ) : (
              <Typography {...core.textLine}>{props.text}</Typography>
            )}
          </Grid>
        </Grid>
      </Collapse>
    </Fragment>
  );
};

SubmitConfirmation.propTypes = {
  // if text is passed an array, the component will seperate each of the elements into their own Typography tag during display.
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  stage: PropTypes.string.isRequired,
  delete: PropTypes.bool,
  uploadProgress: PropTypes.number,
};

export default SubmitConfirmation;
