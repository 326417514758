/* eslint-disable-next-line import/no-anonymous-default-export */
export default {
  'Procedural': [
    {value: 'Written procedure not in place'},
    {value: 'Written procedure ineffective, outdated, or incomplete'},
    {value: 'MOC Procedure not implemented'},
    {value: 'Job procedures existed, but did not address hazard or compliance failure'},
    {value: 'Inspection procedures inadequate to detect hazards or compliance issues'},
  ],
  'Management System': [
    {value: 'Top management not sending clear messages about safety or compliance'},
    {value: 'Inadequate staffing to complete all required tasks safely or in a compliant manner'},
    {value: 'Hazard had not been recognized'},
    {value: 'Hazardous condition had been recognized, but workers not informed of interim measures'},
    {value: 'JSA, Risk Assessment, or Ergonomic Assessment not conducted'},
    {value: 'Review not made of hazards and correct methods before commencing work for task done infrequently'},
    {value: 'Planning and controls were insufficient'},
    {value: 'Work methods are error-provocative'},
    {value: 'Work methods are overly difficult, dangerous, or unpleasant'},
    {value: 'Work methods are overly stressful or induce fatigue'},
    {value: 'Work methods involve significant repetitive task(s)'},
    {value: 'Work methods require use of excessive force to perform job (forceful exertion)'},
    {value: 'Written plans/programs are missing or outdated'},
    {value: 'Supervisor(s) not adequately trained for assigned safety responsibilities'},
    {value: 'Supervisory responsibility and accountability not defined or understood'},
    {value: 'Worker training not conducted or inadequate'},
    {value: 'Worker competencies were inadequately monitored '},
    {value: 'Communication Failure'},
    {value: 'Emergency equipment not specified or available '},
  ],
  'Workplace Design': [
    {value: 'Workstation design unsafe'},
    {value: 'Workstation poorly designed'},
    {value: 'Workstation too crowded '},
    {value: 'Hazards derived from basic design of facilities, machinery, equipment, or tooling'},
    {value: 'Immediate work situation encourages riskier actions than prescribed work methods'},
    {value: 'Layout or position of tooling or equipment presents hazards (including ergonomic)'},
    {value: 'Work flow is hazardous'},
    {value: 'Positioning of employees in relation to equipment and materials is hazardous'},
    {value: 'Uncontrolled or poorly controlled exposure to hazardous materials'},
    {value: 'Work space for operations, maintenance, or storage is insufficient'},
    {value: 'Accessibility for maintenance work is hazardous'},
    {value: 'Workplace design allows environmental hazards (heat, cold, lighting, vibration, ventilation, etc)'},
    {value: 'Worker positioned too close to traffic'},
    {value: 'No clear egress'},
    {value: 'Inadequate lighting'},
  ],
  'Other': [{value: 'Other'}],
};
