/* eslint-disable react/prop-types */
import React from 'react';
import './AsyncLoader.css';

const AsyncLoader = props => {
  return (
    <div style={{display: 'flex', justifyContent: 'center', marginTop: '48px', ...props.style}}>
      <div className='lds-dual-ring' />
    </div>
  );
};

export default AsyncLoader;
