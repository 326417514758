import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {TableWithPaper} from 'components/';
import {Search, ChevronLeft, ImportExport} from '@material-ui/icons';
import {exportToCSV} from '@kbi/utility-library';
import moment from 'moment';

function roundNumber(n) {
  return Math.round(n * 10) / 10;
}

const HerboldCumberlandDailyTable = ({data=[], title, handleBack, handleViewRow}) => {
  const dataForDailyTable = useMemo(() => {
    if (data.length) {
      const mapOfData = {};
      data.forEach(doc => {
        const dateString = moment(doc.Date).format('MM/DD/YYYY');
        if (mapOfData[dateString]) {
          mapOfData[dateString].docs.push(doc);
          mapOfData[dateString].TotalHours = roundNumber(mapOfData[dateString].TotalHours + doc.Hours);
          mapOfData[dateString].WeightTotal += doc.WeightTotal;
          mapOfData[dateString].NumberOfLogs++;
          mapOfData[dateString].MeterStart = mapOfData[dateString].MeterStart < doc.MeterStart ? mapOfData[dateString].MeterStart : doc.MeterStart;
          mapOfData[dateString].MeterEnd = mapOfData[dateString].MeterEnd > doc.MeterEnd ? mapOfData[dateString].MeterEnd : doc.MeterEnd;
        }
        else {
          mapOfData[dateString] = {
            docs: [doc],
            TotalHours: doc.Hours,
            WeightTotal: doc.WeightTotal,
            MeterStart: doc.MeterStart,
            MeterEnd: doc.MeterEnd,
            Date: moment(dateString, 'MM/DD/YYYY').toDate(),
            NumberOfLogs: 1,
          };
        }
      });
      return Object.values(mapOfData);
    }
    else {
      return [];
    }
  }, [data]);

  const dailyTableProps = {
    data: dataForDailyTable,
    columns: [
      {Header: 'Date', accessor: 'Date', type: 'datetime'},
      {Header: 'Meter Start', accessor: 'MeterStart', type: 'numeric'},
      {Header: 'Meter End', accessor: 'MeterEnd', type: 'numeric'},
      {Header: 'Total Hours', accessor: 'TotalHours', type: 'numeric'},
      {Header: 'Total Weight', accessor: 'WeightTotal', type: 'numeric'},
      {Header: 'Number Of Logs', accessor: 'NumberOfLogs', type: 'numeric'},
    ],
    sortInitial: [{id: 'Date', desc: true}],
    title,
    paginationInitialSize: 10,
    actionsPerRow: [
      {
        icon: Search,
        tooltip: 'View Date Logs',
        onClick: ({rowData}) => handleViewRow(rowData),
      },
    ],
    actionsBar: [
      {
        icon: ChevronLeft,
        text: 'Back',
        onClick: handleBack,
      },
      {
        icon: ImportExport,
        text: 'Export Table',
        onClick: () => exportToCSV(data, [
          {Header: 'Date', accessor: 'Date', type: 'datetime'},
          {Header: 'Meter Start', accessor: 'MeterStart', type: 'numeric'},
          {Header: 'Meter End', accessor: 'MeterEnd', type: 'numeric'},
          {Header: 'Total Hours', accessor: 'TotalHours', type: 'numeric'},
          {Header: 'Total Weight', accessor: 'WeightTotal', type: 'numeric'},
          {Header: 'Number Of Logs', accessor: 'NumberOfLogs', type: 'numeric'},
        ]),
      },
    ],
  };

  return (
    <TableWithPaper {...dailyTableProps} />
  );
};

HerboldCumberlandDailyTable.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string.isRequired,
  handleViewRow: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
};

export default HerboldCumberlandDailyTable;
