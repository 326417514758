/* eslint-disable max-len */
import React, {useEffect, useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import {Alert, Collapse, Formik} from '@kbi/component-library';
import {SubmitConfirmation, ReportGenerator} from 'components/';
import {withFormik} from 'formik';
import * as yup from 'yup';
import {CapaForm} from './NewReportModal/';
import {useUsers} from 'hooks/';
const {SubmitButton, FormButton} = Formik;

const stageArray = ['basic', 'success'];

const NewReportModal = ({values, close, listOfQueryResults, setListOfQueryResults, ...formikBag}) => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');

  const users = useUsers();

  useEffect(() => {
    if (stage === 0 && formikBag.status && formikBag.status.success) {
      nonFormikSubmit(values);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikBag.status, stage, values]);

  const changeModalStage = useCallback((resultsFromQuery) => {
    formikBag.setSubmitting(false);
    if (!resultsFromQuery.length) {
      setFormError('The report you tried to generate did not contain any documents.');
      formikBag.setStatus({});
    }
    else {
      setStage(stage + 1);
      setFormError('');
    }
  }, [formikBag, stage]);

  const nonFormikSubmit = useCallback(() => {
    const ReportGen = new ReportGenerator({
      values,
      reportType: 'CAPA',
    });
    ReportGen.runQuery().then(results => {
      setListOfQueryResults(results);
      changeModalStage(results);
    });
  }, [changeModalStage, setListOfQueryResults, values]);

  const core = useMemo(() => ({
    dialog: {
      open: true,
      scroll: 'body',
      transitionDuration: {exit: 0},
      maxWidth: 'sm',
      fullWidth: true,
    },
    closeButton: {
      color: 'primary',
      variant: 'text',
      onClick: () => {
        close();
      },
    },
    submitConfirmation: {
      text: 'Report successfully created.',
      stage: stageArray[stage],
    },
    /* eslint-disable-next-line */
  }), [open, stage, formikBag.isSubmitting, formikBag.handleSubmit, values.reportType, close]);

  if (!users) return null;

  return (
    <Dialog {...core.dialog}>
      {stageArray[stage] !== 'success' && <DialogTitle>New Report</DialogTitle>}
      <DialogContent>
        <Collapse in={stageArray[stage] === 'basic'}>
          <DialogContentText>Select fields to create a custom report.</DialogContentText>
          <CapaForm values={values} />
        </Collapse>
        <SubmitConfirmation {...core.submitConfirmation} />
        <Alert in={Boolean(formError)} text={formError} severity='error' />
      </DialogContent>
      {stage === 0 ? (
        <DialogActions style={{justifyContent: 'space-between'}}>
          <FormButton variant='text' color='secondary' onClick={close}>Cancel</FormButton>
          <SubmitButton variant='text' color='primary' onClick={formikBag.handleSubmit}>Submit</SubmitButton>
        </DialogActions>
      ) : (
        <DialogActions style={{justify: 'space-between'}}>
          <FormButton {...core.closeButton}>Close</FormButton>
        </DialogActions>
      )}
    </Dialog>
  );
};

NewReportModal.propTypes = {
  close: PropTypes.func.isRequired,
  setListOfQueryResults: PropTypes.func.isRequired,
  listOfQueryResults: PropTypes.array,
  values: PropTypes.object.isRequired,
  queryParams: PropTypes.object,
};

yup.addMethod(yup.date, 'noDeadline', function(message) {
  return this.test('no deadline fields', message, function(value) {
    if (value && (this.parent.startDeadline || this.parent.endDeadline)) {
      throw this.createError({
        path: `${this.path}`,
      });
    }
    return true;
  });
});
yup.addMethod(yup.date, 'noDate', function(message) {
  return this.test('no date fields', message, function(value) {
    if (value && (this.parent.startDate || this.parent.endDate)) {
      throw this.createError({
        path: `${this.path}`,
      });
    }
    return true;
  });
});

export default withFormik({
  mapPropsToValues: props => ({
    action: '',
    active: '',
    capaNumber: '',
    completedBeforeDeadline: '',
    startDeadline: '',
    endDeadline: '',
    location: '',
    priority: '',
    startDate: '',
    endDate: '',
    status: '',
    originator: '',
    assignee: '',
  }),
  validationSchema: yup.object().shape({
    startDate: yup.date().noDeadline('Either a date or a deadline can be entered.'),
    endDate: yup.date().noDeadline('Either a date or a deadline can be entered.'),
    startDeadline: yup.date().noDate('Either a date or a deadline can be entered.'),
    endDeadline: yup.date().noDate('Either a date or a deadline can be entered.'),
  }),
  handleSubmit: (values, {...actions}) => {
    actions.setStatus({success: true});
  },
})(NewReportModal);
