import React, {Fragment, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {SubmitConfirmation} from 'components/';
import {useApcLogs, useAxios} from 'hooks/';
import {Dialog, DialogContent, Button, DialogTitle, DialogActions, Grid, DialogContentText, MenuItem} from '@material-ui/core';
import * as yup from 'yup';
import {Formik, Alert, Collapse} from '@kbi/component-library';
import moment from 'moment';
import {FieldArray} from 'formik';
import {Firestore} from 'config.js';
import get from 'lodash.get';
import {Clear} from '@material-ui/icons';
import {determineIfUserIsManager} from 'components/';
const {DateField, FormikForm, TextField, NumberField, WeightField, FormButton, SubmitButton, SelectField} = Formik;

function roundNumber(n) {
  return Math.round(n * 10) / 10;
}

yup.addMethod(yup.object, 'uniqueProperty', function(propertyName, message, fieldName) {
  return this.test('unique', message, function(value) {
    if (!value || !get(value, propertyName)) {
      return true;
    }
    if (
      this.parent
        .filter(v => v !== value)
        .some(v => get(v, propertyName) === get(value, propertyName))
    ) {
      throw this.createError({
        path: `${this.path}.${fieldName || propertyName}`,
      });
    }

    return true;
  });
});

const TorchHoodLogModal = ({close, selectedLog}) => {
  const [formError, setFormError] = useState('');
  const [stage, setStage] = useState(0);
  const currentUser = useSelector(state => state.auth.currentUser);
  const apcLogs = useApcLogs();
  const {axios, getAuthToken, appEngine} = useAxios();

  const formik = {
    initialValues: {
      nonOp: selectedLog ? selectedLog.NonOp : false,
      date: selectedLog ? moment(selectedLog.Date).format('YYYY-MM-DD') : '',
      meterStart: selectedLog ? selectedLog.MeterStart : '',
      meterEnd: selectedLog ? selectedLog.MeterEnd : '',
      meterHours: selectedLog ? selectedLog.Hours : 0,
      containers: selectedLog ? selectedLog.Containers.map(container => ({shortNo: container.ShortNo, weight: container.Weight})) : [{
        shortNo: '',
        weight: '',
      }],
      notes: selectedLog ? selectedLog.Notes : '',
    },
    validationSchema: yup.object().shape({
      date: yup.date().max(new Date(), 'Date cannot be in the future.').required('Date is a required field.').test({
        name: 'unique date',
        test: val => {
          if (selectedLog) {
            return true;
          }
          if (val) {
            if (apcLogs.TorchHood.find(log => moment(log.Date).isSame(val, 'date'))) {
              return false;
            }
            else return true;
          }
          else return true;
        },
        message: 'There is already a log for that date.',
      }),
      meterStart: yup.number().required('Meter Start is a required field.'),
      meterEnd: yup.number().required('Meter End is a required field.').min(yup.ref('meterStart'), ({min}) => `Must be greater than or equal to ${min}`),
      meterHours: yup.number(),
      containers: yup.array().of(yup.object().shape({
        shortNo: yup.string().required('Short Number is a required field.').test({
          name: 'existing short no',
          test: async (value) => {
            if (!value) return true;
            const container = await Firestore.collection('Tracking-Containers').doc(value).get();
            if (container.exists) return true;
            else return false;
          },
          message: 'This short number is not in the tracking system. Please verify number.',
        }),
        weight: yup.number().required('Weight is a required field.').min(1, 'Weight must be greater than 0.'),
      }).uniqueProperty('shortNo', 'This short number is already used in this form.'),
      ),
    }),
    onSubmit: async (values, actions) => {
      const dataToSubmit = {
        Date: moment(values.date).set('hour', 12).set('minutes', 0).toDate(),
        MeterStart: parseFloat(values.meterStart),
        MeterEnd: parseFloat(values.meterEnd),
        Hours: parseFloat(values.meterHours),
        Notes: values.notes,
        Machine: 'TorchHood',
        Containers: values.containers.map(container => ({ShortNo: container.shortNo, Weight: parseInt(container.weight)})),
        ShortNumbers: values.containers.map(container => container.shortNo),
        NonOp: values.nonOp,
        testItem: [
          {
            date: new Date(),
          },
          {
            date: new Date(),
          }, {
            date: new Date(),
          },
        ],
      };
      selectedLog ? dataToSubmit.System = {
        ...selectedLog.System,
        UpdatedOn: new Date(),
        UpdatedBy: currentUser.displayName,
      } : dataToSubmit.System = {
        CreatedBy: currentUser.displayName,
        CreatedOn: new Date(),
      };
      console.log('dataToSubmit', dataToSubmit );
      try {
        await axios({
          method: 'post',
          url: `${appEngine}/ehs/firestore/TorchhoodWrite`,
          withCredentials: true,
          headers: {
            'Authorization': await getAuthToken(),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          data: {
            formId: selectedLog ? selectedLog.logId : null,
            formData: dataToSubmit,
          },
        });
        setFormError('');
        setStage(stage + 1);
      }
      catch (error) {
        setFormError('There was an error during submission. Please try again.');
      }
    },
  };

  const core = {
    dialog: {
      open: true,
      scroll: 'body',
      transitionDuration: {exit: 0},
      maxWidth: 'md',
      fullWidth: true,
    },
    submitConfirmation: {
      text: `Torchhood daily log successfully ${selectedLog ? 'updated' : 'added'}.`,
      stage: stage === 1 ? 'success' : 'not success',
    },
    meterChange: {
      onBlur: ({form}) => {
        form.setFieldValue('meterHours', form.values.meterStart && form.values.meterEnd ? roundNumber(parseFloat(form.values.meterEnd) - parseFloat(form.values.meterStart)) : 0);
      },
    },
    weightChange: {
      onBlur: ({form}) => {
        form.setFieldValue('weightTotal', form.values.weightEnd && form.values.weightStart ? parseInt(form.values.weightEnd) - parseInt(form.values.weightStart) : 0);
      },
    },
    nonOpField: {
      disabled: Boolean(selectedLog),
      onChange: ({event, form}) => {
        if (event.target.value) {
          form.setFieldValue('containers', []);
        }
      },
    },
  };


  return (
    <Dialog {...core.dialog}>
      <FormikForm {...formik}>
        {({values}) => (
          <Fragment>
            {stage !== 1 && <DialogTitle>Torchhood Daily Log</DialogTitle>}
            <DialogContent>
              <Collapse in={stage === 0}>
                {!determineIfUserIsManager(currentUser) ? <DialogContentText>This is read only. In order to edit data, please contact a manager.</DialogContentText> : null}
                <Grid container spacing={2}>
                  <Grid item xs={6} sm>
                    <SelectField name='nonOp' label='Did Not Process Batteries' required {...core.nonOpField}>
                      <MenuItem value={false}>False</MenuItem>
                      <MenuItem value={true}>True</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={6} sm>
                    <DateField name='date' label='Date' required fast disabled={Boolean(selectedLog)} />
                  </Grid>
                  <Grid item xs={4} sm>
                    <NumberField name='meterStart' decimal={1} label='Meter Start' required {...core.meterChange}
                      InputProps={{readOnly: !determineIfUserIsManager(currentUser) && Boolean(selectedLog)}}
                    />
                  </Grid>
                  <Grid item xs={4} sm>
                    <NumberField name='meterEnd' decimal={1} label='Meter End' required {...core.meterChange} InputProps={{readOnly: !determineIfUserIsManager(currentUser) && Boolean(selectedLog)}} />
                  </Grid>
                  <Grid item xs={4} sm>
                    <NumberField name='meterHours' decimal={1} label='Hours' disabled />
                  </Grid>
                  {!values.nonOp && <FieldArray name='containers'>
                    {arrayHelpers => (
                      <Grid item xs={12} container spacing={2} style={{margin: '0px', border: '1px solid lightgrey'}}>
                        {values.containers.map((containerField, index) => (
                          <Fragment key={'containers' + index}>
                            <Grid item xs={5}>
                              <TextField name={`containers[${index}].shortNo`} label='Short Number' required InputProps={{readOnly: !determineIfUserIsManager(currentUser) && Boolean(selectedLog)}} />
                            </Grid>
                            <Grid item xs={5}>
                              <WeightField name={`containers[${index}].weight`} label='Weight' required InputProps={{readOnly: !determineIfUserIsManager(currentUser) && Boolean(selectedLog)}} />
                            </Grid>
                            <Grid item xs={2} container alignContent='center' justify='center'>
                              {!determineIfUserIsManager(currentUser) || !selectedLog ? <Button color='secondary' onClick={() => arrayHelpers.remove(index)} disabled={values.containers.length === 1}>
                                <Clear />
                              </Button> : null}
                            </Grid>
                          </Fragment>
                        ))}
                        {!determineIfUserIsManager(currentUser) || !selectedLog ? <Grid item xs={3}>
                          <Button color='primary' onClick={() => arrayHelpers.push({shortNo: '', weight: ''})}>
                            Add Container
                          </Button>
                        </Grid> : null}
                      </Grid>
                    )}
                  </FieldArray>}
                  <Grid item xs={12}>
                    <TextField name='notes' label='Notes' InputProps={{readOnly: !determineIfUserIsManager(currentUser) && Boolean(selectedLog)}} />
                  </Grid>
                </Grid>
              </Collapse>
              <SubmitConfirmation {...core.submitConfirmation} />
              <Alert in={Boolean(formError)} text={formError} severity='error' />
            </DialogContent>
            {stage === 0 ? (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton variant='text' color='secondary' onClick={close}>Cancel</FormButton>
                <SubmitButton variant='text' color='primary' disabled={!determineIfUserIsManager(currentUser) && Boolean(selectedLog)}>Submit</SubmitButton>
              </DialogActions>
            ) : (
              <DialogActions style={{justify: 'space-between'}}>
                <Button variant='text' color='primary' onClick={close}>Close</Button>
              </DialogActions>
            )}
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

TorchHoodLogModal.propTypes = {
  close: PropTypes.func.isRequired,
  selectedLog: PropTypes.object,
};

export default TorchHoodLogModal;
