import {useCallback} from 'react';
import {Auth} from 'config.js';
import axios from 'axios';

const useAxios = () => {
  // Return appropriate app-engine domain depending on current client-side environment
  const appEngine = process.env.NODE_ENV === 'production' ? 'https://server.kbi.works' : 'http://localhost:8080';

  // Common format to authenticate current Firebase user in secure app engine route
  // Pass to Axios request 'Authorization' header
  const getAuthToken = useCallback(async () => {
    const idToken = await Auth.currentUser.getIdToken(true);
    return `Token ${idToken}`;
  }, []);

  // Common format to handle errors retuned from Axios and displayed using Formik setStatus
  const setAxiosAlert = useCallback((error, actions) => {
    if (error.response?.data?.message) actions.setStatus({alert: error.response.data.message});
    else if (error.request) actions.setStatus({alert: 'Error: Improper Http Request'});
    else actions.setStatus({alert: error.message});
  }, []);

  return {appEngine, axios, getAuthToken, setAxiosAlert};
};

export default useAxios;
