import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Paper, Chip, Typography, Grid} from '@material-ui/core';
import {Firestore} from 'config.js';
import {useHistory} from 'react-router-dom';
import moment from 'moment';

const AttachedInspectionPanel = ({selectedCapa}) => {
  const [inspectionData, setInspectionData] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (selectedCapa.InspectionRefs) {
      const promiseArray = [];
      selectedCapa.InspectionRefs.forEach(ref => promiseArray.push(Firestore.collection('EHS-Inspections').doc(ref).get()));
      Promise.all(promiseArray).then(values => {
        setInspectionData(values.map(doc => ({Date: moment(doc.data().FormData.Date?.toDate() || new Date()).format('M/DD/YY'), Title: doc.data().FormTitle, ref: doc.id})));
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper style={{padding: '16px'}}>
      <Typography variant='h5' paragraph>Attached Inspections</Typography>
      <Grid container spacing={2}>
        {inspectionData?.map(data => (
          <Grid item key={data.ref}>
            <Chip label={data.Title + ' - ' + data.Date} onClick={() => history.push('/inspections/' + data.ref)} color='primary' />
          </Grid>
        )) || null}
      </Grid>
    </Paper>
  );
};

AttachedInspectionPanel.propTypes = {
  selectedCapa: PropTypes.object.isRequired,
};

export default AttachedInspectionPanel;
