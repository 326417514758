import React, {Suspense, useEffect, useState} from 'react';
import {Container, Paper, LinearProgress} from '@material-ui/core';
import {CloudUpload, CloudDownload} from '@material-ui/icons';
import {AddFileModal, TableWithPaper} from 'components/';
import {useHistory} from 'react-router-dom';
import {Firestore, Storage} from 'config.js';
import {useInspections, useUsers} from 'hooks/';
const DailyCatalystAreaVersion1 = React.lazy(() => import('./InspectionDetails/Forms/DailyCatalystArea/Version1.js'));
const DailyLeadAcidBatteryAreaVersion1 = React.lazy(() => import('./InspectionDetails/Forms/DailyLeadAcidBatteryArea/Version1.js'));
const DailyMaintenanceAreaVersion1 = React.lazy(() => import('./InspectionDetails/Forms/DailyMaintenanceArea/Version1.js'));
const DailyNonLeadAreaVersion1 = React.lazy(() => import('./InspectionDetails/Forms/DailyNonLeadArea/Version1.js'));
const DailyPreciousMetalsAreaVersion1 = React.lazy(() => import('./InspectionDetails/Forms/DailyPreciousMetalsArea/Version1.js'));
const DailyShippingAndReceivingAreaVersion1 = React.lazy(() => import('./InspectionDetails/Forms/DailyShippingAndReceivingArea/Version1.js'));
const DailyWastewaterTreatmentAreaVersion1 = React.lazy(() => import('./InspectionDetails/Forms/DailyWastewaterTreatmentArea/Version1.js'));
const MonthlyEmergencyEquipmentVersion1 = React.lazy(() => import('./InspectionDetails/Forms/MonthlyEmergencyEquipment/Version1.js'));
const MonthlyEnclosureVersion1 = React.lazy(() => import('./InspectionDetails/Forms/MonthlyEnclosure/Version1.js'));
const MonthlySecurityVersion1 = React.lazy(() => import('./InspectionDetails/Forms/MonthlySecurity/Version1.js'));
const QuarterlyDuctVersion1 = React.lazy(() => import('./InspectionDetails/Forms/QuarterlyDuct/Version1.js'));
const WeeklyEyewashVersion1 = React.lazy(() => import('./InspectionDetails/Forms/WeeklyEyewash/Version1.js'));

const InspectionDetails = () => {
  const [selectedInspection, setSelectedInspection] = useState(null);
  const [files, setFiles] = useState(null);
  const [fileModalOpen, setFileModalOpen] = useState(false);

  const history = useHistory();
  const inspections = useInspections();
  useUsers();

  useEffect(() => {
    const splitPathname = history.location.pathname.split('/');
    if (inspections?.ref) {
      setSelectedInspection(inspections.ref[splitPathname[splitPathname.length - 1]]);
    }
  }, [history.location.pathname, inspections]);

  useEffect(() => {
    // a listener to the files for this  form
    const splitPathname = history.location.pathname.split('/');
    const listener = Firestore.collection('EHS-Inspections').doc(splitPathname[splitPathname.length - 1]).collection('Files').onSnapshot(snap => {
      setFiles(snap.docs.map(doc => ({...doc.data(), inspectionFileId: doc.id})));
    });
    if (listener) {
      return () => {
        listener();
      };
    }
  }, [history.location.pathname]);

  if (!selectedInspection) return <LinearProgress />;

  const fileTable = {
    data: files || [],
    columns: [
      {accessor: 'DateUploaded', Header: 'Date Uploaded', type: 'timestamp'},
      {accessor: 'FileName', Header: 'File Name'},
      {accessor: 'Owner.Name', Header: 'Uploaded By'},
    ],
    actionsBar: [
      {
        icon: CloudUpload,
        text: 'Upload File',
        onClick: () => setFileModalOpen(true),
      },
    ],
    actionsPerRow: [{
      icon: CloudDownload,
      tooltip: 'Open Document',
      onClick: ({rowData}) =>
        Storage.ref(`Inspections/${selectedInspection.InspectionId}/${rowData.inspectionFileId}.${rowData.FileExt}`).getDownloadURL().then(url => window.open(url, '__blank')),
    }],
    title: 'Attached Files',
    paginationActive: false,
  };
  const fileModalProps = {
    addFileModalOpen: fileModalOpen,
    selectedInspection,
    close: () => setFileModalOpen(false),
  };

  return <Container fixed maxWidth='lg' style={{paddingTop: '16px'}}>
    <Paper style={{padding: '16px'}}>
      <Suspense fallback={<LinearProgress />}>
        {determineFormToRender(selectedInspection)}
        <TableWithPaper {...fileTable} />
      </Suspense>
    </Paper>
    {fileModalOpen && <AddFileModal {...fileModalProps} />}
  </Container>;
};

export default InspectionDetails;

function determineFormToRender(inspection) {
  switch (inspection.FormTitle) {
  case 'Daily Catalyst Area Inspection':
    return determineCatalystAreaVersion(inspection);
  case 'Daily Lead Acid Battery Area Inspection':
    return determineLeadAcidBatteryAreaVersion(inspection);
  case 'Daily Maintenance Area Inspection':
    return determineMaintenanceAreaVersion(inspection);
  case 'Daily Non-Lead Area Inspection':
    return determineNonLeadAreaVersion(inspection);
  case 'Daily Precious Metals Area Inspection':
    return determinePreciousMetalsAreaVersion(inspection);
  case 'Daily Shipping & Receiving Area Inspection':
    return determineShippingAndReceivingAreaVersion(inspection);
  case 'Daily Wastewater Treatment Area Inspection':
    return determineWastewaterTreatmentAreaVersion(inspection);
  case 'Weekly Eyewash Inspection':
    return determineWeeklyEyewashVersion(inspection);
  case 'Monthly Emergency Equipment Inspection':
    return determineMonthlyEmergencyEquipmentVersion(inspection);
  case 'Monthly Enclosure Inspection':
    return determineMonthlyEnclosureVersion(inspection);
  case 'Monthly Security Inspection':
    return determineMonthlySecurityVersion(inspection);
  case 'Quarterly Duct Inspection':
    return determineQuarterlyDuctVersion(inspection);
  default:
    return 'BROKEN';
  }
}
function determineCatalystAreaVersion(inspection) {
  switch (inspection.FormVersion) {
  case 1: {
    return <DailyCatalystAreaVersion1 selectedInspection={inspection} />;
  }
  default: {
    return <DailyCatalystAreaVersion1 selectedInspection={inspection} />;
  }
  }
}
function determineLeadAcidBatteryAreaVersion(inspection) {
  switch (inspection.FormVersion) {
  case 1: {
    return <DailyLeadAcidBatteryAreaVersion1 selectedInspection={inspection} />;
  }
  default: {
    return <DailyLeadAcidBatteryAreaVersion1 selectedInspection={inspection} />;
  }
  }
}
function determineMaintenanceAreaVersion(inspection) {
  switch (inspection.FormVersion) {
  case 1: {
    return <DailyMaintenanceAreaVersion1 selectedInspection={inspection} />;
  }
  default: {
    return <DailyMaintenanceAreaVersion1 selectedInspection={inspection} />;
  }
  }
}
function determineNonLeadAreaVersion(inspection) {
  switch (inspection.FormVersion) {
  case 1: {
    return <DailyNonLeadAreaVersion1 selectedInspection={inspection} />;
  }
  default: {
    return <DailyNonLeadAreaVersion1 selectedInspection={inspection} />;
  }
  }
}
function determinePreciousMetalsAreaVersion(inspection) {
  switch (inspection.FormVersion) {
  case 1: {
    return <DailyPreciousMetalsAreaVersion1 selectedInspection={inspection} />;
  }
  default: {
    return <DailyPreciousMetalsAreaVersion1 selectedInspection={inspection} />;
  }
  }
}
function determineShippingAndReceivingAreaVersion(inspection) {
  switch (inspection.FormVersion) {
  case 1: {
    return <DailyShippingAndReceivingAreaVersion1 selectedInspection={inspection} />;
  }
  default: {
    return <DailyShippingAndReceivingAreaVersion1 selectedInspection={inspection} />;
  }
  }
}
function determineWastewaterTreatmentAreaVersion(inspection) {
  switch (inspection.FormVersion) {
  case 1: {
    return <DailyWastewaterTreatmentAreaVersion1 selectedInspection={inspection} />;
  }
  default: {
    return <DailyWastewaterTreatmentAreaVersion1 selectedInspection={inspection} />;
  }
  }
}
function determineMonthlyEmergencyEquipmentVersion(inspection) {
  switch (inspection.FormVersion) {
  case 1: {
    return <MonthlyEmergencyEquipmentVersion1 selectedInspection={inspection} />;
  }
  default: {
    return <MonthlyEmergencyEquipmentVersion1 selectedInspection={inspection} />;
  }
  }
}
function determineMonthlyEnclosureVersion(inspection) {
  switch (inspection.FormVersion) {
  case 1: {
    return <MonthlyEnclosureVersion1 selectedInspection={inspection} />;
  }
  default: {
    return <MonthlyEnclosureVersion1 selectedInspection={inspection} />;
  }
  }
}
function determineMonthlySecurityVersion(inspection) {
  switch (inspection.FormVersion) {
  case 1: {
    return <MonthlySecurityVersion1 selectedInspection={inspection} />;
  }
  default: {
    return <MonthlySecurityVersion1 selectedInspection={inspection} />;
  }
  }
}
function determineQuarterlyDuctVersion(inspection) {
  switch (inspection.FormVersion) {
  case 1: {
    return <QuarterlyDuctVersion1 selectedInspection={inspection} />;
  }
  default: {
    return <QuarterlyDuctVersion1 selectedInspection={inspection} />;
  }
  }
}
function determineWeeklyEyewashVersion(inspection) {
  switch (inspection.FormVersion) {
  case 1: {
    return <WeeklyEyewashVersion1 selectedInspection={inspection} />;
  }
  default: {
    return <WeeklyEyewashVersion1 selectedInspection={inspection} />;
  }
  }
}
