import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acGetUsers} from 'state/auth/actions.js';

const useUsers = () => {
  const dispatch = useDispatch();
  const users = useSelector(state => state.auth.users);

  useEffect(() => {
    if (!users) dispatch(acGetUsers);
  }, [dispatch, users]);

  return users;
};

export default useUsers;
