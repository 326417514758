import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography} from '@material-ui/core';
import {Firestore, Storage} from 'config.js';
import {Table} from '@kbi/component-library';
import {CloudDownload, CloudUpload} from '@material-ui/icons';
import {useHistory} from 'react-router-dom';
import {ExpandMore} from '@material-ui/icons';
import {AddFileModal} from 'components/';

const AttachmentPanel = ({selectedRootCause}) => {
  const [files, setFiles] = useState(null);
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const history = useHistory();
  const styles = useStyles();

  useEffect(() => {
    // a listener to the files for this form
    const splitPathname = history.location.pathname.split('/');
    const listener = Firestore.collection('EHS-Root-Cause').doc(splitPathname[splitPathname.length - 1]).collection('Files').onSnapshot(snap => {
      setFiles(snap.docs.map(doc => ({...doc.data(), rootCauseFileId: doc.id})));
    });
    if (listener) {
      return () => {
        listener();
      };
    }
  }, [history.location.pathname]);

  const fileTable = {
    data: files || [],
    columns: [
      {accessor: 'DateUploaded', Header: 'Date Uploaded', type: 'timestamp'},
      {accessor: 'FileName', Header: 'File Name'},
      {accessor: 'Owner.Name', Header: 'Uploaded By'},
    ],
    actionsBar: [
      {
        icon: CloudUpload,
        text: 'Upload File',
        onClick: () => setFileModalOpen(true),
      },
    ],
    actionsPerRow: [{
      icon: CloudDownload,
      tooltip: 'Open Document',
      onClick: ({rowData}) =>
        Storage.ref(`Root_Cause/${selectedRootCause.RootCauseId}/${rowData.rootCauseFileId}.${rowData.FileExt}`).getDownloadURL().then(url => window.open(url, '__blank')),
    }],
    // paginationActive: false,
  };

  const fileModalProps = {
    selectedRootCauseAnalysis: selectedRootCause,
    addFileModalOpen: true,
    close: () => setFileModalOpen(false),
  };

  return <Accordion defaultExpanded={false}>
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Typography>Attachments</Typography>
    </AccordionSummary>
    <AccordionDetails className={styles.noPadding}>
      <div style={{width: '100%'}}>
        <Table {...fileTable} />
      </div>
    </AccordionDetails>
    {fileModalOpen && <AddFileModal {...fileModalProps} />}
  </Accordion>;
};

const useStyles = makeStyles(theme => ({
  noPadding: {
    padding: 0,
  },
}),
);

AttachmentPanel.propTypes = {
  selectedRootCause: PropTypes.object.isRequired,
};

export default AttachmentPanel;
