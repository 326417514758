import React, {Fragment, useState, useEffect, useMemo} from 'react';
import {Container, Typography, MenuItem, Grid, Snackbar, Paper, Accordion, AccordionDetails, AccordionSummary, Tooltip, LinearProgress} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {CloudUpload, CloudDownload, ExpandMore, Warning} from '@material-ui/icons';
import {Formik, Alert} from '@kbi/component-library';
import {useHistory} from 'react-router-dom';
import {Firestore, Storage, appEngineUrl} from 'config.js';
import * as yup from 'yup';
import {AddFileModal, TableWithPaper} from 'components/';
import {useStormwaterDocs, useAxios} from 'hooks/';
import moment from 'moment';
const {FormikForm, SelectField, DateField, TextField, RadioGroup, FormButton, SubmitButton} = Formik;

const StormwaterFormTemplate = () => {
  const [selectedForm, setSelectedForm] = useState(null);
  const [formToUse, setFormToUse] = useState(null);
  const [snackbarState, setSnackbarState] = useState({open: false, severity: 'success', message: ''});
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [files, setFiles] = useState(null);

  const {currentUser} = useSelector(state => state.auth);
  const history = useHistory();
  const stormwaterDocs = useStormwaterDocs();
  const {axios, getAuthToken, appEngine} = useAxios();

  useEffect(() => {
    const splitPathname = history.location.pathname.split('/');
    if (stormwaterDocs?.ref) {
      setSelectedForm(stormwaterDocs.ref[splitPathname[splitPathname.length - 1]]);
    }
  }, [history.location.pathname, stormwaterDocs]);
  useEffect(() => {
    // a listener to the files for this  form
    const splitPathname = history.location.pathname.split('/');
    const listener = Firestore.collection('EHS-Stormwater-Inspections').doc(splitPathname[splitPathname.length - 1]).collection('Files').onSnapshot(snap => {
      setFiles(snap.docs.map(doc => ({...doc.data(), stormwaterFileId: doc.id})));
    });
    if (listener) {
      return () => {
        listener();
      };
    }
  }, [history.location.pathname]);
  useEffect(() => {
    const splitPathname = history.location.pathname.split('/');

    if (splitPathname[3] && !selectedForm) {
      // wait until selectedForm is defined. at this point, there is a form selected but the state isnt set yet.
      return;
    }
    else if (splitPathname[3] && selectedForm) {
      const urlWithoutVersion = determineFormApiUrl(splitPathname[2]);
      axios({
        method: 'get',
        url: `${urlWithoutVersion}/v${selectedForm.FormVersion}`,
      }).then(response => {
        const formFromReq = response.data;

        if (!formFromReq) throw new Error();

        setFormToUse(formFromReq);
      }).catch(err => {
        console.error('Error in fetch: ', err);
      });
    }
  }, [axios, history.location.pathname, selectedForm]);

  const formIsReadOnly = useMemo(() => {
    if (currentUser.roleEHS === 'Admin' || selectedForm?.Completed === false) return false;
    else return true;
  }, [currentUser, selectedForm]);

  // this is set up to stop the creation of initial values of formik if there is a selected form
  if (!selectedForm || !formToUse) return <LinearProgress />;

  const closeSnackbar = () => {
    return setSnackbarState({open: false, severity: 'success', message: ''});
  };

  const formikProps = {
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: createFormikInitialValues(formToUse, selectedForm),
    validationSchema: yup.object().shape(createValidationSchema(formToUse)),
    onSubmit: async (values, actions) => {
      const dataToSubmit = createDataForFirestore(values, formToUse);
      try {
        await axios({
          method: 'post',
          url: `${appEngine}/ehs/firestore/EditStormwaterForm`,
          withCredentials: true,
          headers: {
            'Authorization': await getAuthToken(),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          data: {
            formId: selectedForm.stormwaterId,
            formData: {...dataToSubmit,
              System: {
                CompletedBy: currentUser.displayName,
                CompletedOn: new Date(),
              },
            }},
        });

        return setSnackbarState({open: true, severity: 'success', message: 'The form was successfully submitted'});
      }
      catch (err) {
        return setSnackbarState({open: true, severity: 'error', message: 'There was an error during submission. Please try again.'});
      }
    },
  };
  const fileTable = {
    data: files || [],
    columns: [
      {accessor: 'DateUploaded', Header: 'Date Uploaded', type: 'timestamp'},
      {accessor: 'FileName', Header: 'File Name'},
      {accessor: 'Owner.Name', Header: 'Uploaded By'},
    ],
    actionsBar: [
      {
        icon: CloudUpload,
        text: 'Upload File',
        onClick: () => setFileModalOpen(true),
      },
    ],
    actionsPerRow: [{
      icon: CloudDownload,
      tooltip: 'Open Document',
      onClick: ({rowData}) =>
        Storage.ref(`Stormwater_Files/${selectedForm.stormwaterId}/${rowData.stormwaterFileId}.${rowData.FileExt}`).getDownloadURL().then(url => window.open(url, '__blank')),
    }],
    title: 'Attached Files',
    paginationActive: false,
  };
  const fileModalProps = {
    stormwater: true,
    addFileModalOpen: true,
    selectedStormwaterDoc: selectedForm,
    close: () => setFileModalOpen(false),
  };

  return <Container fixed maxWidth='lg' style={{paddingTop: '16px'}}>
    <Paper style={{padding: '16px'}}>
      <Grid container justify='space-between'>
        <Typography variant='h4'>{formToUse.FormTitle}</Typography>
        <Typography variant='body2'>version: {formToUse.Version}</Typography>
      </Grid>
      {formToUse.Subtitle?.length ? <div style={{borderTop: '1px solid black', paddingTop: '8px'}}>
        {formToUse.Subtitle.map((text, i) => <Typography variant='body2' key={`subtitle${i}`} paragraph>{text}</Typography>)}
      </div> : null}
      <FormikForm {...formikProps}>
        {({errors}) => (
          <Fragment>
            {formToUse.FormFields.map((section, index) => (
              <Fragment key={section.Title + index}>
                <Accordion defaultExpanded={true}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Grid container justify='space-between'>
                      <Typography variant='h6'>{section.Title}</Typography>
                      {showErrorNotification(errors, index)}
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {section.Fields.map((fieldObj, i) => createField(fieldObj, index, formIsReadOnly))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Fragment>))}
            <TableWithPaper {...fileTable} />
            <Grid spacing={2} container>
              {!formIsReadOnly ?
                <Fragment>
                  <Grid item>
                    <FormButton color='secondary' onClick={() => history.goBack()}>Cancel</FormButton>
                  </Grid>
                  <Grid item>
                    <FormButton>CAPA</FormButton>
                  </Grid>
                  <Grid item>
                    <SubmitButton>Submit</SubmitButton>
                  </Grid>
                </Fragment> : <FormButton onClick={() => history.goBack()}>Back</FormButton>}
            </Grid>
          </Fragment>
        )}
      </FormikForm>
      <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={closeSnackbar} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
        <Alert in={snackbarState.open} text={snackbarState.message} severity={snackbarState.severity} />
      </Snackbar>
      {fileModalOpen && <AddFileModal {...fileModalProps} />}
    </Paper>
  </Container>;
};

export default StormwaterFormTemplate;

function createDataForFirestore(values) {
  const returnedData = {};
  // eslint-disable-next-line guard-for-in
  for (const fieldName in values) {
    const trimmedName = fieldName.split('&-&')[1];
    if (trimmedName === 'Date') returnedData[trimmedName] = moment(values[fieldName]).toDate();
    else returnedData[trimmedName] = values[fieldName];
  }
  returnedData.Completed = true;

  return returnedData;
}
function createValidationSchema(formToUse) {
  const objectOfValidations = {};
  formToUse.FormFields.forEach((section, index) => {
    section.Fields.forEach(fieldObj => {
      let validation;
      switch (fieldObj.field) {
      default:
        validation = yup.string().label(fieldObj.label);
        break;
      }
      if (fieldObj.required) validation = validation.required();
      objectOfValidations[`${index}&-&${fieldObj.label}`] = validation;
    });
  });
  return objectOfValidations;
}
function createField(fieldObj, index, readOnly) {
  switch (fieldObj.field) {
  case 'SelectField':
    return <SelectField fast InputProps={{readOnly: readOnly}}
      key={fieldObj.label} name={`${index}&-&${fieldObj.label}`} label={fieldObj.label} required={fieldObj.required}
    >
      {fieldObj.options.map((option) => <MenuItem value={option} key={`${fieldObj.label}-${option}`}>{option}</MenuItem>)}
    </SelectField>;

  case 'Datetime':
    return <DateField InputProps={{readOnly: readOnly}} fast key={fieldObj.label}
      type='datetime-local' name={`${index}&-&${fieldObj.label}`}
      label={fieldObj.label} required={fieldObj.required}
    />;

  case 'TextField':
    return <TextField fast InputProps={{readOnly: readOnly}} key={fieldObj.label}
      name={`${index}&-&${fieldObj.label}`}
      label={fieldObj.label} required={fieldObj.required}
    />;

  case 'Radio':
    return <RadioGroup key={fieldObj.label} name={`${index}&-&${fieldObj.label}`} label={fieldObj.label} required={fieldObj.required} row
      radios={fieldObj.options.map(value => ({
        RadioProps: {
          disabled: readOnly,
        },
        label: value,
        value: value,
      }))}
    />;

  default:
    break;
  }
}
function createFormikInitialValues(formToUse, selectedForm) {
  const formikMap = {};
  formToUse.FormFields.forEach((section, index) => {
    section.Fields.forEach(fieldObj => {
      if (fieldObj.field === 'Datetime') {
        formikMap[`${index}&-&${fieldObj.label}`] = selectedForm ? moment(selectedForm[fieldObj.label]).format('YYYY-MM-DDTHH:mm') : '';
      }
      else formikMap[`${index}&-&${fieldObj.label}`] = selectedForm ? selectedForm[fieldObj.label] : '';
    });
  });
  return formikMap;
}
function showErrorNotification(formikErrors, indexOfPanel) {
  let returnedValue = null;
  Object.keys(formikErrors).forEach(fieldName => {
    // eslint-disable-next-line eqeqeq
    if (fieldName[0] == indexOfPanel) {
      returnedValue = <Tooltip title='There is a form error in this collapse'><Warning color='secondary' /></Tooltip>;
    }
  });
  return returnedValue;
}
function determineFormApiUrl(formNameFromUrl) {
  switch (formNameFromUrl) {
  case 'comprehensive-stormwater':
    return `${appEngineUrl}/ehs/ComprehensiveSiteStormwater`;
  case 'non-stormwater-discharge':
    return `${appEngineUrl}/ehs/NonStormwaterDischarge`;
  case 'predicted-storm':
    return `${appEngineUrl}/ehs/PredictedStormEvent`;
  case 'stormwater-discharge':
    return `${appEngineUrl}/ehs/StormwaterDischarge`;
  default:
    break;
  }
}
