import React from 'react';
import {LinearProgress} from '@material-ui/core';
import {AppFrame, ErrorBoundary} from '@kbi/component-library';
import {useAuthCheck} from '@kbi/utility-library';
import {Auth, Functions} from 'config.js';
import {acGetCurrentUser} from 'state/auth/actions.js';
import {useDetachModuleListeners, useModuleAccessCheck, useAxios} from 'hooks/';
import {routes, menuItems, moduleMenuOptions} from './appFrameProps.js';
import {withRouter} from 'react-router-dom';

const Bowser = require('bowser/bundled');
const browser = Bowser.getParser(window.navigator.userAgent).getBrowserName();

function sendDataToEmailCloudFunction(error, info, currentUser) {
  if (process.env.NODE_ENV === 'production') {
    Functions.httpsCallable('emailCatchError')({
      errorMessage: error.message,
      errorName: error.name,
      errorLog: error.message.split(' at')[0],
      errorStack: info.componentStack,
      errorLocation: window.location.href,
      errorTime: new Date().toLocaleString(),
      user: currentUser?.email || 'No User Data found.',
    });
  }
}

const App = () => {
  useDetachModuleListeners();
  const {currentUser} = useAuthCheck({acGetCurrentUser, Auth});
  useModuleAccessCheck();
  const {axios} = useAxios();

  const handleLogout = async () => {
    document.cookie = `signOut=true.${Date.now()};domain=kbi.works;max-age=600`;
    try {
      await axios({
        method: 'post',
        url: `https://server.kbi.works/auth/signout`,
        withCredentials: true,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
    }
    catch (error) {
      console.log('logout error', error);
    }
  };

  if (browser === 'Internet Explorer') window.location.replace('http://kbi.works/PreventIE.html');

  if (!currentUser) return <LinearProgress />;

  return (
    <ErrorBoundary errorHandlerFunction={(error, info) => sendDataToEmailCloudFunction(error, info, currentUser)}>
      <AppFrame
        routes={routes}
        menuItems={menuItems}
        moduleTitle='EH&S Management'
        redirectTo='/dashboard'
        moduleMenuOptions={moduleMenuOptions}
        currentUserEmail={currentUser.email}
        logoutFunction={handleLogout}
      />
    </ErrorBoundary>
  );
};

export default withRouter(App);
