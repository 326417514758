import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acStartRootCauseListener} from 'state/firestore/actions.js';

const useRootCauses = () => {
  const dispatch = useDispatch();
  const rootCauses = useSelector(state => state.firestore.rootCauses);

  useEffect(() => {
    if (!rootCauses) dispatch(acStartRootCauseListener);
  }, [dispatch, rootCauses]);

  return rootCauses;
};

export default useRootCauses;
