/* eslint-disable-next-line import/no-anonymous-default-export */
export default [
  {value: '(COMPREHENSIVE) Root Cause Analysis (RCA)'},
  {value: 'Risk Assessment (RA)'},
  {value: 'Job Safety Analysis (JSA)'},
  {value: 'Ergonomic Assessment'},
  {value: 'Management Of Change (MOC)'},
  {value: 'Workpace Redesign'},
  {value: 'Management System Review/Enhancement'},
  {value: 'Drug/Alcohol Test'},
  {value: 'Medical Examination'},
  {value: 'Other'},
];
