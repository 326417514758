import {Firestore} from 'config.js';
import {SET_WASTEWATER_DOCS, SET_WASTEWATER_SUBSCRIPTION, FORGET_WASTEWATER} from './types.js';
import {SET_STORMWATER_DOCS, SET_STORMWATER_SUBSCRIPTION, FORGET_STORMWATER} from './types.js';
import {SET_CAPA_DOCS, SET_CAPA_SUBSCRIPTION} from './types.js';
import {SET_ROOT_CAUSE_DOCS, SET_ROOT_CAUSE_SUBSCRIPTION} from './types.js';
import {SET_INSPECTION_DOCS, SET_INSPECTION_SUBSCRIPTION} from './types.js';
import {SET_WASTEWATER_FILES, SET_WASTEWATER_FILE_SUBSCRIPTION, FORGET_WASTEWATER_FILES} from './types.js';
import {SET_STORMWATER_FILES, SET_STORMWATER_FILE_SUBSCRIPTION, FORGET_STORMWATER_FILES} from './types.js';
import {FORGET_APC, SET_APC_DOCS, SET_APC_SUBSCRIPTIONS} from './types.js';

// EHS-Wastewater collection listener actions creators
export const acStartWastewaterListener = dispatch => {
  const wastewaterRef = Firestore.collection('EHS-Wastewater').orderBy('Date');
  const list = [];
  const ref = {};
  const options = {includeMetadataChanges: true};
  const handleQuerySnap = async snap => {
    const snapSize = snap.docChanges().length;
    if (snapSize > 0) {
      const findDocIndex = id => {
        const foundIndex = list.findIndex(item => {
          return item.wastewaterDocId === id;
        });
        return foundIndex;
      };
      for (let i = 0; i < snapSize; i++) {
        const {type, doc} = snap.docChanges()[i];
        const docData = {...doc.data(), wastewaterDocId: doc.id};
        if (type === 'added') {
          list.push(docData);
          ref[doc.id] = docData;
        }
        if (type === 'modified') {
          list.splice(findDocIndex(doc.id), 1, docData);
          ref[doc.id] = docData;
        }
        if (type === 'removed') {
          list.splice(findDocIndex(doc.id), 1);
          delete ref[doc.id];
        }
      }
    }
    dispatch({type: SET_WASTEWATER_DOCS, payload: {list: [...list], ref: {...ref}}});
  };
  const handleQueryError = error => {
    console.error('acStartWastewaterListener Error: ', error);
  };
  const subscription = wastewaterRef.onSnapshot(options, handleQuerySnap, handleQueryError);
  dispatch({type: SET_WASTEWATER_SUBSCRIPTION, payload: subscription});
};
export const acEndWastewaterListener = dispatch => {
  dispatch({type: FORGET_WASTEWATER});
};

// EHS-Documents collection listener actions creators
export const acStartWastewaterFilesListener = dispatch => {
  const wastewaterFileRef = Firestore.collection('EHS-Documents').where('EHSType', '==', 'Wastewater');
  const list = [];
  const ref = {};
  const options = {includeMetadataChanges: true};
  const handleQuerySnap = async snap => {
    const snapSize = snap.docChanges().length;
    if (snapSize > 0) {
      const findDocIndex = id => {
        const foundIndex = list.findIndex(item => {
          return item.wastewaterFileId === id;
        });
        return foundIndex;
      };
      for (let i = 0; i < snapSize; i++) {
        const {type, doc} = snap.docChanges()[i];
        const docData = {...doc.data(), wastewaterFileId: doc.id};
        if (type === 'added') {
          list.push(docData);
          ref[doc.id] = docData;
        }
        if (type === 'modified') {
          list.splice(findDocIndex(doc.id), 1, docData);
          ref[doc.id] = docData;
        }
        if (type === 'removed') {
          list.splice(findDocIndex(doc.id), 1);
          delete ref[doc.id];
        }
      }
    }
    dispatch({type: SET_WASTEWATER_FILES, payload: {list: [...list], ref: {...ref}}});
  };
  const handleQueryError = error => {
    console.error('acStartWastewaterFilesListener Error: ', error);
  };
  const subscription = wastewaterFileRef.onSnapshot(options, handleQuerySnap, handleQueryError);
  dispatch({type: SET_WASTEWATER_FILE_SUBSCRIPTION, payload: subscription});
};
export const acEndWastewaterFileListener = dispatch => {
  dispatch({type: FORGET_WASTEWATER_FILES});
};
export const acStartStormwaterFilesListener = dispatch => {
  const stormwaterFileRef = Firestore.collection('EHS-Documents').where('EHSType', '==', 'Stormwater');
  const list = [];
  const ref = {};
  const options = {includeMetadataChanges: true};
  const handleQuerySnap = async snap => {
    const snapSize = snap.docChanges().length;
    if (snapSize > 0) {
      const findDocIndex = id => {
        const foundIndex = list.findIndex(item => {
          return item.stomwaterFileId === id;
        });
        return foundIndex;
      };
      for (let i = 0; i < snapSize; i++) {
        const {type, doc} = snap.docChanges()[i];
        const docData = {...doc.data(), stormwaterFileId: doc.id};
        if (type === 'added') {
          list.push(docData);
          ref[doc.id] = docData;
        }
        if (type === 'modified') {
          list.splice(findDocIndex(doc.id), 1, docData);
          ref[doc.id] = docData;
        }
        if (type === 'removed') {
          list.splice(findDocIndex(doc.id), 1);
          delete ref[doc.id];
        }
      }
    }
    dispatch({type: SET_STORMWATER_FILES, payload: {list: [...list], ref: {...ref}}});
  };
  const handleQueryError = error => {
    console.error('acStartStormwaterFilesListener Error: ', error);
  };
  const subscription = stormwaterFileRef.onSnapshot(options, handleQuerySnap, handleQueryError);
  dispatch({type: SET_STORMWATER_FILE_SUBSCRIPTION, payload: subscription});
};
export const acEndStormwaterFileListener = dispatch => {
  dispatch({type: FORGET_STORMWATER_FILES});
};

// APC-Docs collection listener actions creators
export const acStartApcLogListener = dispatch => {
  const apcLogRef = Firestore.collection('EHS-APC-Logs').orderBy('Date', 'desc').orderBy('MeterEnd', 'desc');
  const Herbold = [];
  const Cumberland = [];
  const TorchHood = [];
  const ref = {};
  const options = {includeMetadataChanges: true};
  const handleQuerySnap = async snap => {
    const snapSize = snap.docChanges().length;
    if (snapSize > 0) {
      const findDocIndex = (id, machineArray) => {
        const foundIndex = machineArray.findIndex(item => {
          return item.logId === id;
        });
        return foundIndex;
      };
      for (let i = 0; i < snapSize; i++) {
        const {type, doc} = snap.docChanges()[i];
        const docData = {...doc.data(), logId: doc.id, Date: doc.data().Date.toDate()};
        let arrayToUse = null;
        switch (docData.Machine) {
        case 'Herbold':
          arrayToUse = Herbold;
          break;
        case 'Cumberland':
          arrayToUse = Cumberland;
          break;
        case 'TorchHood':
          docData.numberOfContainers = docData.Containers.length;
          docData.stringOfShortNumbers = docData.ShortNumbers.join(', ');
          docData.WeightTotal = docData.Containers.reduce((a, b) => a + b.Weight, 0);
          arrayToUse = TorchHood;
          break;
        default:
          break;
        }

        if (type === 'added') {
          arrayToUse.push(docData);
          ref[doc.id] = docData;
        }
        if (type === 'modified') {
          arrayToUse.splice(findDocIndex(doc.id, arrayToUse), 1, docData);
          ref[doc.id] = docData;
        }
        if (type === 'removed') {
          arrayToUse.splice(findDocIndex(doc.id, arrayToUse), 1);
          delete ref[doc.id];
        }
      }
    }
    dispatch({type: SET_APC_DOCS, payload: {Herbold: [...Herbold], Cumberland: [...Cumberland], TorchHood: [...TorchHood], ref: {...ref}}});
  };
  const handleQueryError = error => {
    console.error('acStartApcLogListener Error: ', error);
  };
  const subscription = apcLogRef.onSnapshot(options, handleQuerySnap, handleQueryError);
  dispatch({type: SET_APC_SUBSCRIPTIONS, payload: subscription});
};
export const acEndApcLogListener = dispatch => {
  dispatch({type: FORGET_APC});
};
// EHS-CAPA collection listener
export const acStartCapaListener = dispatch => {
  const CapaRef = Firestore.collection('EHS-CAPA').where('Active', '==', true).orderBy('StartDate');
  const list = [];
  const ref = {};
  const options = {includeMetadataChanges: true};
  const handleQuerySnap = async snap => {
    const snapSize = snap.docChanges().length;
    if (snapSize > 0) {
      const findDocIndex = id => {
        const foundIndex = list.findIndex(item => {
          return item.CapaId === id;
        });
        return foundIndex;
      };
      for (let i = 0; i < snapSize; i++) {
        const {type, doc} = snap.docChanges()[i];
        const docData = {
          ...doc.data(),
          CapaId: doc.id,
          StartDate: doc.data().StartDate.toDate(),
          Deadline: doc.data().Deadline.toDate(),
          OriginatorsString: doc.data().Originators.map(user => user.displayName).join(', '),
          AssigneesString: doc.data().Assignees.map(user => user.displayName).join(', '),
          RCACompleted: Boolean(doc.data().RootCauseAnalysisRef),
        };
        if (type === 'added') {
          list.push(docData);
          ref[doc.id] = docData;
        }
        if (type === 'modified') {
          list.splice(findDocIndex(doc.id), 1, docData);
          ref[doc.id] = docData;
        }
        if (type === 'removed') {
          list.splice(findDocIndex(doc.id), 1);
          delete ref[doc.id];
        }
      }
    }
    dispatch({type: SET_CAPA_DOCS, payload: {list: [...list], ref: {...ref}}});
  };
  const handleQueryError = error => {
    console.error('acStartCapaListener Error: ', error);
  };
  const subscription = CapaRef.onSnapshot(options, handleQuerySnap, handleQueryError);
  dispatch({type: SET_CAPA_SUBSCRIPTION, payload: subscription});
};

export const acStartRootCauseListener = dispatch => {
  const rootCauseRef = Firestore.collection('EHS-Root-Cause');
  const list = [];
  const ref = {};
  const options = {includeMetadataChanges: true};
  const handleQuerySnap = async snap => {
    const snapSize = snap.docChanges().length;
    if (snapSize > 0) {
      const findDocIndex = id => {
        const foundIndex = list.findIndex(item => {
          return item.RootCauseId === id;
        });
        return foundIndex;
      };
      for (let i = 0; i < snapSize; i++) {
        const {type, doc} = snap.docChanges()[i];
        const docData = {
          ...doc.data(),
          RootCauseId: doc.id,
          StartDate: doc.data().StartDate.toDate(),
        };
        if (type === 'added') {
          list.push(docData);
          ref[doc.id] = docData;
        }
        if (type === 'modified') {
          list.splice(findDocIndex(doc.id), 1, docData);
          ref[doc.id] = docData;
        }
        if (type === 'removed') {
          list.splice(findDocIndex(doc.id), 1);
          delete ref[doc.id];
        }
      }
    }
    dispatch({type: SET_ROOT_CAUSE_DOCS, payload: {list: [...list], ref: {...ref}}});
  };
  const handleQueryError = error => {
    console.error('acStartRootCauseListener Error: ', error);
  };
  const subscription = rootCauseRef.onSnapshot(options, handleQuerySnap, handleQueryError);
  dispatch({type: SET_ROOT_CAUSE_SUBSCRIPTION, payload: subscription});
};

export const acStartInspectionListener = dispatch => {
  const inspectionsRef = Firestore.collection('EHS-Inspections');
  const list = [];
  const ref = {};
  const options = {includeMetadataChanges: true};
  const handleQuerySnap = async snap => {
    const snapSize = snap.docChanges().length;
    if (snapSize > 0) {
      const findDocIndex = id => {
        const foundIndex = list.findIndex(item => {
          return item.InspectionId === id;
        });
        return foundIndex;
      };
      for (let i = 0; i < snapSize; i++) {
        const {type, doc} = snap.docChanges()[i];
        const docData = {
          ...doc.data(),
          InspectionId: doc.id,
        };
        if (docData?.FormData?.Date) {
          docData.FormData.Date = docData.FormData?.Date?.toDate();
        }
        if (type === 'added') {
          list.push(docData);
          ref[doc.id] = docData;
        }
        if (type === 'modified') {
          list.splice(findDocIndex(doc.id), 1, docData);
          ref[doc.id] = docData;
        }
        if (type === 'removed') {
          list.splice(findDocIndex(doc.id), 1);
          delete ref[doc.id];
        }
      }
    }
    dispatch({type: SET_INSPECTION_DOCS, payload: {list: [...list], ref: {...ref}}});
  };
  const handleQueryError = error => {
    console.error('acStartInspectionListener Error: ', error);
  };
  const subscription = inspectionsRef.onSnapshot(options, handleQuerySnap, handleQueryError);
  dispatch({type: SET_INSPECTION_SUBSCRIPTION, payload: subscription});
};

// EHS-Stormwater-Inspections collection listener actions creators
export const acStartStormwaterListener = dispatch => {
  const stormwaterRef = Firestore.collection('EHS-Stormwater-Inspections').orderBy('Date');
  const list = [];
  const ref = {};
  const options = {includeMetadataChanges: true};
  const handleQuerySnap = async snap => {
    const snapSize = snap.docChanges().length;
    if (snapSize > 0) {
      const findDocIndex = id => {
        const foundIndex = list.findIndex(item => {
          return item.stormwaterId === id;
        });
        return foundIndex;
      };
      for (let i = 0; i < snapSize; i++) {
        const {type, doc} = snap.docChanges()[i];
        const docData = {...doc.data(), stormwaterId: doc.id};
        if (docData.Date) docData.Date = docData.Date.toDate();

        if (type === 'added') {
          list.push(docData);
          ref[doc.id] = docData;
        }
        if (type === 'modified') {
          list.splice(findDocIndex(doc.id), 1, docData);
          ref[doc.id] = docData;
        }
        if (type === 'removed') {
          list.splice(findDocIndex(doc.id), 1);
          delete ref[doc.id];
        }
      }
    }
    dispatch({type: SET_STORMWATER_DOCS, payload: {list: [...list], ref: {...ref}}});
  };
  const handleQueryError = error => {
    console.error('acStartStormwaterListener Error: ', error);
  };
  const subscription = stormwaterRef.onSnapshot(options, handleQuerySnap, handleQueryError);
  dispatch({type: SET_STORMWATER_SUBSCRIPTION, payload: subscription});
};
export const acEndStormwaterListener = dispatch => {
  dispatch({type: FORGET_STORMWATER});
};
