import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography, AccordionActions} from '@material-ui/core';
import {StyledTextField} from 'components/';
import {RootCausesModal} from './Modals/';
import {ExpandMore} from '@material-ui/icons';

const RootCausePanel = ({selectedRootCause}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return <Accordion defaultExpanded={false}>
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Typography>Root Causes</Typography>
    </AccordionSummary>
    <AccordionDetails>

      {selectedRootCause.RootCauses.length ? (
        <Grid container spacing={2}>
          {selectedRootCause.RootCauses.map((rootCause) => (
            <Grid container spacing={2} key={rootCause.Explanation} style={{margin: '8px', border: '1px solid lightgrey'}}>

              <Fragment>
                <Grid item xs={6}>
                  <StyledTextField value={rootCause.Catagory} label='Catagory' />
                </Grid>
                <Grid item xs={6}>
                  <StyledTextField value={rootCause.Cause} label='Root Cause' />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField value={rootCause.Explanation} label='Explanation' />
                </Grid>
              </Fragment>

            </Grid>
          ))}
        </Grid>) :
        <Typography>Root Causes have not been entered yet.</Typography>}

    </AccordionDetails>
    <AccordionActions>
      {selectedRootCause.Status !== 'Completed' ? <Button variant='outlined' onClick={() => setModalOpen(true)} color='primary'>Update Root Causes</Button> : null}
    </AccordionActions>
    {modalOpen && <RootCausesModal selectedRootCause={selectedRootCause} close={() => setModalOpen(false)} />}
  </Accordion>;
};

RootCausePanel.propTypes = {
  selectedRootCause: PropTypes.object.isRequired,
};

export default RootCausePanel;
