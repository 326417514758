import React, {Fragment} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

// Import Redux Elements
import {Provider as ReduxProvider} from 'react-redux';
import {store} from './state/store';

// Import Design Elements
import CssBaseline from '@material-ui/core/CssBaseline';
import './index.css';

ReactDOM.render(
  <Fragment>
    <ReduxProvider store={store}>
      <CssBaseline />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ReduxProvider>
  </Fragment>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
