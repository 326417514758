import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {TableWithPaper} from 'components/';
import {Add, Search} from '@material-ui/icons';
import {ActionTakenModal} from './ActionsTakenPanel/';

const ActionsTakenPanel = ({selectedCapa, setActionsViewOpen, capaActions}) => {
  const [actionModalType, setActionModalType] = useState('');
  const [actionToView, setActionToView] = useState(null);

  const actionsTakenTable = {
    data: capaActions || [],
    columns: [
      {Header: 'Comment', accessor: 'Comment', wrapBodyText: true},
      {Header: 'Date', accessor: 'Date', type: 'datetime', datetimeFormat: 'MM/DD/YY h:mma'},
      {Header: 'By', accessor: 'CreatedBy'},
      {Header: 'Action Type', accessor: 'ActionType'},
      {Header: '# of Notified', accessor: 'NotifyListLength'},
      {Header: '# of Files', accessor: 'FileNumber'},
    ],
    actionsBar: [
      {
        icon: Add,
        text: 'Add Action',
        buttonProps: {disabled: selectedCapa.Active ? false : true},
        onClick: () => setActionModalType('new action'),
      },
      {
        icon: Search,
        text: 'View All Actions',
        onClick: () => setActionsViewOpen(true),
      },
    ],
    actionsPerRow: [
      {
        icon: Search,
        tooltip: 'View Action',
        onClick: ({rowData}) => {
          setActionModalType(getActionTakenType(rowData));
          setActionToView(rowData);
        },
      },
    ],
    sortInitial: [{id: 'Date', desc: true}],
    isLoading: capaActions === null ? true : false,
    title: 'Actions Taken',
    paperProps: {style: {margin: 0}},
  };
  const actionModalProps = {
    actionToView,
    selectedCapa,
    type: actionModalType,
    close: () => {
      setActionModalType('');
      setActionToView(null);
    },
  };

  return (
    <>
      <TableWithPaper {...actionsTakenTable} />
      {Boolean(actionModalType) && <ActionTakenModal {...actionModalProps} />}
    </>
  );
};

function getActionTakenType(actionObject) {
  if (actionObject.ActionType === 'Deadline Updated') return 'deadline';
  else if (actionObject.ActionType === 'Verified' || actionObject.ActionType === 'Rejected') return 'verification';
  else return 'new action';
}

ActionsTakenPanel.propTypes = {
  selectedCapa: PropTypes.object.isRequired,
  setActionsViewOpen: PropTypes.func.isRequired,
  capaActions: PropTypes.arrayOf(PropTypes.object),
};

export default ActionsTakenPanel;
