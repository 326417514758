import React, {Fragment, useState, useMemo} from 'react';
import {HerboldCumberlandMonthlyTable, HerboldCumberlandModal, HerboldCumberlandLogTable, HerboldCumberlandDailyTable} from './components/';
import {useApcLogs} from '../../hooks/';
import moment from 'moment';
import {LinearProgress} from '@material-ui/core';

function roundNumber(n) {
  return Math.round(n * 10) / 10;
}

const CumberlandView = () => {
  const [addLogModalOpen, setAddLogModalOpen] = useState(false);
  const [logsForOneMonth, setLogsForOneMonth] = useState(null);
  const [logsForOneDay, setLogsForOneDay] = useState(null);
  const [selectedLog, setSelectedLog] = useState(null);

  const apcLogs = useApcLogs();
  const dataForMonthlyTable = useMemo(() => {
    if (apcLogs?.Cumberland) {
      const mapOfData = {};
      apcLogs.Cumberland.forEach(doc => {
        const monthYear = moment(doc.Date).format('MM/YYYY');
        if (mapOfData[monthYear]) {
          mapOfData[monthYear].docs.push(doc);
          mapOfData[monthYear].TotalHours = roundNumber(mapOfData[monthYear].TotalHours + doc.Hours);
          mapOfData[monthYear].WeightTotal += doc.WeightTotal;
          mapOfData[monthYear].MeterStart = mapOfData[monthYear].MeterStart < doc.MeterStart ? mapOfData[monthYear].MeterStart : doc.MeterStart;
          mapOfData[monthYear].MeterEnd = mapOfData[monthYear].MeterEnd > doc.MeterEnd ? mapOfData[monthYear].MeterEnd : doc.MeterEnd;
        }
        else {
          mapOfData[monthYear] = {
            docs: [doc],
            TotalHours: doc.Hours,
            WeightTotal: doc.WeightTotal,
            MeterStart: doc.MeterStart,
            MeterEnd: doc.MeterEnd,
            Date: moment(monthYear, 'MM/YYYY').toDate(),
          };
        }
      });
      return Object.values(mapOfData);
    }
    else {
      return [];
    }
  }, [apcLogs]);

  const handleModalClose = () => {
    setSelectedLog(null);
    setAddLogModalOpen(false);
  };
  const openLogModal = (rowData=null) => {
    setAddLogModalOpen(true);
    if (rowData) setSelectedLog(rowData);
  };

  if (!apcLogs.Cumberland) return <LinearProgress />;

  return (
    <Fragment>
      {!logsForOneMonth ? <HerboldCumberlandMonthlyTable data={dataForMonthlyTable} title='Cumberland Monthly Data'
        openModal={() => openLogModal()}
        handleViewRow={rowData => setLogsForOneMonth(rowData.docs)}
      /> : null}

      {logsForOneMonth && !logsForOneDay ? <HerboldCumberlandDailyTable data={logsForOneMonth} title='Cumberland Daily Data'
        handleViewRow={rowData => setLogsForOneDay(rowData.docs)}
        handleBack={() => setLogsForOneMonth(null)}
      /> : null}

      {logsForOneMonth && logsForOneDay ? <HerboldCumberlandLogTable data={logsForOneDay} title='Cumberland Logs'
        openModal={rowData => openLogModal(rowData)}
        handleBack={() => setLogsForOneDay(null)}
      /> : null}

      {addLogModalOpen && <HerboldCumberlandModal machine='Cumberland' close={handleModalClose} selectedLog={selectedLog} />}
    </Fragment>
  );
};

export default CumberlandView;
