import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acStartWastewaterListener} from 'state/firestore/actions.js';

const useWastewaterDocs = () => {
  const dispatch = useDispatch();
  const wastewaterDocs = useSelector(state => state.firestore.wastewater);

  useEffect(() => {
    if (!wastewaterDocs) dispatch(acStartWastewaterListener);
  }, [dispatch, wastewaterDocs]);

  return wastewaterDocs;
};

export default useWastewaterDocs;
