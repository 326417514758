import {SET_WASTEWATER_DOCS, SET_WASTEWATER_SUBSCRIPTION, FORGET_WASTEWATER} from './types.js';
import {SET_WASTEWATER_FILES, SET_WASTEWATER_FILE_SUBSCRIPTION, FORGET_WASTEWATER_FILES} from './types.js';
import {SET_STORMWATER_FILES, SET_STORMWATER_FILE_SUBSCRIPTION, FORGET_STORMWATER_FILES} from './types.js';
import {SET_STORMWATER_DOCS, FORGET_STORMWATER, SET_STORMWATER_SUBSCRIPTION} from './types.js';
import {FORGET_APC, SET_APC_DOCS, SET_APC_SUBSCRIPTIONS} from './types.js';
import {SET_CAPA_DOCS, SET_CAPA_SUBSCRIPTION} from './types.js';
import {SET_ROOT_CAUSE_DOCS, SET_ROOT_CAUSE_SUBSCRIPTION} from './types.js';
import {SET_INSPECTION_DOCS, SET_INSPECTION_SUBSCRIPTION} from './types.js';

const INITIAL_STATE = {
  wastewater: null,
  wastewaterFiles: null,
  apcLogs: null,
  stormwater: null,
  stormwaterFiles: null,
  capas: null,
  capaActions: null,
  rootCauses: null,
  inspections: null,
};

/* eslint-disable-next-line import/no-anonymous-default-export */
export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action;
  switch (type) {
  case SET_WASTEWATER_DOCS:
    return {...state, wastewater: {...state.wastewater, list: payload.list, ref: payload.ref}};
  case SET_WASTEWATER_SUBSCRIPTION:
    return {...state, wastewater: {...state.wastewater, subscription: payload}};
  case FORGET_WASTEWATER:
    return {...state, wastewater: null};
  case SET_WASTEWATER_FILES:
    return {...state, wastewaterFiles: {...state.wastewaterFiles, list: payload.list, ref: payload.ref}};
  case SET_WASTEWATER_FILE_SUBSCRIPTION:
    return {...state, wastewaterFiles: {...state.wastewaterFiles, subscription: payload}};
  case FORGET_WASTEWATER_FILES:
    return {...state, wastewaterFiles: null};
  case SET_STORMWATER_FILES:
    return {...state, stormwaterFiles: {...state.stormwaterFiles, list: payload.list, ref: payload.ref}};
  case SET_STORMWATER_FILE_SUBSCRIPTION:
    return {...state, stormwaterFiles: {...state.stormwaterFiles, subscription: payload}};
  case FORGET_STORMWATER_FILES:
    return {...state, stormwaterFiles: null};
  case SET_APC_DOCS:
    return {...state, apcLogs: {...state.apcLogs, Herbold: payload.Herbold, Cumberland: payload.Cumberland, TorchHood: payload.TorchHood, ref: payload.ref}};
  case SET_APC_SUBSCRIPTIONS:
    return {...state, apcLogs: {...state.apcLogs, subscription: payload}};
  case FORGET_APC:
    return {...state, apcLogs: null};
  case SET_STORMWATER_DOCS:
    return {...state, stormwater: {...state.stormwater, list: payload.list, ref: payload.ref}};
  case SET_STORMWATER_SUBSCRIPTION:
    return {...state, stormwater: {...state.stormwater, subscription: payload}};
  case FORGET_STORMWATER:
    return {...state, stormwater: null};
  case SET_CAPA_DOCS:
    return {...state, capas: {...state.capas, list: payload.list, ref: payload.ref}};
  case SET_CAPA_SUBSCRIPTION:
    return {...state, capas: {...state.capas, subscription: payload}};
  case SET_ROOT_CAUSE_DOCS:
    return {...state, rootCauses: {...state.rootCauses, list: payload.list, ref: payload.ref}};
  case SET_ROOT_CAUSE_SUBSCRIPTION:
    return {...state, rootCauses: {...state.rootCauses, subscription: payload}};
  case SET_INSPECTION_DOCS:
    return {...state, inspections: {...state.inspections, list: payload.list, ref: payload.ref}};
  case SET_INSPECTION_SUBSCRIPTION:
    return {...state, inspections: {...state.inspections, subscription: payload}};
  default:
    return state;
  }
};
