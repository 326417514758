import React from 'react';
import PropTypes from 'prop-types';
import {Grid, MenuItem} from '@material-ui/core';
import {Formik} from '@kbi/component-library';
import {arrayOfLocations} from '../NewCapaModal.js';
import {useUsers} from 'hooks/';
const {DateField, SelectField, AutoComplete, NumberField} = Formik;

const CapaForm = ({values}) => {
  const users = useUsers();

  const originatorField = {
    name: 'originator',
    label: 'Originator',
    options: users,
    optionKey: 'displayName',
  };
  const assigneeField = {
    name: 'assignee',
    label: 'Assignee',
    options: users,
    optionKey: 'displayName',
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <NumberField name='capaNumber' label='CAPA Number' />
      </Grid>
      <Grid item xs={6}>
        <SelectField name='action' label='Action'>
          <MenuItem value='Corrective'>Corrective</MenuItem>
          <MenuItem value='Preventative'>Preventative</MenuItem>
        </SelectField>
      </Grid>
      <Grid item xs={6}>
        <DateField name='startDate' label='Start Date' disabled={Boolean(values.startDeadline || values.endDeadline)} />
      </Grid>
      <Grid item xs={6}>
        <DateField name='endDate' label='End Date' disabled={Boolean(values.startDeadline || values.endDeadline)} />
      </Grid>
      <Grid item xs={6}>
        <DateField name='startDeadline' label='Start Deadline' disabled={Boolean(values.startDate || values.endDate)} />
      </Grid>
      <Grid item xs={6}>
        <DateField name='endDeadline' label='End Deadline' disabled={Boolean(values.startDate || values.endDate)} />
      </Grid>
      <Grid item xs={6}>
        <SelectField name='active' label='Active?'>
          <MenuItem value={true}>True</MenuItem>
          <MenuItem value={false}>False</MenuItem>
        </SelectField>
      </Grid>
      <Grid item xs={6}>
        <SelectField name='completedBeforeDeadline' label='Completed Before Deadline?'>
          <MenuItem value={true}>True</MenuItem>
          <MenuItem value={false}>False</MenuItem>
        </SelectField>
      </Grid>
      <Grid item xs={6}>
        <SelectField name='priority' label='Priority'>
          <MenuItem value='Critical'>Critical</MenuItem>
          <MenuItem value='High'>High</MenuItem>
          <MenuItem value='Med'>Med</MenuItem>
          <MenuItem value='Low'>Low</MenuItem>
        </SelectField>
      </Grid>
      <Grid item xs={6}>
        <SelectField name='location' label='Location'>
          {arrayOfLocations.map(locationString => (<MenuItem key={locationString} value={locationString}>{locationString}</MenuItem>))}
        </SelectField>
      </Grid>
      <Grid item xs={6}>
        <SelectField name='status' label='Status'>
          <MenuItem value='In Progress'>In Progress</MenuItem>
          <MenuItem value='Pending Verification'>Pending Verification</MenuItem>
          <MenuItem value='Complete'>Complete</MenuItem>
        </SelectField>
      </Grid>
      <Grid item xs={6}>
        <AutoComplete {...originatorField} disabled={Boolean(values.assignee)} />
      </Grid>
      <Grid item xs={6}>
        <AutoComplete {...assigneeField} disabled={Boolean(values.originator)} />
      </Grid>
    </Grid>
  );
};

CapaForm.propTypes = {
  values: PropTypes.object.isRequired,
};

export default CapaForm;
