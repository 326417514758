import {SET_DRAWER_OPEN, SET_SMALL_DEVICE} from './types';

const INITIAL_STATE = {
  drawerOpen: undefined,
  smallDevice: undefined,
};

/* eslint-disable-next-line import/no-anonymous-default-export */
export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action;
  switch (type) {
  case SET_DRAWER_OPEN:
    return {...state, drawerOpen: payload};
  case SET_SMALL_DEVICE:
    return {...state, smallDevice: payload};
  default:
    return state;
  }
};
