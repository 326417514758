import React, {Fragment, useState} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {SubmitConfirmation} from 'components/';
import {
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  DialogActions,
  MenuItem,
  Grid,
} from '@material-ui/core';
import * as yup from 'yup';
import {Formik, Alert, Collapse} from '@kbi/component-library';
import {useAxios} from 'hooks/';

const {FormikForm, FormButton, SubmitButton, SelectField} = Formik;

const SelectFormModal = ({close}) => {
  const [formError, setFormError] = useState('');
  const [stage, setStage] = useState(0);
  const [newFormUrl, setNewFormUrl] = useState(null);
  const history = useHistory();
  const {axios, getAuthToken, appEngine} = useAxios();

  const determineFormApiUrl = (formName) => {
    switch (formName) {
    case 'Comprehensive Site Stormwater Compliance Evaluation':
      return `${appEngine}/ehs/ComprehensiveSiteStormwater/CurrentVersion`;
    case 'Non-Stormwater Discharge Visual Observation':
      return `${appEngine}/ehs/NonStormwaterDischarge/CurrentVersion`;
    case 'Predicted Storm Event Pre-Inspection':
      return `${appEngine}/ehs/PredictedStormEvent/CurrentVersion`;
    case 'Stormwater Discharge Visual Observation':
      return `${appEngine}/ehs/StormwaterDischarge/CurrentVersion`;
    default:
      break;
    }
  };

  const formik = {
    initialValues: {
      formName: '',
    },
    validationSchema: yup.object().shape({
      formName: yup.string().required('Form Name is a required field.'),
    }),
    onSubmit: async (values, actions) => {
      try {
        const url = determineFormApiUrl(values.formName);
        const formResponse = await axios({
          method: 'get',
          url: url,
        });

        const formToUse = formResponse.data;
        if (!formToUse) throw new Error();

        const dataForFirestore = createDataForFirestore(formToUse);

        const submitResponse = await axios({
          method: 'post',
          url: `${appEngine}/ehs/firestore/NewStormwaterForm`,
          withCredentials: true,
          headers: {
            'Authorization': await getAuthToken(),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          data: {
            formData: dataForFirestore,
          },
        });

        setNewFormUrl(determineNewFormUrl(submitResponse.data.formId, dataForFirestore.FormTitle));
        setStage(stage + 1);
      }
      catch (err) {
        setFormError('There was an error during submission. Please try again.');
      }
    },
  };

  const core = {
    dialog: {
      open: true,
      scroll: 'body',
      transitionDuration: {exit: 0},
      maxWidth: 'sm',
      fullWidth: true,
    },
    submitConfirmation: {
      text: 'Form successfully created. You will be redirected there upon close.',
      stage: stage === 1 ? 'success' : 'not success',
    },
  };

  return (
    <Dialog {...core.dialog}>
      <FormikForm {...formik}>
        {() => (
          <Fragment>
            {stage !== 1 && <DialogTitle>Select Form to Create</DialogTitle>}
            <DialogContent>
              <Collapse in={stage === 0}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SelectField name='formName' label='Form Name' required>
                      <MenuItem value='Comprehensive Site Stormwater Compliance Evaluation'>Comprehensive Site Stormwater Compliance Evaluation</MenuItem>
                      <MenuItem value='Non-Stormwater Discharge Visual Observation'>Non-Stormwater Discharge Visual Observation</MenuItem>
                      <MenuItem value='Predicted Storm Event Pre-Inspection'>Predicted Storm Event Pre-Inspection</MenuItem>
                      <MenuItem value='Stormwater Discharge Visual Observation'>Stormwater Discharge Visual Observation</MenuItem>
                    </SelectField>
                  </Grid>
                </Grid>
              </Collapse>
              <SubmitConfirmation {...core.submitConfirmation} />
              <Alert in={Boolean(formError)} text={formError} severity='error' />
            </DialogContent>
            {stage === 0 ? (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton variant='text' color='secondary' onClick={close}>Cancel</FormButton>
                <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
              </DialogActions>
            ) : (
              <DialogActions style={{justify: 'space-between'}}>
                <Button variant='text' color='primary' onClick={() => history.push(newFormUrl)}>Close</Button>
              </DialogActions>
            )}
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

SelectFormModal.propTypes = {
  close: PropTypes.func.isRequired,
};

export default SelectFormModal;

function createDataForFirestore(formToUse) {
  const returnedData = {};
  formToUse.FormFields.forEach(section => {
    section.Fields.forEach(fieldObj => {
      if (fieldObj.label === 'Date') returnedData[fieldObj.label] = new Date();
      else returnedData[fieldObj.label] = '';
    });
  });
  returnedData.FormTitle = formToUse.FormTitle;
  returnedData.FormVersion = formToUse.Version;
  returnedData.Completed = false;

  return returnedData;
}
function determineNewFormUrl(docRef, formName) {
  switch (formName) {
  case 'Comprehensive Site Stormwater Compliance Evaluation':
    return `/stormwater/comprehensive-stormwater/${docRef}`;
  case 'Non-Stormwater Discharge Visual Observation':
    return `/stormwater/non-stormwater-discharge/${docRef}`;
  case 'Predicted Storm Event Pre-Inspection':
    return `/stormwater/predicted-storm/${docRef}`;
  case 'Stormwater Discharge Visual Observation':
    return `/stormwater/stormwater-discharge/${docRef}`;
  default:
    break;
  }
}
