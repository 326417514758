import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acStartInspectionListener} from 'state/firestore/actions.js';

const useInspections = () => {
  const dispatch = useDispatch();
  const inspections = useSelector(state => state.firestore.inspections);

  useEffect(() => {
    if (!inspections) dispatch(acStartInspectionListener);
  }, [dispatch, inspections]);

  return inspections;
};

export default useInspections;
