import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {SubmitConfirmation} from 'components/';
import {Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText} from '@material-ui/core';
import {Alert, Collapse, Formik} from '@kbi/component-library';
import * as yup from 'yup';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useAxios} from 'hooks/';
const {FormikForm, SubmitButton, FormButton} = Formik;

const RootCauseModal = ({close, selectedCapa}) => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');
  const [newFormUrl, setNewFormUrl] = useState('');

  const history = useHistory();
  const {appEngine, axios, getAuthToken} = useAxios();
  const currentUser = useSelector(state => state.auth.currentUser);

  const formikProps = {
    initialValues: {
    },
    validationSchema: yup.object().shape({}),
    onSubmit: async (values, actions) => {
      try {
        const dataForFirestore = createDataForFirestore(selectedCapa);

        const submitResponse = await axios({
          method: 'post',
          url: `${appEngine}/ehs/firestore/NewRootCauseAnalysis`,
          withCredentials: true,
          headers: {
            'Authorization': await getAuthToken(),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          data: {
            formData: dataForFirestore,
            currentUser,
          },
        });

        setNewFormUrl(`/root-cause/${submitResponse.data.formId}`);
        setFormError('');
        setStage(stage + 1);
      }
      catch (err) {
        setFormError('There was an error during submission. Please try again.');
      }
    },
  };

  return (
    <Dialog open={true} maxWidth='sm' fullWidth scroll='body' transitionDuration={{exit: 0}}>
      <FormikForm {...formikProps}>
        {({values}) => (
          <Fragment>
            {stage !== 1 && <DialogTitle>Create New Root Cause Analysis</DialogTitle>}
            <DialogContent>

              <Collapse in={stage === 0}>
                <DialogContentText>
                  Press submit to create a new root cause analysis.
                  {selectedCapa ? ` CAPA ${selectedCapa.CapaNumber} will default as a primary CAPA for this analysis.` : ''} You will be redirected to it after closing this modal.
                </DialogContentText>
              </Collapse>
              <SubmitConfirmation stage={stage === 1 ? 'success' : ''} text='Root cause analysis successfully created. Redirecting after close.' />
              <Alert in={Boolean(formError)} text={formError} severity='error' />
            </DialogContent>
            {stage === 0 ? (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton variant='text' color='secondary' onClick={close}>Cancel</FormButton>
                <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
              </DialogActions>
            ) : (
              <DialogActions style={{justify: 'space-between'}}>
                <FormButton variant='text' color='primary' onClick={() => history.push(newFormUrl)}>Close</FormButton>
              </DialogActions>
            )}
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

RootCauseModal.propTypes = {
  close: PropTypes.func.isRequired,
  selectedCapa: PropTypes.object,
};

export default RootCauseModal;

function createDataForFirestore(selectedCapa) {
  return {
    StartDate: new Date(),
    PrimaryCapas: selectedCapa ? [
      {
        Assignees: selectedCapa.AssigneesString,
        Number: selectedCapa.CapaNumber,
        Ref: selectedCapa.CapaId,
        Location: selectedCapa.Location,
        Observation: selectedCapa.Observation,
      },
    ] : [],
    SupplementalCapas: [],
    RootCauses: [],
    DirectCauses: [],
    RecommendedActions: [],
    Analysis: null,
    AdditionalComments: null,
    TrainingRequired: null,
    TrainingRecommended: null,
    Status: 'In Progress',
  };
}
