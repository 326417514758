import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acStartApcLogListener} from 'state/firestore/actions.js';

const useApcLogs = () => {
  const dispatch = useDispatch();
  const apcLogs = useSelector(state => state.firestore.apcLogs);

  useEffect(() => {
    if (!apcLogs) dispatch(acStartApcLogListener);
  }, [dispatch, apcLogs]);

  return apcLogs;
};

export default useApcLogs;
