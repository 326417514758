import React from 'react';
import PropTypes from 'prop-types';
import {ImgThumbnail} from '../ActionsTakenPanel/ActionsTakenModal/';
import {Grid, Paper, makeStyles, Typography} from '@material-ui/core';
import moment from 'moment';

const ActionPaper = ({action, urlMap}) => {
  const styles = useStyles();
  return (
    <Paper className={styles.paper}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography>
            <span className={styles.bold}>Date: </span>
            {moment(action.Date).format('MM/DD/YY h:mma')}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            <span className={styles.bold}>By: </span>
            {action.CreatedBy}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            <span className={styles.bold}>Action Type: </span>
            {action.ActionType}
          </Typography>
        </Grid>
        {action.NotifyListString && <Grid item xs={12}>
          <Typography>
            <span className={styles.bold}>Notified: </span>
            {action.NotifyListString}
          </Typography>
        </Grid>}
        <Grid item xs={12}>
          <Typography>
            <span className={styles.bold}>Action Taken/Comment: </span>
            {action.Comment}
          </Typography>
        </Grid>
        {action.FileNames.map(name => {
          const fileSuffix = name.split('.').pop().toLowerCase();
          return <Grid item xs={12} sm={6} key={name}>
            <ImgThumbnail notImg={fileSuffix === 'doc' || fileSuffix === 'docx' || fileSuffix === 'pdf'} src={urlMap[action.ActionId][name]} name={name} />
          </Grid>;
        })}
      </Grid>
    </Paper>

  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '16px 16px',
    breakInside: 'avoid',
    pageBreakInside: 'avoid',
    display: 'block',
  },
  bold: {
    fontWeight: 'bold',
  },
}));

ActionPaper.propTypes = {
  action: PropTypes.object.isRequired,
  urlMap: PropTypes.object.isRequired,
};

export default ActionPaper;
