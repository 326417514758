import {SET_CURRENT_USER, UNSET_CURRENT_USER, SET_USERS} from './types';

const INITIAL_STATE = {
  currentUser: null,
  users: null,
};

/* eslint-disable-next-line import/no-anonymous-default-export*/
export default (state = INITIAL_STATE, action) => {
  const {payload, type} = action;
  switch (type) {
  case SET_CURRENT_USER:
    return {...state, currentUser: payload};
  case UNSET_CURRENT_USER:
    return {...state, currentUser: null};
  case SET_USERS:
    return {...state, users: payload};
  default:
    return state;
  }
};
