import React from 'react';
import PropTypes from 'prop-types';
import {Table} from '@kbi/component-library';
import {Paper} from '@material-ui/core';


const TableWithPaper = ({paperProps, ...tableProps}) => {
  return (
    <Paper style={{margin: '24px 0px'}} {...paperProps}>
      <Table {...tableProps} />
    </Paper>
  );
};

TableWithPaper.propTypes = {
  paperProps: PropTypes.object,
};

export default TableWithPaper;
