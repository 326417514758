import React, {useState, useEffect} from 'react';
import {Grid, LinearProgress} from '@material-ui/core';
import {Timeline} from '@material-ui/icons';
import {
  RootCausePanel,
  SupplementalCapaPanel,
  PrimaryCapaPanel,
  DirectCausePanel,
  TrainingPanel,
  OverviewPanel,
  RecommendedActionsPanel,
  AttachmentPanel,
  MarkAsCompleteModal,
} from './RootCauseAnalysisDetails/';
import {DetailsPanel} from '@kbi/component-library';
import {useHistory} from 'react-router-dom';
import {useRootCauses} from 'hooks/';
import moment from 'moment';

const RootCauseAnalysisDetails = () => {
  const [selectedRootCause, setSelectedRootCause] = useState(null);
  const [markAsCompleteModalOpen, setMarkAsCompleteModalOpen] = useState(false);

  const history = useHistory();
  const rootCauses = useRootCauses();

  useEffect(() => {
    const splitPathname = history.location.pathname.split('/');
    if (rootCauses?.ref) {
      setSelectedRootCause(rootCauses.ref[splitPathname[splitPathname.length - 1]]);
    }
  }, [history.location.pathname, rootCauses]);


  // this is set up to stop the creation of initial values of formik if there is a selected form
  if (!selectedRootCause) return <LinearProgress />;

  return <Grid container spacing={5}>
    <Grid item xs={12} md={3} className='hidePrint'>
      <DetailsPanel title='Root Cause Analysis'
        subtitle={selectedRootCause.Status}
        icon={Timeline}
        fields={[
          {label: 'Form ID', value: selectedRootCause.RootCauseId},
          {label: 'Start Date', value: moment(selectedRootCause.StartDate).format('MM/DD/YYYY')},
        ]}
        buttons={[
          {text: 'Mark As Complete', onClick: () => setMarkAsCompleteModalOpen(true), visible: isSelectedRootCauseReadyForCompletion(selectedRootCause)},
        ]}
      />
    </Grid>
    <Grid item xs={12} md={9} className='hidePrint'>
      <OverviewPanel selectedRootCause={selectedRootCause} />
      <DirectCausePanel selectedRootCause={selectedRootCause} />
      <RootCausePanel selectedRootCause={selectedRootCause} />
      <PrimaryCapaPanel selectedRootCause={selectedRootCause} />
      <SupplementalCapaPanel selectedRootCause={selectedRootCause} />
      <TrainingPanel selectedRootCause={selectedRootCause} />
      <RecommendedActionsPanel selectedRootCause={selectedRootCause} />
      <AttachmentPanel selectedRootCause={selectedRootCause} />
    </Grid>
    {markAsCompleteModalOpen && <MarkAsCompleteModal selectedRootCause={selectedRootCause} close={() => setMarkAsCompleteModalOpen(false)} />}
  </Grid>;
};

function isSelectedRootCauseReadyForCompletion(selectedRootCause) {
  if (!selectedRootCause.Analysis === null) return false;
  else if (!selectedRootCause.DirectCauses.length) return false;
  else if (!selectedRootCause.PrimaryCapas.length && !selectedRootCause.SupplementalCapas.length) return false;
  else if (!selectedRootCause.RecommendedActions.length) return false;
  else if (!selectedRootCause.RootCauses.length) return false;
  else if (selectedRootCause.TrainingRequired === null) return false;
  else if (selectedRootCause.Status === 'Completed') return false;
  else return true;
}

export default RootCauseAnalysisDetails;
