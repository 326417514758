/* eslint-disable-next-line import/no-anonymous-default-export */
export default [
  {value: 'Employee took shortcuts with safety'},
  {value: 'Employee knew job procedured but deviated from them'},
  {value: 'Hazardous condition not recognized'},
  {value: 'Employee complacent or not paying attention to work'},
  {value: 'Defective or improperly working machine'},
  {value: 'Incorrect equipment or tools being used for job'},
  {value: 'Poorly maintained equipment or machinery'},
  {value: 'Unguarded machinery•'},
  {value: 'Employee defeated or bypassed machine guarding'},
  {value: 'Wrong or missing PPE-Proper PPE not specified for job or employee did not know requirements'},
  {value: 'Wrong or missing PPE-Employee knew proper PPE requirements, but did not follow them'},
  {value: 'Material handling error'},
  {value: 'Horseplay (fooling around)'},
  {value: 'Employee put hand, fingers, or other body part in harm\'s way'},
  {value: 'Employee(s) used damaged or worn equipment'},
  {value: 'Improper or inadequate packaged, labeling, or identification of material'},
  {value: 'Supervisor did not maintain labeling and/or accumulation in compliance'},
  {value: 'Other'},
];
