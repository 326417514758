import React, {useState, Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import {SubmitConfirmation, AsyncLoader} from 'components/';
import {Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Grid, MenuItem, makeStyles, Typography, Fab} from '@material-ui/core';
import {DeleteForeverOutlined} from '@material-ui/icons';
import {Alert, Collapse, Formik} from '@kbi/component-library';
import * as yup from 'yup';
import {useSelector} from 'react-redux';
import {Storage} from 'config.js';
import Dropzone from 'react-dropzone';
import Jimp from 'jimp';
import {ImgThumbnail} from './ActionsTakenModal/';
import moment from 'moment';
import {useUsers, useAxios} from 'hooks';
const {FormikForm, AutoCompleteObject, SelectField, SubmitButton, FormButton, TextField, DateField} = Formik;

const ActionTakenModal = ({close, selectedCapa, actionToView, type}) => {
  const styles = useStyles();
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');
  const [fileArray, setFileArray] = useState([]);
  const [fileAlert, setFileAlert] = useState('');

  const currentUser = useSelector(state => state.auth.currentUser);
  const users = useUsers();
  const {axios, getAuthToken, appEngine} = useAxios();

  useEffect(() => {
    if (actionToView) {
      const arrayOfUrlRequests = [];
      actionToView.FileNames.forEach(name => {
        arrayOfUrlRequests.push(Storage.ref(`CAPA/${selectedCapa.CapaId}/${actionToView.ActionId}/${name}`).getDownloadURL());
      });
      Promise.all(arrayOfUrlRequests).then(values => {
        setFileArray(actionToView.FileNames.map((name, index) => ({
          base64: values[index],
          fileName: name,
        })));
      }).catch(error => {
        setFileAlert('There was an error loading the images.');
      });
    }
  }, [actionToView, selectedCapa]);

  const formikProps = {
    initialValues: {
      actionType: actionToView ? actionToView.ActionType : '',
      notifyList: actionToView ? actionToView.NotifyList :
        type === 'verification' ? [...selectedCapa.Originators, ...selectedCapa.Assignees] : [],
      approved: actionToView ? actionToView.Approved : '',
      comment: actionToView ? actionToView.Comment : '',
      newDeadline: actionToView?.NewDeadline ? moment(actionToView.NewDeadline).format('YYYY-MM-DD') : '',
      date: actionToView ? moment(actionToView.Date).format('YYYY-MM-DDTHH:mm') : moment().format('YYYY-MM-DDTHH:mm'),
    },
    validationSchema: (() => {
      if (type === 'new action') {
        return yup.object().shape({
          actionType: yup.string().required('Action Type is a required field.'),
          date: yup.date().max(new Date(), 'Date cannot be in the future.').required('Date is a required field.'),
          notifyList: yup.array(),
          comment: yup.string().required('This is a required field.'),
        });
      }
      else if (type === 'verification') {
        return yup.object().shape({
          approved: yup.boolean().required('Approved is a required field.'),
          date: yup.date().max(new Date(), 'Date cannot be in the future.').required('Date is a required field.'),
          comment: yup.string().when('approved', {
            is: val => !val,
            then: yup.string().required('A comment is required for any disapproved verifications.'),
            otherwise: yup.string(),
          }),
        });
      }
      else if (type === 'deadline') {
        return yup.object().shape({
          newDeadline: yup.date().required('New Deadline is a required field.'),
          comment: yup.string().required('Comment is required for a change of deadline.'),
        });
      }
    }),
    onSubmit: async (values, actions) => {
      const newAction = createDataForFirestore(values);

      const formData = new FormData();
      formData.append('newAction', JSON.stringify(newAction));
      formData.append('docId', selectedCapa.CapaId);
      formData.append('capaNumber', selectedCapa.CapaNumber);

      for (let i = 0; i < fileArray.length; i++) {
        // this is here in order to convert the images uploaded through JIMP to be converted into blobs, able to be sent over formData.
        // if they are a pdf, and did not go through JIMP, then just take the file prop.
        const blob = fileArray[i].buffer ? new Blob([fileArray[i].buffer], {type: 'image/jpg'}) : fileArray[i].file;
        formData.append('fileData', blob, fileArray[i].fileName);
      }

      try {
        let urlToSend;
        if (type === 'deadline') urlToSend = `${appEngine}/ehs/firestore/UpdateCapaDeadline`;
        else if (type === 'verification') urlToSend = `${appEngine}/ehs/firestore/VerifyCapaAction`;
        else if (type === 'new action') urlToSend = `${appEngine}/ehs/firestore/NewActionTaken`;

        const response = await axios.post(urlToSend, formData, {
          headers: {
            'Authorization': await getAuthToken(),
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true,
        });

        if (!response ) throw new Error();

        setStage(stage + 1);
        setFormError('');
        setFileAlert('');
      }
      catch (err) {
        console.log('err', err );
        setFormError('There was an error during submission. Please try again.');
      }
    },
  };

  const dropzoneProps = {
    accept: 'application/pdf, image/*, .doc, .docx, application/msword',
    multiple: false,
    onDropAccepted: async file => {
      setFileAlert('');

      let fileForState = null;
      if (file[0].type !== 'image/*') {
        fileForState = {
          fileName: file[0].name,
          base64: null,
          file: file[0],
          isImg: false,
        };
      }
      else {
        const jimpObj = await Jimp.read(URL.createObjectURL(file[0]));

        const getImageBase64 = async () => {
          let src = null;
          if (jimpObj.bitmap.width < 600) src = jimpObj.getBase64Async(Jimp.MIME_JPEG);
          else src = await jimpObj.quality(60).getBase64Async(Jimp.MIME_JPEG);
          return src;
        };
        const getImageBuffer = async () => {
          let src = null;
          if (jimpObj.bitmap.width < 600) src = jimpObj.getBufferAsync(Jimp.MIME_JPEG);
          else src = await jimpObj.quality(60).getBufferAsync(Jimp.MIME_JPEG);
          return src;
        };

        fileForState = {
          fileName: file[0].name.split('.')[0] + '.jpeg',
          base64: await getImageBase64(),
          buffer: await getImageBuffer(),
          isImg: true,
        };
      }

      setFileArray([fileForState, ...fileArray]);
    },
    onDropRejected: (file, event) => {
      setFileAlert('The file type must be an image or PDF.');
    },
  };

  const createDataForFirestore = values => {
    const formattedData = {
      Date: moment(values.date).toDate() || new Date(),
      CreatedBy: currentUser.displayName,
      ActionType: values.actionType,
      NotifyList: values.notifyList,
      FileNumber: fileArray.length,
      FileNames: fileArray.map(file => file.fileName),
      Comment: values.comment,
      System: {
        CreatedBy: currentUser.displayName,
        CreatedOn: new Date(),
      },
    };

    if (type === 'verification') {
      formattedData.Approved = values.approved;
      formattedData.ActionType = values.approved ? 'Verified' : 'Rejected';
    }
    if (type === 'deadline') {
      formattedData.NewDeadline = moment(values.newDeadline).toDate();
      formattedData.OriginalDeadline = selectedCapa.Deadline;
      formattedData.NotifyList = selectedCapa.Originators;
      formattedData.ActionType = 'Deadline Updated';
    }

    return formattedData;
  };

  const notifyListField = {
    name: 'notifyList',
    label: 'Notify List',
    options: users,
    optionKey: 'displayName',
    multiple: true,
  };
  const actionTypeField = {
    name: 'actionType',
    label: 'Action Type',
    required: true,
    fast: true,
    disabled: Boolean(actionToView),
    onChange: ({event, form}) => {
      if (event.target.value === 'Final') {
        form.setFieldValue('notifyList', [...selectedCapa.Originators]);
      }
    },
  };

  const deleteButton = index => ({
    size: 'small',
    color: 'secondary',
    className: styles.fab,
    onClick: (e) => {
      const newFileArray = [...fileArray];
      newFileArray.splice(index, 1);
      setFileArray(newFileArray);
    },
  });

  return (
    <Dialog open={true} maxWidth='md' fullWidth scroll='body' transitionDuration={{exit: 0}}>
      <FormikForm {...formikProps}>
        {({values}) => (
          <Fragment>
            {stage !== 1 && <DialogTitle>{actionToView ? 'View Action Taken' : 'Enter Action Taken'}</DialogTitle>}
            <DialogContent>

              <Collapse in={stage === 0}>
                <Collapse in={type === 'new action'}>
                  {!actionToView && <DialogContentText>Choose an action catagory, and fill out the relevant details:</DialogContentText>}
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={4}>
                      <DateField name='date' label='Date' required fast disabled={Boolean(actionToView)} type='datetime-local' />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <SelectField {...actionTypeField}>
                        <MenuItem value='Interim'>Interim</MenuItem>
                        <MenuItem value='Final'>Final</MenuItem>
                      </SelectField>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <AutoCompleteObject {...notifyListField} disabled={Boolean(actionToView) || values.actionType === 'Final'} />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField name='comment' label='Action Taken/Comments' fast required multiline disabled={Boolean(actionToView)} />
                    </Grid>
                  </Grid>
                </Collapse>

                <Collapse in={type === 'verification'}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <DateField name='date' label='Date' required fast disabled={Boolean(actionToView)} type='datetime-local' />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectField name='approved' label='Approve?' required fast disabled={Boolean(actionToView)}>
                        <MenuItem value={true}>Approve</MenuItem>
                        <MenuItem value={false}>Reject</MenuItem>
                      </SelectField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField name='comment' label='Comment' required={!values.approved} multiline disabled={Boolean(actionToView)} />
                    </Grid>
                  </Grid>
                </Collapse>

                <Collapse in={type === 'deadline'}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}>
                      <DateField name='newDeadline' label='New Deadline' required fast disabled={Boolean(actionToView)} />
                    </Grid>
                    <Grid item xs={6} sm={9}>
                      <TextField name='comment' label='Comment' required multiline disabled={Boolean(actionToView)} />
                    </Grid>
                  </Grid>
                </Collapse>

                <Collapse in={type === 'new action' || type === 'verification'}>
                  <Grid container spacing={2} style={{marginTop: '8px'}}>
                    {actionToView?.FileNumber && !fileArray.length ? <Grid item xs={12}><AsyncLoader /></Grid> : null}
                    {!actionToView ? (
                      <Grid item xs={12}>
                        <Dropzone {...dropzoneProps}>
                          {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()} className={styles.fileDrop}>
                              <input {...getInputProps()} />
                              <div className={styles.divStyles}>
                                <Typography variant='subtitle1'>
                              Drop File Here or Click Inside the Box to Attach
                                </Typography>
                                <Typography variant='subtitle1'>
                              Accepts Images and PDF
                                </Typography>
                                <Typography variant='body2'>
                              (Not Required)
                                </Typography>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </Grid>) : null}
                    {fileArray.map((file, index) => (
                      <Grid item xs={12} sm={6} key={`Image#${index}`} container style={{position: 'relative'}}>
                        {!actionToView && <Fab {...deleteButton(index)}><DeleteForeverOutlined size='small' /></Fab>}
                        <ImgThumbnail notImg={!file.isImg} src={file.base64} allowOpen={Boolean(actionToView)} name={file.fileName} />
                      </Grid>
                    ))}
                  </Grid>
                </Collapse>

              </Collapse>
              <SubmitConfirmation stage={stage === 1 ? 'success' : ''} text='Action successfully taken.' />
              <Alert in={Boolean(formError)} text={formError} severity='error' />
              <Alert in={Boolean(fileAlert)} text={fileAlert} severity='error' />
            </DialogContent>
            {stage === 0 && !actionToView ? (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton variant='text' color='secondary' onClick={close}>Cancel</FormButton>
                <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
              </DialogActions>
            ) : (
              <DialogActions style={{justify: 'space-between'}}>
                <FormButton variant='text' color='primary' onClick={close}>Close</FormButton>
              </DialogActions>
            )}
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'absolute',
    right: 20,
    top: 20,
    backgroundColor: 'rgb(255,0,87,0.6)',
  },
  divStyles: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  fileDrop: {
    alignItems: 'center',
    backgroundColor: 'aliceblue',
    border: '2px gray dotted',
    display: 'flex',
    height: '200px',
    justifyContent: 'center',
  },
}));

ActionTakenModal.propTypes = {
  close: PropTypes.func.isRequired,
  selectedCapa: PropTypes.object.isRequired,
  actionToView: PropTypes.object,
  type: PropTypes.oneOf(['deadline', 'verification', 'new action']).isRequired,
};

export default ActionTakenModal;
