import React, {useMemo} from 'react';
import {ExpandMore} from '@material-ui/icons';
import {Table} from '@kbi/component-library';
import {
  Accordion,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import {useInspections} from 'hooks/';
import moment from 'moment';

function findAndFormatFormByName(arrayToSearch, formTitle) {
  const form = arrayToSearch.find(form => form.FormTitle === formTitle);
  if (form) {
    return {
      FormTitle: formTitle,
      LastCompleted: form.FormData.Date,
      DaysUntilDue: form.Frequency - moment().diff(moment(form.FormData.Date), 'days'),
    };
  }
  else {
    return {
      FormTitle: formTitle,
      LastCompleted: '',
      DaysUntilDue: 0,
    };
  }
}

const PeriodicInspectionPanel = () => {
  const inspecitons = useInspections();

  const dataForTable = useMemo(() => {
    const sortedAndFilteredInspections = inspecitons.list.filter(item => item.Completed && item.Frequency > 0).sort((a, b) => moment(a.FormData.Date).isBefore(b.FormData.Date) ? 1 : -1);

    const returnedDataArray = [];
    returnedDataArray.push(findAndFormatFormByName(sortedAndFilteredInspections, 'Weekly Eyewash Inspection'));
    returnedDataArray.push(findAndFormatFormByName(sortedAndFilteredInspections, 'Monthly Emergency Equipment Inspection'));
    returnedDataArray.push(findAndFormatFormByName(sortedAndFilteredInspections, 'Monthly Enclosure Inspection'));
    returnedDataArray.push(findAndFormatFormByName(sortedAndFilteredInspections, 'Monthly Security Inspection'));
    returnedDataArray.push(findAndFormatFormByName(sortedAndFilteredInspections, 'Quarterly Duct Inspection'));

    return returnedDataArray;
  }, [inspecitons]);

  return <Accordion defaultExpanded={true}>
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Typography variant='h6'>Periodic Inspection Summary</Typography>
    </AccordionSummary>
    <Table
      data={dataForTable}
      columns={[
        {Header: 'Form Name', accessor: 'FormTitle'},
        {Header: 'Days Until Due', accessor: 'DaysUntilDue', type: 'numeric'},
        {Header: 'Last Completed', accessor: 'LastCompleted', type: 'datetime'},
      ]}
      paginationActive={false}
      disableFilters={true}
    />
  </Accordion>;
};

export default PeriodicInspectionPanel;
