export const SET_WASTEWATER_DOCS = 'module_kbi_ehs/state/firestore/SET_WASTEWATER_DOCS';
export const SET_WASTEWATER_SUBSCRIPTION = 'module_kbi_ehs/state/firestore/SET_WASTEWATER_SUBSCRIPTION';
export const FORGET_WASTEWATER = 'module_kbi_ehs/state/firestore/FORGET_WASTEWATER';
export const SET_WASTEWATER_FILES = 'module_kbi_ehs/state/firestore/SET_WASTEWATER_FILES';
export const SET_WASTEWATER_FILE_SUBSCRIPTION = 'module_kbi_ehs/state/firestore/SET_WASTEWATER_FILE_SUBSCRIPTION';
export const FORGET_WASTEWATER_FILES = 'module_kbi_ehs/state/firestore/FORGET_WASTEWATER_FILES';
export const SET_STORMWATER_FILES = 'module_kbi_ehs/state/firestore/SET_STORMWATER_FILES';
export const SET_STORMWATER_FILE_SUBSCRIPTION = 'module_kbi_ehs/state/firestore/SET_STORMWATER_FILE_SUBSCRIPTION';
export const FORGET_STORMWATER_FILES = 'module_kbi_ehs/state/firestore/FORGET_STORMWATER_FILES';
export const SET_APC_DOCS = 'module_kbi_ehs/state/firestore/SET_APC_DOCS';
export const SET_APC_SUBSCRIPTIONS = 'module_kbi_ehs/state/firestore/SET_APC_SUBSCRIPTIONS';
export const FORGET_APC = 'module_kbi_ehs/state/firestore/FORGET_APC';
export const SET_STORMWATER_DOCS = 'module_kbi_ehs/state/firestore/SET_STORMWATER_DOCS';
export const SET_STORMWATER_SUBSCRIPTION = 'module_kbi_ehs/state/firestore/SET_STORMWATER_SUBSCRIPTION';
export const FORGET_STORMWATER = 'module_kbi_ehs/state/firestore/FORGET_STORMWATER';
export const SET_CAPA_DOCS = 'module_kbi_ehs/state/firestore/SET_CAPA_DOCS';
export const SET_CAPA_SUBSCRIPTION = 'module_kbi_ehs/state/firestore/SET_CAPA_SUBSCRIPTION';
export const SET_ROOT_CAUSE_DOCS = 'module_kbi_ehs/state/firestore/SET_ROOT_CAUSE_DOCS';
export const SET_ROOT_CAUSE_SUBSCRIPTION = 'module_kbi_ehs/state/firestore/SET_ROOT_CAUSE_SUBSCRIPTION';
export const SET_INSPECTION_DOCS = 'module_kbi_ehs/state/firestore/SET_INSPECTION_DOCS';
export const SET_INSPECTION_SUBSCRIPTION = 'module_kbi_ehs/state/firestore/SET_INSPECTION_SUBSCRIPTION';
