import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography, AccordionActions} from '@material-ui/core';
import {DirectCausesModal} from './Modals/';
import {StyledTextField} from 'components/';
import {ExpandMore} from '@material-ui/icons';

const DirectCausePanel = ({selectedRootCause}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return <Accordion defaultExpanded={false}>
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Typography>Direct Causes</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container spacing={2}>
        {selectedRootCause.DirectCauses.length ? selectedRootCause.DirectCauses.map((cause, index) => (
          <Fragment key={index}>
            <Grid item xs={6} >
              <StyledTextField value={cause.DirectCause} label='Direct Cause' />
            </Grid>
            <Grid item xs={6} key={index}>
              <StyledTextField value={cause.Explanation} label='Explanation' />
            </Grid>
          </Fragment>
        )) : null}
        {!selectedRootCause.DirectCauses.length ? <Grid item xs={12}><Typography>Direct Causes have not been entered yet.</Typography></Grid> : null}
      </Grid>
    </AccordionDetails>
    <AccordionActions>
      {selectedRootCause.Status !== 'Completed' ? <Button variant='outlined' onClick={() => setModalOpen(true)} color='primary'>Update Direct Causes</Button> : null}
    </AccordionActions>
    {modalOpen && <DirectCausesModal selectedRootCause={selectedRootCause} close={() => setModalOpen(false)} />}
  </Accordion>;
};

DirectCausePanel.propTypes = {
  selectedRootCause: PropTypes.object.isRequired,
};

export default DirectCausePanel;
