import React, {useState, Fragment} from 'react';
import {TableWithPaper, AddFileModal} from 'components/';
import {LinearProgress} from '@material-ui/core';
import {Add, CloudUpload, Search, CloudDownload} from '@material-ui/icons';
import {useHistory} from 'react-router-dom';
import {SelectFormModal} from './Stormwater/';
import {useStormwaterDocs, useStormwaterFiles} from '../hooks/';
import {Storage} from 'config.js';
import {useSelector} from 'react-redux';

const Stormwater = () => {
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [formModalOpen, setFormModalOpen] = useState(false);
  const history = useHistory();
  const stormwaterDocs = useStormwaterDocs();
  const stormwaterFiles = useStormwaterFiles();
  const currentUser = useSelector(state => state.auth.currentUser);

  const inspectionsTable = {
    data: stormwaterDocs?.list || [],
    columns: [
      {Header: 'Form Name', accessor: 'FormTitle'},
      {Header: 'Date', accessor: 'Date', type: 'datetime', datetimeFormat: 'MM/DD/YYYY h:mma'},
      {Header: 'Completed By', accessor: 'System.CompletedBy'},
      {Header: 'Facility', accessor: 'Facility'},
      {Header: 'Completed?', accessor: 'Completed', type: 'boolean'},
    ],
    sortInitial: [{id: 'Date', desc: true}],
    actionsPerRow: [{
      icon: Search,
      tooltip: 'View Form',
      onClick: ({rowData}) => {
        let route = null;
        switch (rowData.FormTitle) {
        case 'Comprehensive Site Stormwater Compliance Evaluation':
          route = '/stormwater/comprehensive-stormwater';
          break;
        case 'Non-Stormwater Discharge Visual Observation':
          route = '/stormwater/non-stormwater-discharge';
          break;
        case 'Predicted Storm Event Pre-Inspection':
          route = '/stormwater/predicted-storm';
          break;
        case 'Stormwater Discharge Visual Observation':
          route = '/stormwater/stormwater-discharge';
          break;
        default:
          break;
        }
        history.push(`${route}/${rowData.stormwaterId}`);
      },
    }],
    actionsBar: [
      {
        icon: Add,
        text: 'Add Stormwater Form',
        buttonProps: {
          disabled: currentUser.roleEHS === 'CAPA User',
        },
        onClick: () => setFormModalOpen(true),
      },
    ],
    title: 'Stormwater Inspections',
    paperProps: {
      style: {
        margin: '0px',
      },
    },
  };
  const documentsTable = {
    data: stormwaterFiles?.list || [],
    columns: [
      {accessor: 'DateUploaded', Header: 'Date Uploaded', type: 'timestamp'},
      {accessor: 'FileName', Header: 'File Name'},
      {accessor: 'Owner.Name', Header: 'Uploaded By'},
    ],
    actionsBar: [
      {
        icon: CloudUpload,
        text: 'Upload File',
        onClick: () => setFileModalOpen(true),
      },
    ],
    actionsPerRow: [{
      icon: CloudDownload,
      tooltip: 'Open Document',
      onClick: ({rowData}) =>
        Storage.ref(`Stormwater_Files/${rowData.stormwaterFileId}.${rowData.FileExt}`).getDownloadURL().then(url => window.open(url, '__blank')),
    }],
    sortInitial: [{id: 'DateUploaded', desc: true}],
    title: 'Stormwater Documents',
  };
  const fileModalProps = {
    stormwater: true,
    addFileModalOpen: true,
    close: () => setFileModalOpen(false),
  };

  if (!stormwaterDocs?.ref || !stormwaterFiles?.ref) return <LinearProgress />;

  return (
    <Fragment>
      <TableWithPaper {...inspectionsTable} />
      <TableWithPaper {...documentsTable} />
      {fileModalOpen && <AddFileModal {...fileModalProps} />}
      {formModalOpen && <SelectFormModal close={() => setFormModalOpen(false)} />}
    </Fragment>
  );
};

export default Stormwater;
