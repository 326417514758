import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Accordion, AccordionDetails, AccordionSummary, Button, Typography, AccordionActions, Grid} from '@material-ui/core';
import {OverviewModal} from './Modals/';
import {StyledTextField} from 'components/';
import {ExpandMore} from '@material-ui/icons';

const OverviewPanel = ({selectedRootCause}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return <Accordion defaultExpanded={true}>
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Typography>Overview</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container spacing={2}>
        {selectedRootCause.Analysis && (
          <Grid item xs={6}><StyledTextField value={selectedRootCause.Analysis} label='Analysis' /></Grid>
        )}
        {selectedRootCause.AdditionalComments && (
          <Grid item xs={6}><StyledTextField value={selectedRootCause.AdditionalComments} label='Additional Comments' /></Grid>
        )}
        {!selectedRootCause.Analysis && <Grid item xs={12}><Typography>An Overview has not been entered yet.</Typography></Grid>}
      </Grid>
    </AccordionDetails>
    <AccordionActions>
      {selectedRootCause.Status !== 'Completed' ? <Button variant='outlined' onClick={() => setModalOpen(true)} color='primary'>Update Overview</Button> : null}
    </AccordionActions>
    {modalOpen && <OverviewModal selectedRootCause={selectedRootCause} close={() => setModalOpen(false)} />}
  </Accordion>;
};

OverviewPanel.propTypes = {
  selectedRootCause: PropTypes.object.isRequired,
};

export default OverviewPanel;
