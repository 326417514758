import React, {useMemo, useState, Fragment} from 'react';
import {Paper, Typography, LinearProgress} from '@material-ui/core';
import {ImportExport, Search, ChevronLeft, Edit, Add, CloudDownload} from '@material-ui/icons';
import {Table} from '@kbi/component-library';
import {exportToCSV} from '@kbi/utility-library';
import {ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Legend} from 'recharts';
import {DailyLogModal} from './Wastewater/';
import {useWastewaterDocs, useWastewaterFiles} from 'hooks/';
import {AddFileModal} from 'components/';
import {Storage} from 'config.js';
import moment from 'moment';
import {useSelector} from 'react-redux';

const Wastewater = () => {
  const [monthlyDocs, setMonthlyDocs] = useState([]);
  const [modal, setModal] = useState(null);
  const wastewaterDocs = useWastewaterDocs();
  const wastewaterFiles = useWastewaterFiles();
  const currentUser = useSelector(state => state.auth.currentUser);

  const wastewaterByMonth = useMemo(() => {
    if (wastewaterDocs?.list) {
      const mapOfWaste = {};
      wastewaterDocs.list.forEach(doc => {
        const monthYear = moment(doc.Date.toDate()).format('MM/YYYY');
        if (mapOfWaste[monthYear]) {
          mapOfWaste[monthYear].docs.push(doc);
          mapOfWaste[monthYear].Acid += doc.Acid;
          mapOfWaste[monthYear].MAG += doc.MAG;
          mapOfWaste[monthYear].KOH += doc.KOH;
          mapOfWaste[monthYear].WasteH2O += doc.WasteH2O;
          mapOfWaste[monthYear].Filtercake += doc.Filtercake;
          mapOfWaste[monthYear].Discharge += doc.Discharge;
          mapOfWaste[monthYear].MeterStart = mapOfWaste[monthYear].MeterStart < doc.MeterStart ? mapOfWaste[monthYear].MeterStart : doc.MeterStart;
          mapOfWaste[monthYear].MeterEnd = mapOfWaste[monthYear].MeterEnd > doc.MeterEnd ? mapOfWaste[monthYear].MeterEnd : doc.MeterEnd;
        }
        else {
          mapOfWaste[monthYear] = {
            docs: [doc],
            MAG: doc.MAG,
            KOH: doc.KOH,
            Acid: doc.Acid,
            WasteH2O: doc.WasteH2O,
            Filtercake: doc.Filtercake,
            Discharge: doc.Discharge,
            MeterStart: doc.MeterStart,
            MeterEnd: doc.MeterEnd,
            Date: moment(monthYear, 'MM/YYYY').toDate(),
          };
        }
      });
      return Object.values(mapOfWaste);
    }
    else {
      return [];
    }
  }, [wastewaterDocs]);

  const dailyLogTableProps = {
    data: monthlyDocs,
    columns: [
      {accessor: 'Date', type: 'timestamp'},
      {accessor: 'MeterStart', Header: 'Start', type: 'numeric'},
      {accessor: 'MeterEnd', Header: 'End', type: 'numeric'},
      {accessor: 'Discharge', type: 'numeric'},
      {accessor: 'Rain', type: 'boolean'},
      {accessor: 'pH', type: 'numeric'},
      {accessor: 'MAG', type: 'numeric'},
      {accessor: 'Filtercake', type: 'numeric'},
      {accessor: 'Acid', type: 'numeric'},
      {accessor: 'WasteH2O', Header: 'Waste H2O', type: 'numeric'},
      {accessor: 'KOH', type: 'numeric'},
    ],
    actionsBar: [
      {icon: ChevronLeft, text: 'Back', onClick: () => setMonthlyDocs([])},
      {icon: ImportExport, text: 'Export Table', onClick: () => {
        const rowsWithTotals = [...monthlyDocs, {
          Discharge: monthlyDocs.reduce((a, b) => a + b.Discharge, 0),
          MAG: monthlyDocs.reduce((a, b) => a + b.MAG, 0),
          Filtercake: monthlyDocs.reduce((a, b) => a + b.Filtercake, 0),
          Acid: monthlyDocs.reduce((a, b) => a + b.Acid, 0),
          WasteH2O: monthlyDocs.reduce((a, b) => a + b.WasteH2O, 0),
          KOH: monthlyDocs.reduce((a, b) => a + b.KOH, 0),
        }];
        exportToCSV(rowsWithTotals,
          [
            {accessor: 'Date', Header: 'Date', type: 'timestamp'},
            {accessor: 'MeterStart', Header: 'Start', type: 'numeric'},
            {accessor: 'MeterEnd', Header: 'End', type: 'numeric'},
            {accessor: 'Discharge', Header: 'Discharge', type: 'numeric'},
            {accessor: 'Rain', Header: 'Rain', type: 'boolean'},
            {accessor: 'pH', Header: 'pH', type: 'numeric'},
            {accessor: 'MAG', Header: 'MAG', type: 'numeric'},
            {accessor: 'Filtercake', Header: 'Filtercake', type: 'numeric'},
            {accessor: 'Acid', Header: 'Acid', type: 'numeric'},
            {accessor: 'WasteH2O', Header: 'Waste H2O', type: 'numeric'},
            {accessor: 'KOH', Header: 'KOH', type: 'numeric'},
          ],
          `${moment(rowsWithTotals[0].Date.toDate()).format('MM/YYYY')}_monthly_wastewater_log.CSV`,
        );
      }},
    ],
    actionsPerRow: currentUser.roleEHS === 'User' || currentUser.roleEHS === 'CAPA User' ? [] :
      [{icon: Edit, tooltip: 'Update Log', onClick: ({rowData}) => setModal({type: 'editDailyLog', row: rowData})}],
    isLoading: !wastewaterDocs?.list ? true : false,
    paginationSizes: [5, 15, 30, 50],
    sortInitial: [{id: 'Date', desc: true}],
    title: {primary: 'Daily Wastewater Log'},
  };
  const monthlyLogTableProps = {
    data: wastewaterByMonth || [],
    columns: [
      {accessor: 'Date', type: 'datetime', datetimeFormat: 'MM/YYYY'},
      {accessor: 'MeterStart', Header: 'Start', type: 'numeric'},
      {accessor: 'MeterEnd', Header: 'End', type: 'numeric'},
      {accessor: 'Discharge', type: 'numeric'},
      {accessor: 'MAG', type: 'numeric'},
      {accessor: 'Filtercake', type: 'numeric'},
      {accessor: 'Acid', type: 'numeric'},
      {accessor: 'WasteH2O', Header: 'Waste H2O', type: 'numeric'},
      {accessor: 'KOH', type: 'numeric'},
    ],
    actionsBar: [
      {icon: Add, text: 'Add Daily Log', onClick: () => setModal({type: 'addDailyLog'}), buttonProps: {disabled: currentUser.roleEHS === 'CAPA User'}},
      {icon: ImportExport, text: 'Export Table', onClick: () => {
        const rowsWithTotals = [...wastewaterByMonth, {
          Discharge: wastewaterByMonth.reduce((a, b) => a + b.Discharge, 0),
          MAG: wastewaterByMonth.reduce((a, b) => a + b.MAG, 0),
          Filtercake: wastewaterByMonth.reduce((a, b) => a + b.Filtercake, 0),
          Acid: wastewaterByMonth.reduce((a, b) => a + b.Acid, 0),
          WasteH2O: wastewaterByMonth.reduce((a, b) => a + b.WasteH2O, 0),
          KOH: wastewaterByMonth.reduce((a, b) => a + b.KOH, 0),
        }];
        exportToCSV(rowsWithTotals,
          [
            {accessor: 'Date', Header: 'Date', type: 'datetime', datetimeFormat: 'MM/YYYY'},
            {accessor: 'MeterStart', Header: 'Start', type: 'numeric'},
            {accessor: 'MeterEnd', Header: 'End', type: 'numeric'},
            {accessor: 'Discharge', Header: 'Discharge', type: 'numeric'},
            {accessor: 'MAG', Header: 'MAG', type: 'numeric'},
            {accessor: 'Filtercake', Header: 'Filtercake', type: 'numeric'},
            {accessor: 'Acid', Header: 'Acid', type: 'numeric'},
            {accessor: 'WasteH2O', Header: 'Waste H2O', type: 'numeric'},
            {accessor: 'KOH', Header: 'KOH', type: 'numeric'},
          ],
          `${moment(rowsWithTotals[0].Date).format('MM/YYYY')}_wastewater_log.CSV`,
        );
      }},
    ],
    actionsPerRow: [{icon: Search, tooltip: 'View Month', onClick: ({rowData}) => setMonthlyDocs(rowData.docs)}],
    isLoading: !wastewaterByMonth ? true : false,
    paginationSizes: [5, 15, 30, 50],
    sortInitial: [{id: 'Date', desc: true}],
    title: {primary: 'Monthly Wastewater Log'},
  };
  const documentTableProps = {
    data: wastewaterFiles?.list || [],
    columns: [
      {accessor: 'DateUploaded', Header: 'Date Uploaded', type: 'timestamp'},
      {accessor: 'FileName', Header: 'File Name'},
      {accessor: 'Owner.Name', Header: 'Uploaded By'},
    ],
    actionsBar: [{icon: Add, text: 'Add Document', onClick: () => setModal({type: 'addFile'})}],
    actionsPerRow: [{
      icon: CloudDownload,
      tooltip: 'Open Document',
      onClick: ({rowData}) =>
        Storage.ref(`Wastewater_Files/${rowData.wastewaterFileId}.${rowData.FileExt}`).getDownloadURL().then(url => window.open(url, '__blank')),
    }],
    isLoading: !wastewaterFiles?.list ? true : false,
    paginationSizes: [5, 15, 30, 50],
    sortInitial: [{id: 'DateUploaded', desc: true}],
    title: {primary: 'Wastewater Documents'},
  };
  const addFileModalProps = {
    close: () => setModal(null),
    addFileModalOpen: modal?.type === 'addFile',
    wastewater: true,
  };
  const dailyLogModalProps = useMemo(() => ({
    close: () => setModal(null),
    row: modal?.row || null,
    type: modal?.type || null,
  }), [modal]);
  const barChartData = useMemo(() => {
    if (wastewaterDocs?.list) {
      const aggregatedDocs = wastewaterDocs.list.reduce((acc, doc) => {
        const docYear = moment(doc.Date.toMillis()).year();
        acc[docYear] = {Discharge: !acc[docYear] ? doc.Discharge : doc.Discharge + acc[docYear].Discharge};
        return acc;
      }, {});
      return Object.keys(aggregatedDocs).map(key => {
        return {Year: key, Discharge: aggregatedDocs[key].Discharge};
      });
    }
    return [];
  }, [wastewaterDocs]);

  if (!wastewaterDocs?.list) return <LinearProgress />;
  return (
    <Fragment>
      <Paper elevation={3}>
        <Typography variant='h6' style={{padding: '16px'}}>Annual Discharge Summary</Typography>
        <ResponsiveContainer width='100%' height={300}>
          <BarChart data={barChartData} margin={{top: 24, right: 16, left: 16, bottom: 8}}>
            <XAxis dataKey='Year' />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey='Discharge' fill='#ed1c24' />
          </BarChart>
        </ResponsiveContainer>
      </Paper>
      <div style={{height: '24px'}} />
      <Paper elevation={3}>
        {monthlyDocs.length ? <Table {...dailyLogTableProps} /> : null}
        {!monthlyDocs.length ? <Table {...monthlyLogTableProps} /> : null}
      </Paper>
      <div style={{height: '24px'}} />
      <Paper elevation={3}>
        <Table {...documentTableProps} />
      </Paper>
      <DailyLogModal {...dailyLogModalProps} />
      {modal?.type === 'addFile' && <AddFileModal {...addFileModalProps} />}
    </Fragment>
  );
};

export default Wastewater;
