import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acEndWastewaterListener, acEndWastewaterFileListener, acEndApcLogListener, acEndStormwaterListener} from 'state/firestore/actions.js';

// Look for Firestore listeners and detach on module's parent component (i.e. module_ehs/index.js) unmount.
const useDetachModuleListeners = () => {
  const dispatch = useDispatch();

  // Detach EHS-Wastewater listener and forget wastewater redux state on module unmount.
  const detachWastewater = useSelector(state => state.firestore.wastewater?.subscription);
  const detachWastewaterFiles = useSelector(state => state.firestore.wastewaterFiles?.subscription);
  const detachApcLogs = useSelector(state => state.firestore.apcLogs?.subscription);
  const detachStormwater = useSelector(state => state.firestore.stormwater?.subscription);

  useEffect(() => {
    return () => {
      if (detachApcLogs) {
        detachApcLogs();
        dispatch(acEndApcLogListener);
      }
    };
  }, [detachApcLogs, dispatch]);
  useEffect(() => {
    return () => {
      if (detachWastewater) {
        detachWastewater();
        dispatch(acEndWastewaterListener);
      }
    };
  }, [detachWastewater, dispatch]);
  useEffect(() => {
    return () => {
      if (detachWastewaterFiles) {
        detachWastewaterFiles();
        dispatch(acEndWastewaterFileListener);
      }
    };
  }, [detachWastewaterFiles, dispatch]);
  useEffect(() => {
    return () => {
      if (detachStormwater) {
        detachStormwater();
        dispatch(acEndStormwaterListener);
      }
    };
  }, [detachStormwater, dispatch]);
};

export default useDetachModuleListeners;
