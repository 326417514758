import React, {useState} from 'react';
import {TableWithPaper} from 'components/';
import {LinearProgress, Button, Grid, Typography, Paper, makeStyles} from '@material-ui/core';
import {Add, Search, ImportExport, EventNote} from '@material-ui/icons';
import {useHistory} from 'react-router-dom';
import {NewCapaModal, NewReportModal} from './CorrectiveActions/';
import {useUsers, useCapas} from 'hooks/';
import {exportToCSV} from '@kbi/utility-library';
import moment from 'moment';
import {Collapse} from '@kbi/component-library';

const CorrectiveActions = () => {
  const styles = useStyles();
  const [newCapaModalOpen, setNewCapaModalOpen] = useState(false);
  const [capaReportModalOpen, setCapaReportModalOpen] = useState(false);
  const [listOfQueryResults, setListOfQueryResults] = useState(null);

  useUsers();
  const capas = useCapas();
  const history = useHistory();

  const capaTable = {
    data: capas?.list || [],
    columns: [
      {Header: 'ID', accessor: 'CapaNumber', type: 'numeric'},
      {Header: 'Start Date', accessor: 'StartDate', type: 'datetime', datetimeFormat: 'MM/DD/YYYY'},
      {Header: 'Deadline', accessor: 'Deadline', type: 'datetime', datetimeFormat: 'MM/DD/YYYY'},
      {Header: 'Status', accessor: 'Status', filterField: 'select'},
      {Header: 'Location', accessor: 'Location'},
      {Header: 'Action', accessor: 'Action', filterField: 'select'},
      {Header: 'Originators', accessor: 'OriginatorsString'},
      {Header: 'Assignees', accessor: 'AssigneesString'},
      {Header: 'Priority', accessor: 'Priority', filterField: 'select'},
      {Header: 'RCA Completed?', accessor: 'RCACompleted', type: 'boolean', filterField: 'boolean'},
    ],
    sortInitial: [{id: 'StartDate', desc: true}],
    paginationInitialSize: 10,
    actionsBar: [
      {
        icon: Add,
        text: 'Add New CAPA',
        onClick: () => setNewCapaModalOpen(true),
      },
    ],
    actionsPerRow: [{
      icon: Search,
      tooltip: 'View CAPA',
      onClick: ({rowData}) => history.push(`/corrective-actions/${rowData.CapaId}`),
    }],
    title: 'Active CAPA\'s',
    paperProps: {
      style: {
        margin: '0px',
      },
    },
  };

  const capaReportTable = {
    data: listOfQueryResults || [],
    columns: [
      {Header: 'ID', accessor: 'CapaNumber', type: 'numeric'},
      {Header: 'Start Date', accessor: 'StartDate', type: 'datetime', datetimeFormat: 'MM/DD/YYYY'},
      {Header: 'Deadline', accessor: 'Deadline', type: 'datetime', datetimeFormat: 'MM/DD/YYYY'},
      {Header: 'Status', accessor: 'Status'},
      {Header: 'Location', accessor: 'Location'},
      {Header: 'Action', accessor: 'Action'},
      {Header: 'Originators', accessor: 'OriginatorsString'},
      {Header: 'Assignees', accessor: 'AssigneesString'},
      {Header: 'Priority', accessor: 'Priority'},
      {Header: 'Completed Before Deadline', accessor: 'CompletedBeforeDeadline', type: 'boolean'},
    ],
    sortInitial: [{id: 'StartDate', desc: true}],
    paginationInitialSize: 10,
    actionsPerRow: [{
      icon: Search,
      tooltip: 'View CAPA',
      onClick: ({rowData}) => history.push(`/corrective-actions/${rowData.CapaId}`),
    }],
    actionsBar: [
      {
        icon: EventNote,
        text: 'New CAPA Report',
        onClick: () => setCapaReportModalOpen(true),
      },
      {
        icon: ImportExport,
        text: 'Export To Excel',
        onClick: () => {
          exportToCSV(listOfQueryResults, [
            {Header: 'ID', accessor: 'CapaNumber', type: 'numeric'},
            {Header: 'Start Date', accessor: 'StartDate', type: 'datetime', datetimeFormat: 'MM/DD/YYYY'},
            {Header: 'Deadline', accessor: 'Deadline', type: 'datetime', datetimeFormat: 'MM/DD/YYYY'},
            {Header: 'Status', accessor: 'Status'},
            {Header: 'Location', accessor: 'Location'},
            {Header: 'Action', accessor: 'Action'},
            {Header: 'Originators', accessor: 'OriginatorsString'},
            {Header: 'Assignees', accessor: 'AssigneesString'},
            {Header: 'Priority', accessor: 'Priority'},
            {Header: 'Completed Before Deadline', accessor: 'CompletedBeforeDeadline', type: 'boolean'},
          ],
          `${moment().format('MM/DD/YYYY')}_CAPA_report.csv`,
          );
        },
      }],
    title: 'CAPA Report Results',
    paperProps: {
      style: {
        margin: '0px',
      },
    },
  };

  if (!capas || !capas.list) return <LinearProgress />;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TableWithPaper {...capaTable} />
      </Grid>

      <Grid item xs={12}>

        <Collapse in={!listOfQueryResults?.length} >
          <Paper className={styles.paper}>
            <Typography variant='h4' gutterBottom>
            CAPA Reporting
            </Typography>
            <EventNote style={{fontSize: '10em'}} />
            <Button variant='outlined' onClick={() => setCapaReportModalOpen(true)}>Create Report</Button>
          </Paper>
        </Collapse>

        <Collapse in={Boolean(listOfQueryResults?.length)} >
          <TableWithPaper {...capaReportTable} />
        </Collapse>

      </Grid>

      {capaReportModalOpen &&
        <NewReportModal close={() => setCapaReportModalOpen(false)} setListOfQueryResults={array => setListOfQueryResults(array)} listOfQueryResults={listOfQueryResults} />
      }

      {newCapaModalOpen && <NewCapaModal close={() => setNewCapaModalOpen(false)} />}
    </Grid>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%',
    display: 'flex',
    overflowX: 'auto',
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default CorrectiveActions;
