import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acStartWastewaterFilesListener} from 'state/firestore/actions.js';

const useWastewaterFiles = () => {
  const dispatch = useDispatch();
  const wastewaterFiles = useSelector(state => state.firestore.wastewaterFiles);

  useEffect(() => {
    if (!wastewaterFiles) dispatch(acStartWastewaterFilesListener);
  }, [dispatch, wastewaterFiles]);

  return wastewaterFiles;
};

export default useWastewaterFiles;
