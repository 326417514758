import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {SubmitConfirmation} from 'components/';
import {Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Grid, Button} from '@material-ui/core';
import {recommendedActions} from './Consts/';
import {Clear} from '@material-ui/icons';
import {Alert, Collapse, Formik} from '@kbi/component-library';
import * as yup from 'yup';
import {useSelector} from 'react-redux';
import {FieldArray} from 'formik';
import {useAxios} from 'hooks';
const {FormikForm, SubmitButton, FormButton, TextField, AutoCompleteValue} = Formik;

const RecommendedActionsModal = ({close, selectedRootCause}) => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');

  const currentUser = useSelector(state => state.auth.currentUser);
  const {appEngine, axios, getAuthToken} = useAxios();

  const formikProps = {
    initialValues: {
      recommendedActions: selectedRootCause.RecommendedActions.length ?
        selectedRootCause.RecommendedActions.map(recommendedAction => ({action: recommendedAction.Action, comment: recommendedAction.Comment})) :
        [
          {
            action: '',
            comment: '',
          },
        ],
    },
    validationSchema: yup.object().shape({
      recommendedActions: yup.array().of(yup.object().shape({
        action: yup.string().required('Action is a required field.'),
        comment: yup.string().required('Comment is a required field.'),
      })).min(1, 'At least one action must be submitted.'),
    }),
    onSubmit: async (values, actions) => {
      try {
        const dataForFirestore = createDataForFirestore(values);

        await axios({
          method: 'post',
          url: `${appEngine}/ehs/firestore/UpdateRootCauseAnalysis`,
          withCredentials: true,
          headers: {
            'Authorization': await getAuthToken(),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          data: {
            formData: dataForFirestore,
            currentUser,
            docId: selectedRootCause.RootCauseId,
          },
        });

        setFormError('');
        setStage(stage + 1);
      }
      catch (err) {
        setFormError('There was an error during submission. Please try again.');
      }
    },
  };

  const actionField = {
    options: recommendedActions,
    optionKey: 'value',
  };

  return (
    <Dialog open={true} maxWidth='md' fullWidth scroll='body' transitionDuration={{exit: 0}}>
      <FormikForm {...formikProps}>
        {({values}) => (
          <Fragment>
            {stage !== 1 && <DialogTitle>Update Recommended Actions</DialogTitle>}
            <DialogContent>
              <Collapse in={stage === 0}>
                <DialogContentText>
                  Update the recommended actions of the root cause analysis:
                </DialogContentText>
                <Grid container spacing={2}>
                  <FieldArray name='recommendedActions'>
                    {arrayHelpers => (
                      <Fragment>
                        {values.recommendedActions.map((field, index) => (
                          <Grid key={index} item xs={12} container spacing={2} style={{margin: '8px', border: '1px solid lightgrey'}}>
                            <Grid item xs={5}>
                              <AutoCompleteValue {...actionField} name={`recommendedActions[${index}].action`} label='Action' required />
                            </Grid>
                            <Grid item xs={5}>
                              <TextField name={`recommendedActions[${index}].comment`} label='Comment' required multiline />
                            </Grid>
                            <Grid item xs={2} container alignContent='center' justify='center'>
                              <Button color='secondary' onClick={() => arrayHelpers.remove(index)} disabled={values.recommendedActions.length === 1}>
                                <Clear />
                              </Button>
                            </Grid>
                          </Grid>
                        ))}
                        <Grid item xs={3}>
                          <Button color='primary' onClick={() => arrayHelpers.push({action: '', comment: ''})}>
                            Add Action
                          </Button>
                        </Grid>
                      </Fragment>
                    )}
                  </FieldArray>
                </Grid>
              </Collapse>
              <SubmitConfirmation stage={stage === 1 ? 'success' : ''} text='Recommended Actions successfully updated.' />
              <Alert in={Boolean(formError)} text={formError} severity='error' />
            </DialogContent>
            {stage === 0 ? (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton variant='text' color='secondary' onClick={close}>Cancel</FormButton>
                <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
              </DialogActions>
            ) : (
              <DialogActions style={{justify: 'space-between'}}>
                <FormButton variant='text' color='primary' onClick={close}>Close</FormButton>
              </DialogActions>
            )}
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

RecommendedActionsModal.propTypes = {
  close: PropTypes.func.isRequired,
  selectedRootCause: PropTypes.object,
};

export default RecommendedActionsModal;

function createDataForFirestore(values) {
  return {
    RecommendedActions: values.recommendedActions.map(fields => ({
      Action: fields.action,
      Comment: fields.comment,
    })),
  };
}
