import React from 'react';
import {makeStyles, Grid, Typography} from '@material-ui/core';
import {BrokenImage} from '@material-ui/icons';
import PropTypes from 'prop-types';

const ImgThumbnail = ({name, src, allowOpen, notImg}) => {
  const styles = useStyles();
  const openInNewWindow = () => window.open(src, '__blank');
  const props = {
    className: allowOpen ? styles.imgWithLink : styles.img,
    src,
    style: notImg ? {} : {
      backgroundImage: `url(${src})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
    },
    onClick: allowOpen ? openInNewWindow : null,
  };
  if (!notImg) return <div {...props} />;
  else {
    return (
      <Grid container justify='center' alignItems={'center'} direction='column' {...props}>
        <Typography variant='h5'>{name}</Typography>
        <BrokenImage className={styles.icon} />
        <Typography variant='h5'>Not Visible in Print View</Typography>
      </Grid>
    );
  }
};

const useStyles = makeStyles(theme => ({
  imgWithLink: {
    width: '100%',
    height: '250px',
    cursor: 'pointer',
    border: '1px solid lightgrey',
    backgroundColor: 'ghostwhite',
  },
  img: {
    width: '100%',
    height: '250px',
    border: '1px solid lightgrey',
    backgroundColor: 'ghostwhite',
  },
  icon: {
    fontSize: '10em',
  },
}));

ImgThumbnail.defaultProps = {
  allowOpen: true,
};
ImgThumbnail.propTypes = {
  src: PropTypes.string,
  notImg: PropTypes.bool,
  allowOpen: PropTypes.bool,
  name: PropTypes.string,
};

export default ImgThumbnail;
