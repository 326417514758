import React, {Fragment, useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment} from '@material-ui/core';
import {Button, Grid, MenuItem, Typography} from '@material-ui/core';
import {Formik, Alert} from '@kbi/component-library';
import {CheckCircleOutline} from '@material-ui/icons';
import moment from 'moment';
import {boolean, date, number, object, ref, string} from 'yup';
import {useAxios} from 'hooks/';
const {DateField, FormButton, FormikForm, NumberField, SelectField, SubmitButton, TextField} = Formik;

const DailyLogModal = ({close, row, type}) => {
  const [stage, setStage] = useState('enterDailyLog');
  const wastewaterDocs = useSelector(state => state.firestore.wastewater);
  const {axios, getAuthToken, appEngine} = useAxios();

  const lastMeterRead = useMemo(() => {
    const lastDoc = wastewaterDocs?.list ? wastewaterDocs.list[wastewaterDocs.list.length - 1] : '';
    return lastDoc.MeterEnd;
  }, [wastewaterDocs]);

  const isNewLogDate = useCallback((logDate) => {
    let isNew = true;
    const formLogMoment = moment(logDate);
    for (let i = 0; wastewaterDocs.list && i < wastewaterDocs.list.length; i++) {
      const itemLogMoment = moment(wastewaterDocs.list[i].Date.toMillis());
      if (formLogMoment.isSame(itemLogMoment, 'day')) {
        isNew = false;
        break;
      }
    }
    return isNew;
  }, [wastewaterDocs]);
  const handleClose = useCallback(() => {
    setStage('enterDailyLog');
    close();
  }, [close]);
  const dialogProps = useMemo(() => ({
    fullWidth: true,
    maxWidth: 'sm',
    open: type === 'addDailyLog' || type === 'editDailyLog',
    scroll: 'body',
    transitionDuration: {exit: 0},
  }), [type]);
  const formProps = useMemo(() => ({
    initialStatus: {alert: ''},
    initialValues: {
      Date: row?.Date ? moment(row.Date.toMillis()).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
      Discharge: row?.Discharge || row?.Discharge === 0 ? row.Discharge : '',
      MeterStart: row?.MeterStart || lastMeterRead,
      MeterEnd: row?.MeterEnd || '',
      Rain: row?.Rain || row?.Rain === false ? row.Rain : '',
      pH: row?.pH || row?.pH === 0 ? row.pH : '',
      MAG: row?.MAG || row?.MAG === 0 ? row.MAG : '',
      Filtercake: row?.Filtercake || row?.Filtercake === 0 ? row.Filtercake : '',
      Acid: row?.Acid || row?.Acid === 0 ? row.Acid : '',
      WasteH2O: row?.WasteH2O || row?.WasteH2O === 0 ? row.WasteH2O : '',
      KOH: row?.KOH || row?.KOH === 0 ? row.KOH : '',
      Comments: row?.Comments || '',
    },
    onSubmit: async (values, actions) => {
      const formItem = {
        Date: moment(values.Date).toDate(),
        Discharge: Number(values.Discharge),
        MeterStart: Number(values.MeterStart),
        MeterEnd: Number(values.MeterEnd),
        Rain: values.Rain,
        pH: Number(values.pH),
        MAG: Number(values.MAG),
        Filtercake: Number(values.Filtercake),
        Acid: Number(values.Acid),
        WasteH2O: Number(values.WasteH2O),
        KOH: Number(values.KOH),
        Comments: values.Comments.trim(),
      };
      const addFormItem = async () => {
        try {
          await axios({
            method: 'post',
            url: `${appEngine}/ehs/firestore/NewWastewaterItem`,
            withCredentials: true,
            headers: {
              'Authorization': await getAuthToken(),
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            data: {
              formData: formItem,
            },
          });
          setStage('success');
          actions.setStatus({alert: ''});
        }
        catch (error) {
          actions.setStatus({alert: error.message});
        }
      };
      const editFormItem = async () => {
        try {
          await axios({
            method: 'post',
            url: `${appEngine}/ehs/firestore/EditWastewaterItem`,
            withCredentials: true,
            headers: {
              'Authorization': await getAuthToken(),
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            data: {
              formId: row.wastewaterDocId,
              formData: formItem,
            },
          });
          setStage('success');
          actions.setStatus({alert: ''});
        }
        catch (error) {
          actions.setStatus({alert: error.message});
        }
      };
      if (type ==='addDailyLog') await addFormItem();
      if (type === 'editDailyLog') await editFormItem();
    },
    validationSchema: object().shape({
      Date: date().label('Log Date').required().test(
        'is-duplicate-log',
        'A record already exists for this Log Date',
        value => {
          if (type === 'addDailyLog') return isNewLogDate(value);
          if (type === 'editDailyLog') return true;
        }),
      Discharge: number().required().positive(),
      MeterStart: (() => {
        if (type === 'addDailyLog') return number().label('Meter Start').required().min(lastMeterRead, ({min}) => `Must be greater than or equal to ${min}`);
        if (type === 'editDailyLog') return number().label('Meter Start').required();
      })(),
      MeterEnd: number().label('Meter End').required().min(ref('MeterStart'), ({min}) => `Must be greater than or equal to ${min}`),
      Rain: boolean().required('Required'),
      pH: number().required('Required').min(0, ({min}) => `Must be greater than or equal to ${min}`).max(14, ({max}) => `Must be less than or equal to ${max}`),
      MAG: number().required('Required').min(0, ({min}) => `Must be greater than or equal to ${min}`),
      Filtercake: number().required('Required').min(0, ({min}) => `Must be greater than or equal to ${min}`),
      Acid: number().required().min(0, ({min}) => `Must be greater than or equal to ${min}`),
      WasteH2O: number().label('Waste H2O').required().min(0, ({min}) => `Must be greater than or equal to ${min}`),
      KOH: number().required().min(0, ({min}) => `Must be greater than or equal to ${min}`),
      Comments: (() => {
        if (type === 'addDailyLog') {
          return string().when('MeterStart', {
            is: val => val === lastMeterRead,
            then: string().notRequired(),
            otherwise: string().required('Meter Start does not match Meter End reading from last log date. Readings should always be continous. Add comment explaining reason for discrepancy.'), // eslint-disable-line max-len
          });
        }
        if (type === 'editDailyLog') return string().notRequired();
      })(),
    }),
  }), [isNewLogDate, lastMeterRead, row, type, axios, appEngine, getAuthToken]);

  return (
    <Dialog {...dialogProps}>
      <FormikForm {...formProps}>
        {(formikProps) => (
          <Fragment>
            <DialogTitle>
              <Collapse in={stage !== 'success'} timeout={750}>
                {type === 'addDailyLog' ? 'Create New' : 'Update'} Daily Log
              </Collapse>
            </DialogTitle>
            <DialogContent>
              <EnterDailyLogStage stage={stage} type={type} />
              <SuccessStage stage={stage} type={type} />
              <Alert in={Boolean(formikProps.status.alert)} text={formikProps.status.alert} severity='error' />
            </DialogContent>
            <Collapse in={stage === 'success'} timeout={750}>
              <DialogActions>
                <Button onClick={handleClose} color='primary'>
                  Close
                </Button>
              </DialogActions>
            </Collapse>
            <Collapse in={stage !== 'success'} timeout={750}>
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton onClick={handleClose} color='secondary' variant='text'>
                  Cancel
                </FormButton>
                <SubmitButton color='primary' variant='text'>
                  Submit
                </SubmitButton>
              </DialogActions>
            </Collapse>
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

DailyLogModal.propTypes = {
  row: PropTypes.object,
  type: PropTypes.string,
  close: PropTypes.func.isRequired,
};
export default DailyLogModal;

const EnterDailyLogStage = React.memo(({stage, type}) => {
  const meterStartFieldProps = useMemo(() => ({
    name: 'MeterStart',
    label: 'Meter Start',
    required: true,
    onBlur: ({field, form, meta}) => {
      if (form.values.MeterEnd && meta.value) form.setFieldValue('Discharge', form.values.MeterEnd - meta.value);
      else form.setFieldValue('Discharge', '');
    },
  }), []);
  const meterEndFieldProps = useMemo(() => ({
    name: 'MeterEnd',
    label: 'Meter End',
    required: true,
    onBlur: ({field, form, meta}) => {
      if (form.values.MeterStart && meta.value) form.setFieldValue('Discharge', meta.value - form.values.MeterStart);
      else form.setFieldValue('Discharge', '');
    },
  }), []);

  return (
    <Collapse in={stage === 'enterDailyLog'} timeout={750}>
      <DialogContentText>Enter wastewater daily log details below:</DialogContentText>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DateField name='Date' label='Log Date' required disabled={type === 'editDailyLog'} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberField name='Discharge' disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberField {...meterStartFieldProps} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberField {...meterEndFieldProps} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <SelectField name='Rain' required>
            <MenuItem value={true}>True</MenuItem>
            <MenuItem value={false}>False</MenuItem>
          </SelectField>
        </Grid>
        <Grid item xs={12} sm={3}>
          <NumberField name='pH' required />
        </Grid>
        <Grid item xs={12} sm={3}>
          <NumberField name='MAG' required InputProps={{endAdornment: <InputAdornment position='end'>gal</InputAdornment>}} />
        </Grid>
        <Grid item xs={12} sm={3}>
          <NumberField name='Filtercake' required InputProps={{endAdornment: <InputAdornment position='end'>lbs</InputAdornment>}} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <NumberField name='Acid' required InputProps={{endAdornment: <InputAdornment position='end'>gal</InputAdornment>}} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <NumberField name='WasteH2O' label='Waste H2O' required InputProps={{endAdornment: <InputAdornment position='end'>gal</InputAdornment>}} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <NumberField name='KOH' required InputProps={{endAdornment: <InputAdornment position='end'>gal</InputAdornment>}} />
        </Grid>
        <Grid item xs={12}>
          <TextField name='Comments' />
        </Grid>
      </Grid>
    </Collapse>
  );
});
EnterDailyLogStage.propTypes = {stage: PropTypes.string.isRequired, type: PropTypes.string};

const SuccessStage = React.memo(({stage, type}) => {
  return (
    <Collapse in={stage === 'success'} timeout={750}>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <CheckCircleOutline style={{fontSize: '10em'}} />
        <Typography variant='h6' style={{textAlign: 'center'}} gutterBottom>
          Wastewater Daily Log Succesfully {type === 'addDailyLog' ? 'Added' : 'Updated'}
        </Typography>
      </div>
    </Collapse>
  );
});
SuccessStage.propTypes = {stage: PropTypes.string.isRequired, type: PropTypes.string};
