import React, {useState} from 'react';
import {TableWithPaper} from 'components/';
import {LinearProgress, Grid} from '@material-ui/core';
import {Add, Search} from '@material-ui/icons';
import {useHistory} from 'react-router-dom';
import {useInspections} from 'hooks/';
import {NewInspectionModal, DailyInspectionPanel, PeriodicInspectionPanel} from './Inspections/';

const Inspections = () => {
  const [newInspectionModalOpen, setNewInspectionModalOpen] = useState(false);

  const history = useHistory();
  const inspections = useInspections();

  const inspectionsTable = {
    data: inspections?.list || [],
    columns: [
      {Header: 'Form', accessor: 'FormTitle'},
      {Header: 'Date', accessor: 'FormData.Date', type: 'datetime', datetimeFormat: 'MM/DD/YYYY'},
      {Header: 'Created By', accessor: 'System.CreatedBy'},
      {Header: 'Completed By', accessor: 'System.CompletedBy'},
    ],
    sortInitial: [{id: 'FormData.Date', desc: true}],
    paginationInitialSize: 10,
    actionsBar: [
      {
        icon: Add,
        text: 'Add New Inspection',
        onClick: () => setNewInspectionModalOpen(true),
      },
    ],
    actionsPerRow: [{
      icon: Search,
      tooltip: 'View Inspection',
      onClick: ({rowData}) => history.push(`/inspections/${rowData.InspectionId}`),
    }],
    title: 'All Inspections',
    paperProps: {
      style: {
        margin: '0px',
      },
    },
  };

  if (!inspections || !inspections.list) return <LinearProgress />;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <DailyInspectionPanel />
      </Grid>
      <Grid item xs={12}>
        <PeriodicInspectionPanel />
      </Grid>
      <Grid item xs={12}>
        <TableWithPaper {...inspectionsTable} />
      </Grid>

      {newInspectionModalOpen && <NewInspectionModal close={() => setNewInspectionModalOpen(false)} />}
    </Grid>
  );
};

export default Inspections;
