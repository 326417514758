import {createStore, combineReducers, applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk';

// Import EHS Reducers
import AuthReducers from './auth/reducers';
import FirestoreReducers from './firestore/reducers';
import UiReducers from './ui/reducers';

export const store = createStore(
  combineReducers({
    auth: AuthReducers,
    firestore: FirestoreReducers,
    ui: UiReducers,
  }),
  applyMiddleware(ReduxThunk),
);
