import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/storage';

const prodConfig = {
  apiKey: 'AIzaSyDYqcc4pGWH0SYSQtYquHO7Ytujgto3Eg8',
  authDomain: 'batteries-fb.firebaseapp.com',
  databaseURL: 'https://batteries-fb.firebaseio.com',
  projectId: 'batteries-fb',
  storageBucket: 'batteries-fb.appspot.com',
  messagingSenderId: '550772834910',
};
const devConfig = {
  apiKey: 'AIzaSyAEiimSBP259_UbvLaZcyiPzdtXMeOlFXA',
  authDomain: 'batteries-fb-dev.firebaseapp.com',
  databaseURL: 'https://batteries-fb-dev.firebaseio.com',
  projectId: 'batteries-fb-dev',
  storageBucket: 'batteries-fb-dev.appspot.com',
  messagingSenderId: '967804234433',
  appId: '1:967804234433:web:359b46f0f14713cbd54240',
  measurementId: 'G-K1NLT3DV0J',
};

const firebaseCatalyst = firebase.initializeApp(
  {
    apiKey: 'AIzaSyDeeqipVi1_X-8KX2_Y5f7SR56XWzByB5c',
    authDomain: 'catalyst-fire.firebaseapp.com',
    databaseURL: 'https://catalyst-fire.firebaseio.com',
    projectId: 'catalyst-fire',
    storageBucket: 'catalyst-fire.appspot.com',
    messagingSenderId: '950879361607',
  },
  'catalyst',
);

const config = process.env.NODE_ENV === 'production' ? firebase.initializeApp(prodConfig) : firebase.initializeApp(devConfig);

export const Firestore = config.firestore();
export const Auth = config.auth();
export const Functions = config.functions();
export const Storage = config.storage();
export const appEngineUrl = process.env.NODE_ENV === 'production' ? 'https://server.kbi.works' : 'http://localhost:8080';

// Direct export of Firestore's FieldValue & Timestamp utilities;
// https://firebase.google.com/docs/reference/node/firebase.firestore.FieldValue
// https://firebase.google.com/docs/reference/js/firebase.firestore.Timestamp
export const FieldValue = firebase.firestore.FieldValue;
export const Timestamp = firebase.firestore.Timestamp;

export const FirestoreCatalyst = firebaseCatalyst.firestore();
