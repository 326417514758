import React, {useState, Fragment, useMemo} from 'react';
import PropTypes from 'prop-types';
import {SubmitConfirmation, AsyncLoader, determineIfUserIsManager} from 'components/';
import {Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Grid, MenuItem} from '@material-ui/core';
import {Alert, Collapse, Formik} from '@kbi/component-library';
import {useUsers, useAxios} from 'hooks/';
import * as yup from 'yup';
import moment from 'moment';
import {useSelector} from 'react-redux';
const {FormikForm, AutoCompleteObject, SelectField, SubmitButton, FormButton, DateField, TextField} = Formik;

// eslint-disable-next-line max-len
export const arrayOfLocations = ['Catalyst Buying Area', 'Catalyst Cutting Area', 'Catalyst Warehouse', 'Janitorial', 'Laboratory', 'Lead Acid', 'Loading Dock', 'Main Yard', 'Maintenance', 'Non-Lead Acid', 'Office (125)', 'Office (1314)', 'Offsite', 'Outside Perimeter', 'Parking Lot', 'Precious Metals (1314)', 'Shipping', 'Staging Area', 'Storage Unit', 'Tracking', 'Water Treatment', 'Other (Not Listed)'];

const NewCapaModal = ({close, selectedRootCause, selectedInspection}) => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');
  const [newCapa, setNewCapa] = useState(null);

  const {axios, getAuthToken, appEngine} = useAxios();

  const users = useUsers();
  const currentUser = useSelector(state => state.auth.currentUser);

  const originatorOptions = useMemo(() => {
    if (users) return users.filter(user => determineIfUserIsManager(user));
    else return [];
  }, [users]);

  if (!users || !currentUser) return <AsyncLoader />;

  const formikProps = {
    initialValues: {
      startDate: moment().format('YYYY-MM-DD'),
      deadline: '',
      priority: '',
      location: '',
      originators: [users.find(user => user.uid === currentUser.uid)],
      assignees: [],
      observation: '',
      action: '',
    },
    validationSchema: yup.object().shape({
      startDate: yup.date().required('Start Date is a required field.').max(new Date(), 'Start Date cannot be in the future.'),
      deadline: yup.date().required('Deadline is a required field.').test({
        name: 'after start date',
        test: function(value) {
          if (value && moment(value).isSameOrBefore(moment(this.parent.startDate))) {
            return false;
          }
          else return true;
        },
        message: 'Deadline must be after Start Date.',
      }),
      priority: yup.string().required('Priority is a required field.'),
      location: yup.string().required('Location is a required field.'),
      originators: yup.array().min(1, 'There must be at least one originator.'),
      assignees: yup.array().min(1, 'There must be at least one assignee.'),
      observation: yup.string().required('Observation is a required field.'),
      action: yup.string().required('Action is a required field.'),
    }),
    onSubmit: async (values, actions) => {
      const submitData = createDataForFirestore(values);
      try {
        const response = await axios({
          method: 'post',
          url: `${appEngine}/ehs/firestore/NewCapa`,
          withCredentials: true,
          headers: {
            'Authorization': await getAuthToken(),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          data: {
            formData: submitData,
          },
        });

        if (!response || !response.data.formId) throw new Error();

        setNewCapa(response.data);
        setStage(stage + 1);
        setFormError('');
      }
      catch (err) {
        setFormError('There was an error during submission. Please try again.');
      }
    },
  };
  const handleClose = () => {
    close(newCapa);
  };

  const createDataForFirestore = values => ({
    Active: true,
    StartDate: moment(values.startDate, 'YYYY-MM-DD').toDate(),
    Deadline: moment(values.deadline, 'YYYY-MM-DD').toDate(),
    Priority: values.priority,
    Location: values.location,
    Originators: values.originators.map(user => ({displayName: user.displayName, uid: user.uid, email: user.email, role: user.roleEHS})),
    Assignees: values.assignees.map(user => ({displayName: user.displayName, uid: user.uid, email: user.email, role: user.roleEHS})),
    AssigneeNames: values.assignees.map(user => user.displayName),
    OriginatorNames: values.originators.map(user => user.displayName),
    Observation: values.observation,
    Status: 'In Progress',
    Action: values.action,
    RootCauseAnalysisRef: selectedRootCause ? selectedRootCause.RootCauseId : null,
    OriginalDeadline: null,
    InspectionRefs: selectedInspection ? [selectedInspection.InspectionId] : null,
    System: {
      CreatedBy: currentUser.displayName,
      CreatedOn: new Date(),
    },
  });

  const originatorsField = {
    name: 'originators',
    label: 'Originators',
    options: originatorOptions,
    optionKey: 'displayName',
    multiple: true,
    fast: true,
    required: true,
  };

  const assigneesField = {
    name: 'assignees',
    label: 'Assignees',
    options: users,
    optionKey: 'displayName',
    multiple: true,
    fast: true,
    required: true,
  };

  const priorityField = {
    name: 'priority',
    label: 'Priority',
    required: true,
    onChange: ({event, form}) => {
      // some messed up stuff is happening with eslint and proptypes in this func. so I disabled it.
      /* eslint-disable */
      const value = event.target.value;
      switch (value) {
        case 'Critical': {
          return form.setFieldValue('deadline', moment().add(3, 'days').format('YYYY-MM-DD'));
        }
        case 'High': {
          return form.setFieldValue('deadline', moment().add(7, 'days').format('YYYY-MM-DD'));
        }
        case 'Medium': {
          return form.setFieldValue('deadline', moment().add(14, 'days').format('YYYY-MM-DD'));
        }
        case 'Low': {
          return form.setFieldValue('deadline', moment().add(21, 'days').format('YYYY-MM-DD'));
        }
        default: return null;
      }
      /* eslint-enable */
    },
  };


  return (
    <Dialog open={true} maxWidth='md' fullWidth scroll='body' transitionDuration={{exit: 0}}>
      <FormikForm {...formikProps}>
        {({values}) => (
          <Fragment>
            {stage !== 1 && <DialogTitle>Begin New CAPA</DialogTitle>}
            <DialogContent>
              <Collapse in={stage === 0}>
                <DialogContentText>Enter details for CAPA:</DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={3}>
                    <DateField name='startDate' label='Start Date' required />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <SelectField {...priorityField}>
                      <MenuItem value='Critical'>Critical</MenuItem>
                      <MenuItem value='High'>High</MenuItem>
                      <MenuItem value='Medium'>Medium</MenuItem>
                      <MenuItem value='Low'>Low</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <DateField name='deadline' label='Deadline' fast required />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <SelectField name='location' label='Location' fast required>
                      {arrayOfLocations.map(locationString => (<MenuItem key={locationString} value={locationString}>{locationString}</MenuItem>))}
                    </SelectField>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <SelectField name='action' label='Action' fast required>
                      <MenuItem value='Corrective'>Corrective</MenuItem>
                      <MenuItem value='Preventative'>Preventative</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <AutoCompleteObject {...originatorsField} />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <AutoCompleteObject {...assigneesField} />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField name='observation' label='Observation' multiline required />
                  </Grid>
                </Grid>
              </Collapse>
              <SubmitConfirmation stage={stage === 1 ? 'success' : ''} text='CAPA successfully created.' />
              <Alert in={Boolean(formError)} text={formError} severity='error' />
            </DialogContent>
            {stage === 0 ? (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton variant='text' color='secondary' onClick={handleClose}>Cancel</FormButton>
                <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
              </DialogActions>
            ) : (
              <DialogActions style={{justify: 'space-between'}}>
                <FormButton variant='text' color='primary' onClick={handleClose}>Close</FormButton>
              </DialogActions>
            )}
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

NewCapaModal.propTypes = {
  close: PropTypes.func.isRequired,
  selectedRootCause: PropTypes.shape({
    RootCauseId: PropTypes.string.isRequired,
  }),
  selectedInspection: PropTypes.shape({
    InspectionId: PropTypes.string.isRequired,
  }),
};

export default NewCapaModal;
