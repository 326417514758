import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {SubmitConfirmation} from 'components/';
import {Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Grid, Button, MenuItem} from '@material-ui/core';
import {Clear} from '@material-ui/icons';
import {rootCauses} from './Consts/';
import {Alert, Collapse, Formik} from '@kbi/component-library';
import * as yup from 'yup';
import {useSelector} from 'react-redux';
import {FieldArray} from 'formik';
import {useAxios} from 'hooks/';
const {FormikForm, SubmitButton, FormButton, SelectField, AutoCompleteValue, TextField} = Formik;

const RootCausesModal = ({close, selectedRootCause}) => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');

  const currentUser = useSelector(state => state.auth.currentUser);
  const {axios, getAuthToken, appEngine} = useAxios();

  const formikProps = {
    initialValues: {
      rootCauses: selectedRootCause.RootCauses.length ?
        selectedRootCause.RootCauses.map(cause => ({
          catagory: cause.Catagory,
          cause: cause.Cause,
          explanation: cause.Explanation,
        })) : [
          {
            catagory: '',
            cause: '',
            explanation: '',
          },
        ],
    },
    validationSchema: yup.object().shape({
      rootCauses: yup.array().of(yup.object().shape({
        catagory: yup.string().required('Catagory is a required field.'),
        cause: yup.string().required('Root Cause is a required field.'),
        explanation: yup.string().required('Explanation is a required field.'),
      })).min(1, 'There must be at least one Root Cause.'),
    }),
    onSubmit: async (values, actions) => {
      try {
        const dataForFirestore = createDataForFirestore(values);

        await axios({
          method: 'post',
          url: `${appEngine}/ehs/firestore/UpdateRootCauseAnalysis`,
          withCredentials: true,
          headers: {
            'Authorization': await getAuthToken(),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          data: {
            formData: dataForFirestore,
            currentUser,
            docId: selectedRootCause.RootCauseId,
          },
        });

        setFormError('');
        setStage(stage + 1);
      }
      catch (err) {
        setFormError('There was an error during submission. Please try again.');
      }
    },
  };

  const rootCauseField = (fieldArrayValues, index) => {
    return {
      name: `rootCauses[${index}].cause`,
      label: 'Root Cause',
      options: rootCauses[fieldArrayValues.catagory] || [],
      optionKey: 'value',
      disabled: !fieldArrayValues.catagory,
      required: true,
    };
  };

  return (
    <Dialog open={true} maxWidth='md' fullWidth scroll='body' transitionDuration={{exit: 0}}>
      <FormikForm {...formikProps}>
        {({values, setFieldValue}) => (
          <Fragment>
            {stage !== 1 && <DialogTitle>Update Root Causes</DialogTitle>}
            <DialogContent>
              <Collapse in={stage === 0}>
                <DialogContentText>
                  Enter all root causes:
                </DialogContentText>
                <Grid container spacing={2}>
                  <FieldArray name='rootCauses'>
                    {arrayHelpers => (
                      <Fragment>
                        {values.rootCauses.map((fields, index) => (
                          <Grid item xs={12} container spacing={2} style={{margin: '8px', border: '1px solid lightgrey'}} key={index}>
                            <Grid item xs={10} container spacing={2}>
                              <Grid item xs={6}>
                                <SelectField name={`rootCauses[${index}].catagory`} label='Catagory' required
                                  onChange={() => setFieldValue(`rootCauses[${index}].cause`, '')}
                                >
                                  {Object.keys(rootCauses).map((key, index) => {
                                    return <MenuItem key={key + index} value={key}>{key}</MenuItem>;
                                  })}
                                </SelectField>
                              </Grid>
                              <Grid item xs={6}>
                                <AutoCompleteValue {...rootCauseField(fields, index)} />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField name={`rootCauses[${index}].explanation`} label='Explanation' required multiline />
                              </Grid>
                            </Grid>
                            <Grid item xs={2} container alignContent='center' justify='center'>
                              <Button color='secondary' onClick={() => arrayHelpers.remove(index)} disabled={values.rootCauses.length === 1}>
                                <Clear />
                              </Button>
                            </Grid>
                          </Grid>
                        ))}
                        <Grid item xs={3}>
                          <Button color='primary' onClick={() => arrayHelpers.push({cause: '', explanation: '', catagory: ''})}>
                          Add Root Cause
                          </Button>
                        </Grid>
                      </Fragment>
                    )}
                  </FieldArray>
                </Grid>
              </Collapse>
              <SubmitConfirmation stage={stage === 1 ? 'success' : ''} text='Root Causes successfully updated.' />
              <Alert in={Boolean(formError)} text={formError} severity='error' />
            </DialogContent>
            {stage === 0 ? (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton variant='text' color='secondary' onClick={close}>Cancel</FormButton>
                <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
              </DialogActions>
            ) : (
              <DialogActions style={{justify: 'space-between'}}>
                <FormButton variant='text' color='primary' onClick={close}>Close</FormButton>
              </DialogActions>
            )}
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

RootCausesModal.propTypes = {
  close: PropTypes.func.isRequired,
  selectedRootCause: PropTypes.object,
};

export default RootCausesModal;

function createDataForFirestore(values) {
  return {
    RootCauses: values.rootCauses.map(formikValues => ({
      Cause: formikValues.cause,
      Catagory: formikValues.catagory,
      Explanation: formikValues.explanation,
    })),
  };
}
