/* eslint-disable max-len */
import {Firestore} from 'config.js';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/firestore';

class ReportGenerator {
  constructor(props) {
    this.inputValues = props.values;
    this.reportType = props.reportType;
    this.reduxValues = props.reduxValues;
    this.queryRef = null; // this will be the queries that are send to firestore. Can either be a single query, or an array of queries.
    this.arrayOfResults = []; // this is the results that are going to be returned to the report modal
    this.subcollectionResults = []; // the subcollectionResults are generated during async queries, which send off queries to other collections to compare results with after all results are returned.
  }

  // the function that is called by the report modal in order to begin the query process.
  async runQuery() {
    switch (this.reportType) {
    case 'CAPA':
      this.generateCapaQuery();
      break;
    default:
      break;
    }

    if (this.queryRef.length) {
      return this.sendArryOfQueries();
    }
    else return this.sendSingleQuery();
  }

  // the below functions send the queries generated by the switch statement in this.runQuery. They set the value of this.arrayOfResults
  // if this.queryRef is a single query, this will fire.
  sendSingleQuery() {
    return this.queryRef.get().then(snap => {
      // snap has two options. either the this.queryRef was a query or collection ref, in which snap will be a snap of all documents,
      // or this.queryRef was a document ref, in which snap will be a document.
      // if snap.exists, that means that snap was a document, and needs to be handled as such. the reason for this was the user entered some ID of something they are looking for
      if (snap.exists) {
        // unneeded section for capa reporting at the moment.

        // otherwise, snap.exists will be undefined. that means that snap is of all documents in a query. handle it as such
      }
      else if (snap.exists === undefined) {
        snap.forEach(doc => {
          let formattedItem = null;
          // the block below formats the data into what the tables are after.
          if (this.reportType === 'CAPA') {
            formattedItem = this.formatCapaItem(doc);
          }
          this.arrayOfResults.push(formattedItem);
        });
      }
      // if snap.exists === false, that means that the user entered an ID they were looking for, but the document does not exist. therefore we will not be setting anything new into this.arrayOfResults
      return this.returnResults();
    });
  }
  // if this.queryRef is an array of queries, this will fire.
  sendArryOfQueries() {
    return Promise.all(this.queryRef.map(query => query.get())).then(arrayOfResultArrays=> {
      this.handleArrayOfQueryResults(arrayOfResultArrays);
      return this.returnResults(this.arrayOfResults);
    });
  }

  // if this.subcollectionResults has any values, this will fire this.filterResultsBySubcollectionResults and filter the results. then it send the filtered results back to modal.
  returnResults() {
    if (this.subcollectionResults.length) {
      this.filterResultsBySubcollectionResults();
    }
    return Promise.resolve(this.arrayOfResults);
  }

  // the below functions assign a single query to this.queryRef, triggering this.sendSingleQuery.
  generateCapaQuery() {
    this.queryRef = Firestore.collection('EHS-CAPA');

    if (this.inputValues.capaNumber) return this.queryRef = this.queryRef.where('CapaNumber', '==', parseInt(this.inputValues.capaNumber));

    if (this.inputValues.completedBeforeDeadline !== '') {
      this.queryRef = this.queryRef.where('CompletedBeforeDeadline', '==', this.inputValues.completedBeforeDeadline);
    }

    if (this.inputValues.active !== '') {
      this.queryRef = this.queryRef.where('Active', '==', this.inputValues.active);
    }

    if (this.inputValues.action) {
      this.queryRef = this.queryRef.where('Action', '==', this.inputValues.action);
    }

    if (this.inputValues.location) {
      this.queryRef = this.queryRef.where('Location', '==', this.inputValues.location);
    }

    if (this.inputValues.priority) {
      this.queryRef = this.queryRef.where('Priority', '==', this.inputValues.priority);
    }

    if (this.inputValues.status) {
      this.queryRef = this.queryRef.where('Status', '==', this.inputValues.status);
    }

    if (this.inputValues.originator) {
      this.queryRef = this.queryRef.where('OriginatorNames', 'array-contains', this.inputValues.originator);
    }

    if (this.inputValues.assignee) {
      this.queryRef = this.queryRef.where('AssigneeNames', 'array-contains', this.inputValues.assignee);
    }
    // creates query for start date
    if (this.inputValues.startDate && !this.inputValues.endDate) {
      // if the user did not put an end date, it sets the query to be all shipments that came in on between midnight to midnight of the start date
      const startDate = firebase.firestore.Timestamp.fromDate(new Date(this.inputValues.startDate));
      const endDate = firebase.firestore.Timestamp.fromDate(
        new Date(
          moment()
            .add(1, 'd')
            .toDate(),
        ),
      );
      this.queryRef = this.queryRef.where('StartDate', '>=', startDate).where('StartDate', '<=', endDate);
    }
    else if (this.inputValues.startDate && this.inputValues.endDate) {
      // if the user put an end date, it grabs all shipments that came in from midnight of start date to midnight of end date.
      const startDate = firebase.firestore.Timestamp.fromDate(new Date(this.inputValues.startDate));
      const endDate = firebase.firestore.Timestamp.fromDate(
        new Date(
          moment(this.inputValues.endDate)
            .add(1, 'd')
            .toDate(),
        ),
      );
      this.queryRef = this.queryRef.where('StartDate', '>=', startDate).where('StartDate', '<=', endDate);
    }

    // creates deadline query
    if (this.inputValues.startDeadline && !this.inputValues.endDeadline) {
      // if the user did not put an end date, it sets the query to be all shipments that came in on between midnight to midnight of the start date
      const startDeadline = firebase.firestore.Timestamp.fromDate(new Date(this.inputValues.startDeadline));
      const endDeadline = firebase.firestore.Timestamp.fromDate(
        new Date(
          moment()
            .add(1, 'd')
            .toDate(),
        ),
      );
      this.queryRef = this.queryRef.where('Deadline', '>=', startDeadline).where('Deadline', '<=', endDeadline);
    }
    else if (this.inputValues.startDeadline && this.inputValues.endDeadline) {
      // if the user put an end date, it grabs all shipments that came in from midnight of start date to midnight of end date.
      const startDeadline = firebase.firestore.Timestamp.fromDate(new Date(this.inputValues.startDeadline));
      const endDeadline = firebase.firestore.Timestamp.fromDate(
        new Date(
          moment(this.inputValues.endDeadline)
            .add(1, 'd')
            .toDate(),
        ),
      );
      this.queryRef = this.queryRef.where('Deadline', '>=', startDeadline).where('Deadline', '<=', endDeadline);
    }
  }

  // the below functions are used to format the data after the return of query data
  handleArrayOfQueryResults(arrayOfResultArrays) {
    // unused for capa reports
  }

  formatCapaItem(capaDoc) {
    return {
      ...capaDoc.data(),
      CapaId: capaDoc.id,
      StartDate: capaDoc.data().StartDate.toDate(),
      Deadline: capaDoc.data().Deadline.toDate(),
      OriginatorsString: capaDoc.data().Originators.map(user => user.displayName).join(', '),
      AssigneesString: capaDoc.data().Assignees.map(user => user.displayName).join(', '),
    };
  }

  // does any comparison needed between this.arrayOfResults, and this.subcollectionResults. once finished filtering, sets this.arrayOfResults to the new filtered values.
  filterResultsBySubcollectionResults() {
    const filteredArray = [...this.arrayOfResults];
    this.subcollectionResults.forEach(result => {
      for (let index = 0; index < filteredArray.length; index++) {
        if (this.reportType === 'inventoryItems') {
          if (filteredArray[index].generatorId !== result &&
            filteredArray[index].PurchaseOrderRef !== result &&
            filteredArray[index].ShippingDocRef !== result) {
            filteredArray.splice(index, 1);
            index--;
          }
        }
        else {
          if (filteredArray[index].id !== result) {
            filteredArray.splice(index, 1);
            index--;
          }
        }
      }
    });
    this.arrayOfResults = filteredArray;
  }
  createObjectMapFromFirestoreDocs(array) {
    const returnedMap = {};
    array.forEach(doc => {
      returnedMap[doc.id] = {...doc.data(), id: doc.id};
    });
    return returnedMap;
  }
}

export default ReportGenerator;
