import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {SubmitConfirmation} from 'components/';
import {Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Grid, MenuItem} from '@material-ui/core';
import {Alert, Collapse, Formik} from '@kbi/component-library';
import * as yup from 'yup';
import {useSelector} from 'react-redux';
import {useAxios} from 'hooks/';
const {FormikForm, SubmitButton, FormButton, TextField, SelectField} = Formik;

const TrainingModal = ({close, selectedRootCause}) => {
  const [stage, setStage] = useState(0);
  const [formError, setFormError] = useState('');

  const currentUser = useSelector(state => state.auth.currentUser);
  const {appEngine, getAuthToken, axios} = useAxios();

  const formikProps = {
    initialValues: {
      trainingRecommended: selectedRootCause.TrainingRecommended === null ? '' : selectedRootCause.TrainingRecommended,
      trainingRequired: selectedRootCause.TrainingRequired === null ? '' : selectedRootCause.TrainingRequired,
    },
    validationSchema: yup.object().shape({
      trainingRequired: yup.boolean().required('Training Required is a required field.'),
      trainingRecommended: yup.string().when('trainingRequired', {
        is: val => Boolean(val),
        then: yup.string().required('Recommended Training is required.'),
        otherwise: yup.string(),
      }),
    }),
    onSubmit: async (values, actions) => {
      try {
        const dataForFirestore = createDataForFirestore(values);

        await axios({
          method: 'post',
          url: `${appEngine}/ehs/firestore/UpdateRootCauseAnalysis`,
          withCredentials: true,
          headers: {
            'Authorization': await getAuthToken(),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          data: {
            formData: dataForFirestore,
            currentUser,
            docId: selectedRootCause.RootCauseId,
          },
        });

        setFormError('');
        setStage(stage + 1);
      }
      catch (err) {
        setFormError('There was an error during submission. Please try again.');
      }
    },
  };

  return (
    <Dialog open={true} maxWidth='md' fullWidth scroll='body' transitionDuration={{exit: 0}}>
      <FormikForm {...formikProps}>
        {({values}) => (
          <Fragment>
            {stage !== 1 && <DialogTitle>Update Training</DialogTitle>}
            <DialogContent>
              <Collapse in={stage === 0}>
                <DialogContentText>
                  Update the training of the root cause analysis:
                </DialogContentText>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <SelectField name='trainingRequired' label='Training Required?' required>
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={8}>
                    <TextField name='trainingRecommended' label='Recommended Training' multiline required={Boolean(values.trainingRequired)} />
                  </Grid>
                </Grid>
              </Collapse>
              <SubmitConfirmation stage={stage === 1 ? 'success' : ''} text='Training successfully updated.' />
              <Alert in={Boolean(formError)} text={formError} severity='error' />
            </DialogContent>
            {stage === 0 ? (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton variant='text' color='secondary' onClick={close}>Cancel</FormButton>
                <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
              </DialogActions>
            ) : (
              <DialogActions style={{justify: 'space-between'}}>
                <FormButton variant='text' color='primary' onClick={close}>Close</FormButton>
              </DialogActions>
            )}
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

TrainingModal.propTypes = {
  close: PropTypes.func.isRequired,
  selectedRootCause: PropTypes.object,
};

export default TrainingModal;

function createDataForFirestore(values) {
  return {
    TrainingRequired: values.trainingRequired,
    TrainingRecommended: values.trainingRecommended,
  };
}
