import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Grid, makeStyles, LinearProgress} from '@material-ui/core';
import {Storage} from 'config.js';
import {ActionPaper} from './ActionView/';

const ActionsView = ({selectedCapa, capaActions, backOnClick}) => {
  const [urlMap, setUrlMap] = useState(null);
  const styles = useStyles();

  useEffect(() => {
    const map = {};
    const arrayOfPromises = [];
    capaActions.forEach(action => {
      map[action.ActionId] = {};
      action.FileNames.forEach(name => {
        arrayOfPromises.push(Storage.ref(`CAPA/${selectedCapa.CapaId}/${action.ActionId}/${name}`).getDownloadURL().then(url => {
          map[action.ActionId][name] = url;
        }));
      },
      );
    });
    Promise.all(arrayOfPromises).then(values => {
      setUrlMap(map);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!urlMap) return <LinearProgress />;

  return <Grid container spacing={2} item xs={12} className={styles.printBlock}>
    {capaActions.sort((a, b) => a.Date < b.Date ? -1 : 1).map(action => {
      // sort arranges them to oldest first
      return (
        <Grid key={action.ActionId} item xs={12}>
          <ActionPaper action={action} urlMap={urlMap} />
        </Grid>);
    })}
  </Grid>;
};

const useStyles = makeStyles(theme => ({
  printBlock: {
    display: 'block',
    breakInside: 'avoid',
    pageBreakInside: 'avoid',
  },
}));

ActionsView.propTypes = {
  capaActions: PropTypes.arrayOf(PropTypes.shape({
    Comment: PropTypes.string.isRequired,
    Date: PropTypes.object.isRequired,
    CreatedBy: PropTypes.string.isRequired,
    FileNames: PropTypes.arrayOf(PropTypes.string),
    ActionId: PropTypes.string.isRequired,
    ActionType: PropTypes.string.isRequired,
    NotifyList: PropTypes.arrayOf(PropTypes.shape({
      uid: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    })).isRequired,
  })),
  backOnClick: PropTypes.func.isRequired,
  selectedCapa: PropTypes.object.isRequired,
};

export default ActionsView;
