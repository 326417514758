import React, {Fragment, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {SubmitConfirmation} from 'components/';
import {useApcLogs, useAxios} from 'hooks/';
import {Dialog, DialogContent, Button, DialogTitle, DialogActions, MenuItem, Grid} from '@material-ui/core';
import * as yup from 'yup';
import {Formik, Alert, Collapse} from '@kbi/component-library';
import moment from 'moment';
import {Firestore} from 'config.js';

const {DateField, FormikForm, TextField, NumberField, WeightField, FormButton, SubmitButton, SelectField} = Formik;
function roundNumber(n) {
  return Math.round(n * 10) / 10;
}

const HerboldCumberlandModal = ({machine, close, selectedLog}) => {
  const [formError, setFormError] = useState('');
  const [stage, setStage] = useState(0);
  const currentUser = useSelector(state => state.auth.currentUser);
  const apcLogs = useApcLogs();
  const {axios, getAuthToken, appEngine} = useAxios();

  const formik = {
    initialValues: {
      date: selectedLog ? moment(selectedLog.Date).format('YYYY-MM-DD') : '',
      meterStart: selectedLog ? selectedLog.MeterStart : apcLogs[machine][0].MeterEnd,
      meterEnd: selectedLog ? selectedLog.MeterEnd : '',
      meterHours: selectedLog ? selectedLog.Hours : 0,
      weightStart: selectedLog ? selectedLog.WeightStart : '',
      weightEnd: selectedLog ? selectedLog.WeightEnd : '',
      weightTotal: selectedLog ? selectedLog.WeightTotal : 0,
      notes: selectedLog ? selectedLog.Notes : '',
      shortNo: selectedLog ? selectedLog.ShortNo : '',
      nonOp: selectedLog ? selectedLog.NonOp : false,
    },
    validationSchema: yup.object().shape({
      date: yup.date().max(new Date(), 'Date cannot be in the future.').required('Date is a required field.'),
      meterStart: yup.number().required('Meter Start is a required field.'),
      meterEnd: yup.number().required('Meter End is a required field.').min(yup.ref('meterStart'), ({min}) => `Must be greater than or equal to ${min}`),
      meterHours: yup.number(),
      weightStart: yup.number().required('Start Weight is a required field.').min(0, 'Start Weight must be a positive number.'),
      weightEnd: yup.number().required('End Weight is a required field.').min(yup.ref('weightStart'), ({min}) => `Must be greater than ${min}`),
      weightTotal: yup.number(),
      shortNo: yup.string().required('Short Number is a required field.').test({
        name: 'existing short no',
        test: async (value) => {
          if (!value) return true;
          const container = await Firestore.collection('Tracking-Containers').doc(value).get();
          if (container.exists) return true;
          else return false;
        },
        message: 'This short number is not in the tracking system. Please verify number.',
      }),
      notes: (() => {
        if (selectedLog) return yup.string();
        else {
          return yup.string().when('meterStart', {
            is: val => val === apcLogs[machine][0].MeterEnd,
            then: yup.string().notRequired(),
            otherwise: yup.string().required('Meter Start does not match Meter End reading from last log. Readings should always be continous. Add comment explaining reason for discrepancy.'), // eslint-disable-line max-len
          }).test({
            name: 'a field was changed',
            test: function(val) {
              if (!val) {
                if (this.parent.nonOp) {
                  if (this.parent.weightStart !== 0 ||
                     this.parent.weightEnd !== 0 ||
                      this.parent.meterStart !== apcLogs[machine][0].MeterEnd ||
                       this.parent.meterEnd !== apcLogs[machine][0].MeterEnd ||
                        this.parent.shortNo !== apcLogs[machine][0].ShortNo) {
                    return false;
                  }
                  else return true;
                }
                else return true;
              }
              else return true;
            },
            message: 'If any field values were changed and the machine did not operate, a note is required.',
          });
        }
      })(),
    }),
    onSubmit: async (values, actions) => {
      const dataToSubmit = {
        Date: moment(values.date).set('hour', 12).set('minutes', 0).toDate(),
        MeterStart: parseFloat(values.meterStart),
        MeterEnd: parseFloat(values.meterEnd),
        Hours: parseFloat(values.meterHours),
        WeightStart: parseInt(values.weightStart),
        WeightEnd: parseInt(values.weightEnd),
        WeightTotal: parseInt(values.weightTotal),
        ShortNo: values.shortNo,
        Notes: values.notes,
        Machine: machine,
        NonOp: values.nonOp,
      };
      selectedLog ? dataToSubmit.System = {
        ...selectedLog.System,
        UpdatedOn: new Date(),
        UpdatedBy: currentUser.displayName,
      } : dataToSubmit.System = {
        CreatedBy: currentUser.displayName,
        CreatedOn: new Date(),
      };

      try {
        await axios({
          method: 'post',
          url: `${appEngine}/ehs/firestore/HerboldCumerlandWrite`,
          withCredentials: true,
          headers: {
            'Authorization': await getAuthToken(),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          data: {
            formId: selectedLog ? selectedLog.logId : null,
            formData: dataToSubmit,
          },
        });
        setFormError('');
        setStage(stage + 1);
      }
      catch (error) {
        setFormError('There was an error during submission. Please try again.');
      }
    },
  };

  const core = {
    dialog: {
      open: true,
      scroll: 'body',
      transitionDuration: {exit: 0},
      maxWidth: 'md',
      fullWidth: true,
    },
    submitConfirmation: {
      text: `${machine} daily log successfully ${selectedLog ? 'updated' : 'created'}.`,
      stage: stage === 1 ? 'success' : 'not success',
    },
    meterChange: {
      onBlur: ({form}) => {
        form.setFieldValue('meterHours', form.values.meterStart && form.values.meterEnd ? roundNumber(parseFloat(form.values.meterEnd) - parseFloat(form.values.meterStart)) : 0);
      },
    },
    weightChange: {
      onBlur: ({form}) => {
        form.setFieldValue('weightTotal', form.values.weightEnd && form.values.weightStart ? parseInt(form.values.weightEnd) - parseInt(form.values.weightStart) : 0);
      },
    },
    nonOpField: {
      disabled: Boolean(selectedLog),
      onChange: ({form, event}) => {
        if (event.target.value) {
          form.setFieldValue('meterStart', apcLogs[machine][0].MeterEnd);
          form.setFieldValue('meterEnd', apcLogs[machine][0].MeterEnd);
          form.setFieldValue('weightStart', 0);
          form.setFieldValue('weightEnd', 0);
          form.setFieldValue('shortNo', apcLogs[machine][0].ShortNo);
        }
        else {
          form.setFieldValue('meterStart', '' );
          form.setFieldValue('meterEnd', '');
          form.setFieldValue('weightStart', '');
          form.setFieldValue('weightEnd', '');
          form.setFieldValue('shortNo', '');
        }
      },
    },
  };

  return (
    <Dialog {...core.dialog}>
      <FormikForm {...formik}>
        {({values}) => (
          <Fragment>
            {stage !== 1 && <DialogTitle>{machine} Daily Log</DialogTitle>}
            <DialogContent>
              <Collapse in={stage === 0}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    {/* This seems backwards, but it allows for the data to be consistant */}
                    <SelectField name='nonOp' label='Operated?' required {...core.nonOpField}>
                      <MenuItem value={false}>True</MenuItem>
                      <MenuItem value={true}>False</MenuItem>
                    </SelectField>
                  </Grid>
                  <Grid item xs={4}>
                    <DateField name='date' label='Date' required />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField name='shortNo' label='Short Number' required />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <NumberField name='meterStart' decimal={1} label='Meter Start' required {...core.meterChange} />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <NumberField name='meterEnd' decimal={1} label='Meter End' required {...core.meterChange} />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <NumberField name='meterHours' decimal={1} label='Total Hours' disabled />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <WeightField name='weightStart' label='Start Weight' required {...core.weightChange} />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <WeightField name='weightEnd' label='End Weight' required {...core.weightChange} />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <WeightField name='weightTotal' label='Total Weight' disabled />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField name='notes' label='Notes' />
                  </Grid>
                </Grid>
              </Collapse>
              <SubmitConfirmation {...core.submitConfirmation} />
              <Alert in={Boolean(formError)} text={formError} severity='error' />
            </DialogContent>
            {stage === 0 ? (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton variant='text' color='secondary' onClick={close}>Cancel</FormButton>
                <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
              </DialogActions>
            ) : (
              <DialogActions style={{justify: 'space-between'}}>
                <Button variant='text' color='primary' onClick={close}>Close</Button>
              </DialogActions>
            )}
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

HerboldCumberlandModal.propTypes = {
  close: PropTypes.func.isRequired,
  machine: PropTypes.string.isRequired,
  selectedLog: PropTypes.object,
};

export default HerboldCumberlandModal;
