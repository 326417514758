import React from 'react';
import {AirPollutionControl, CorrectiveActions, Dashboard, Inspections, Stormwater, Wastewater, CorrectiveActionDetails, RootCauseAnalysis, RootCauseAnalysisDetails, InspectionDetails} from 'views/';
import {StormwaterFormTemplate} from './views/Stormwater/';
import {BubbleChart, Cloud, Dashboard as DashboardIcon, FindInPage, Opacity, PlaylistAddCheck, Timeline} from '@material-ui/icons';

export const routes = [
  {component: () => <Dashboard />, exact: true, strict: true, path: '/dashboard', pageTitle: 'EH&S Dashboard'},
  {component: () => <Wastewater />, exact: true, strict: true, path: '/wastewater', pageTitle: 'Wastewater'},
  {component: () => <Stormwater />, exact: true, strict: true, path: '/stormwater', pageTitle: 'Stormwater'},
  {component: () => <StormwaterFormTemplate />, exact: true, strict: true, path: '/stormwater/comprehensive-stormwater/:id', pageTitle: 'Stormwater'},
  {component: () => <StormwaterFormTemplate />, exact: true, strict: true, path: '/stormwater/non-stormwater-discharge/:id', pageTitle: 'Stormwater'},
  {component: () => <StormwaterFormTemplate />, exact: true, strict: true, path: '/stormwater/predicted-storm/:id', pageTitle: 'Stormwater'},
  {component: () => <StormwaterFormTemplate />, exact: true, strict: true, path: '/stormwater/stormwater-discharge/:id', pageTitle: 'Stormwater'},
  {component: () => <Inspections />, exact: true, strict: true, path: '/inspections', pageTitle: 'Inspections'},
  {component: () => <InspectionDetails />, exact: true, strict: true, path: '/inspections/:id', pageTitle: 'Inspections'},
  {component: () => <CorrectiveActions />, exact: true, strict: true, path: '/corrective-actions', pageTitle: 'Corrective Actions'},
  {component: () => <CorrectiveActionDetails />, exact: true, strict: true, path: '/corrective-actions/search?number=react', pageTitle: 'Corrective Actions'},
  {component: () => <CorrectiveActionDetails />, exact: true, strict: true, path: '/corrective-actions/:capaId', pageTitle: 'Corrective Actions'},
  {component: () => <RootCauseAnalysis />, exact: true, strict: true, path: '/root-cause', pageTitle: 'Root Cause Analysis'},
  {component: () => <RootCauseAnalysisDetails />, exact: true, strict: true, path: '/root-cause/:id', pageTitle: 'Root Cause Analysis'},
  {component: () => <AirPollutionControl />, exact: true, strict: true, path: '/apc', pageTitle: 'Air Pollution Control'},
];

export const menuItems = [
  {icon: () => <DashboardIcon />, text: 'Dashboard', link: '/dashboard'},
  {icon: () => <Opacity />, text: 'Wastewater', link: '/wastewater'},
  {icon: () => <Cloud />, text: 'Stormwater', link: '/stormwater'},
  {icon: () => <FindInPage />, text: 'Inspections', link: '/inspections'},
  {icon: () => <PlaylistAddCheck />, text: 'Corrective Actions', link: '/corrective-actions'},
  {icon: () => <Timeline />, text: 'Root Cause Analysis', link: '/root-cause'},
  {icon: () => <BubbleChart />, text: 'Air Pollution Control', link: '/apc'},
];

export const moduleMenuOptions = [
  {title: 'Entry', path: 'https://auth.kbi.works'},
  {title: 'CRM', path: 'https://crm.kbi.works'},
  {title: 'Tracking', path: 'https://tracking.kbi.works'},
];
