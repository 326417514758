import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acStartCapaListener} from 'state/firestore/actions.js';

const useCapas = () => {
  const dispatch = useDispatch();
  const capas = useSelector(state => state.firestore.capas);

  useEffect(() => {
    if (!capas) dispatch(acStartCapaListener);
  }, [dispatch, capas]);

  return capas;
};

export default useCapas;
