import React, {useState, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography, AccordionActions} from '@material-ui/core';
import {TrainingModal} from './Modals/';
import {StyledTextField} from 'components/';
import {ExpandMore} from '@material-ui/icons';

const TrainingPanel = ({selectedRootCause}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return <Accordion defaultExpanded={false}>
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Typography>Training</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Grid container spacing={2}>
        {selectedRootCause.TrainingRequired !== null ? (
          <Fragment>
            <Grid item xs={4}>
              <StyledTextField value={selectedRootCause.TrainingRequired ? 'Yes' : 'No'} label='Training Required?' />
            </Grid>
            {selectedRootCause.TrainingRecommended ? <Grid item xs={8}>
              <StyledTextField value={selectedRootCause.TrainingRecommended} label='Recommended Training' />
            </Grid> : null}
          </Fragment>
        ) : <Grid item xs={12}><Typography>Training has not been entered yet.</Typography></Grid>}
      </Grid>
    </AccordionDetails>
    <AccordionActions>
      {selectedRootCause.Status !== 'Completed' ? <Button variant='outlined' onClick={() => setModalOpen(true)} color='primary'>Update Training</Button> : null}
    </AccordionActions>
    {modalOpen && <TrainingModal selectedRootCause={selectedRootCause} close={() => setModalOpen(false)} />}
  </Accordion>;
};

TrainingPanel.propTypes = {
  selectedRootCause: PropTypes.object.isRequired,
};

export default TrainingPanel;
