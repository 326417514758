import React from 'react';
import PropTypes from 'prop-types';
import {TableWithPaper, determineIfUserIsManager} from 'components/';
import {Edit, ChevronLeft, ImportExport, Search} from '@material-ui/icons';
import {exportToCSV} from '@kbi/utility-library';
import {useSelector} from 'react-redux';

const TorchHoodDailyTable = ({data=[], openModal, handleBack}) => {
  const currentUser = useSelector(state => state.auth.currentUser);

  const monthlyTableProps = {
    data,
    columns: [
      {Header: 'Date', accessor: 'Date', type: 'datetime'},
      {Header: 'Meter Start', accessor: 'MeterStart', type: 'numeric'},
      {Header: 'Meter End', accessor: 'MeterEnd', type: 'numeric'},
      {Header: 'Total Hours', accessor: 'Hours', type: 'numeric'},
      {Header: 'Total Weight', accessor: 'WeightTotal', type: 'numeric'},
      {Header: 'Containers Used', accessor: 'numberOfContainers', type: 'numeric'},
    ],
    sortInitial: [{id: 'MeterEnd', desc: true}],
    paginationInitialSize: 10,
    title: 'Torchhood Daily Log',
    actionsPerRow: determineIfUserIsManager(currentUser) ? [
      {
        icon: Edit,
        tooltip: 'Edit Log',
        onClick: ({rowData}) => openModal(rowData),
      },
    ] : [
      {
        icon: Search,
        tooltip: 'View Log',
        onClick: ({rowData}) => openModal(rowData),
      },
    ],
    actionsBar: [
      {
        icon: ChevronLeft,
        text: 'Back',
        onClick: handleBack,
      },
      {
        icon: ImportExport,
        text: 'Export Table',
        onClick: () => exportToCSV(data, [
          {Header: 'Date', accessor: 'Date', type: 'datetime'},
          {Header: 'Meter Start', accessor: 'MeterStart', type: 'numeric'},
          {Header: 'Meter End', accessor: 'MeterEnd', type: 'numeric'},
          {Header: 'Total Hours', accessor: 'Hours', type: 'numeric'},
          {Header: 'Total Weight', accessor: 'WeightTotal', type: 'numeric'},
          {Header: 'Containers Used', accessor: 'numberOfContainers', type: 'numeric'},
          {Header: 'All Short No\'s', accessor: 'stringOfShortNumbers'},
        ]),
      },
    ],
  };

  return (
    <TableWithPaper {...monthlyTableProps} />
  );
};

TorchHoodDailyTable.propTypes = {
  data: PropTypes.array,
  openModal: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
};

export default TorchHoodDailyTable;
