import React, {useState, useEffect} from 'react';
import {DetailsPanel} from '@kbi/component-library';
import {Grid, LinearProgress, Button, makeStyles} from '@material-ui/core';
import {EventAvailable, ChevronLeft, Print} from '@material-ui/icons';
import moment from 'moment';
import {useSelector} from 'react-redux';
import {useParams, useLocation} from 'react-router-dom';
import {useCapas, useUsers} from 'hooks/';
import {ActionsTakenPanel, ActionsView, ObservationPanel, AttachedInspectionsPanel} from './CorrectiveActionsDetails/';
import {ActionTakenModal} from './CorrectiveActionsDetails/ActionsTakenPanel/';
import {RootCauseModal} from './RootCauseAnalysis/';
import {Firestore} from 'config.js';
import {useHistory} from 'react-router-dom';

// https://reactrouter.com/web/example/query-parameters
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const CorrectiveActionDetails = props => {
  const styles = useStyles();
  const [selectedCapa, setSelectedCapa] = useState(null);
  const [actionTakenModalType, setActionTakenModalType] = useState('');
  const [actionsViewOpen, setActionsViewOpen] = useState(false);
  const [rootCauseModalOpen, setRootCauseModalOpen] = useState(false);
  const [capaActions, setCapaActions] = useState(null);

  const currentUser = useSelector(state => state.auth.currentUser);
  const params = useParams();
  const query = useQuery();
  const capas = useCapas();
  const history = useHistory();
  useUsers();

  useEffect(() => {
    // capa listener hasnt been established
    if (!capas) return;
    // capa listener up, waiting for first response and redux update
    else if (capas.ref) {
      // capas in redux, if the capa that was navigated to is in redux, set it as selected
      if (capas.ref[params.capaId]) {
        return setSelectedCapa(capas.ref[params.capaId]);
      }
      else { // otherwise, make an listener to firestore and set that as the selected. used for inactive capas
        if (query.get('number')) {
        // this listener will be set up if the page was reached by an inspection, querying capas by their unique number
          const listenerByQuery = Firestore.collection('EHS-CAPA').where('CapaNumber', '==', parseInt(query.get('number'))).onSnapshot(snap => {
            if (snap.empty) return null;
            else {
              const docOfCapaByNumber = snap.docs[0];
              const docData = {
                ...docOfCapaByNumber.data(),
                CapaId: docOfCapaByNumber.id,
                StartDate: docOfCapaByNumber.data().StartDate.toDate(),
                Deadline: docOfCapaByNumber.data().Deadline.toDate(),
                OriginatorsString: docOfCapaByNumber.data().Originators.map(user => user.displayName).join(', '),
                AssigneesString: docOfCapaByNumber.data().Assignees.map(user => user.displayName).join(', '),
              };
              setSelectedCapa(docData);
            }
            return () => listenerByQuery();
          });
        }
        else if (params.capaId) {
          // this listener will be set up if the url designates the id of the capa as the parameter.
          const individualListener = Firestore.collection('EHS-CAPA').doc(params.capaId).onSnapshot(doc => {
            const docData = {
              ...doc.data(),
              CapaId: doc.id,
              StartDate: doc.data().StartDate.toDate(),
              Deadline: doc.data().Deadline.toDate(),
              OriginatorsString: doc.data().Originators.map(user => user.displayName).join(', '),
              AssigneesString: doc.data().Assignees.map(user => user.displayName).join(', '),
            };
            setSelectedCapa(docData);
          });
          return () => individualListener();
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capas, params]);

  useEffect(() => {
    // setting up listener for actions
    if (selectedCapa) {
      const subscription = Firestore.collection('EHS-CAPA').doc(selectedCapa.CapaId).collection('Actions').onSnapshot(snap => {
        const docs = snap.docs.map(doc => ({
          ...doc.data(),
          ActionId: doc.id,
          Date: doc.data().Date.toDate(),
          NotifyListString: doc.data().NotifyList.map(user => user.displayName).join(', '),
          NotifyListLength: doc.data().NotifyList.length,
          NewDeadline: doc.data().NewDeadline?.toDate() || null,
        }));
        setCapaActions(docs);
      });
      return () => {
        subscription();
      };
    }
  }, [selectedCapa]);


  if (!selectedCapa) return <LinearProgress />;

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} md={3} className='hidePrint'>
        <DetailsPanel title={`CAPA ${selectedCapa.CapaNumber}`}
          subtitle={selectedCapa.Status}
          icon={EventAvailable}
          fields={[
            {label: 'Start Date', value: moment(selectedCapa.StartDate).format('MM/DD/YYYY')},
            {label: 'Original Deadline', value: moment(selectedCapa.OriginalDeadline?.toDate()).format('MM/DD/YYYY'), visible: selectedCapa.OriginalDeadline},
            {label: 'Deadline', value: moment(selectedCapa.Deadline).format('MM/DD/YYYY')},
            {label: 'Location', value: selectedCapa.Location},
            {label: 'Action', value: selectedCapa.Action},
            {label: 'Originators', value: selectedCapa.OriginatorsString},
            {label: 'Assignees', value: selectedCapa.AssigneesString},
            {label: 'Priority', value: selectedCapa.Priority},
            {
              label: 'Root Cause Analysis Id',
              value: selectedCapa.RootCauseAnalysisRef || '',
              visible: Boolean(selectedCapa.RootCauseAnalysisRef),
              textFieldProps: {
                onClick: () => history.push(`/root-cause/${selectedCapa.RootCauseAnalysisRef}`),
                className: styles.root,
              },
            },
          ]}
          buttons={[
            {text: 'Update Deadline', onClick: () => setActionTakenModalType('deadline'), visible: selectedCapa.Active},
            {text: 'Create Root Cause Analysis', onClick: () => setRootCauseModalOpen(true), visible: !selectedCapa.Active},
            {
              text: 'Verify CAPA',
              onClick: () => setActionTakenModalType('verification'),
              visible: selectedCapa.Status === 'Pending Verification' && isCurrentUserAnOriginator(selectedCapa.Originators, currentUser),
            },
          ]}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <Grid item xs={12} container spacing={2}>

          {actionsViewOpen && <Grid item xs={12} className='hidePrint'>
            <Button variant='outlined' onClick={() => setActionsViewOpen(false)}><ChevronLeft className={styles.extendedIcon} />Back</Button>
            <Button className={styles.marginLeft} variant='outlined' onClick={() => window.print()}><Print className={styles.extendedIcon} />Print</Button>
          </Grid>
          }
          <Grid item xs={12}>
            <ObservationPanel selectedCapa={selectedCapa} />
          </Grid>

          {selectedCapa?.InspectionRefs?.length ? <Grid item xs={12}>
            <AttachedInspectionsPanel selectedCapa={selectedCapa} />
          </Grid> : null}

          {actionsViewOpen ?
            <ActionsView selectedCapa={selectedCapa} capaActions={capaActions} /> :
            <Grid item xs={12}>
              <ActionsTakenPanel selectedCapa={selectedCapa} setActionsViewOpen={bool => setActionsViewOpen(bool)} capaActions={capaActions} />
            </Grid>
          }
        </Grid>

      </Grid>
      {Boolean(actionTakenModalType) && <ActionTakenModal selectedCapa={selectedCapa} close={() => setActionTakenModalType('')} type={actionTakenModalType} />}
      {rootCauseModalOpen && <RootCauseModal close={() => setRootCauseModalOpen(false)} selectedCapa={selectedCapa} />}
    </Grid>
  );
};

function isCurrentUserAnOriginator(listOfOriginators, currentUser) {
  if (listOfOriginators.find(user => user.uid === currentUser.uid)) return true;
  else return false;
}

const useStyles = makeStyles(theme => ({
  'extendedIcon': {
    marginRight: '8px',
  },
  'marginLeft': {
    marginLeft: '8px',
  },
  'printBlock': {
    display: 'block',
  },
  'root': {
    'backgroundColor': 'white',
    'cursor': 'pointer',
    'fontSize': '0.9rem',
    '& div': {
      cursor: 'pointer',
    },
    '& textarea': {
      color: '#0000EE !important',
      cursor: 'pointer',
    },
  },
}));

export default CorrectiveActionDetails;
