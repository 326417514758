import React from 'react';
import PropTypes from 'prop-types';
import {TableWithPaper, determineIfUserIsManager} from 'components/';
import {Edit, ChevronLeft, ImportExport} from '@material-ui/icons';
import {exportToCSV} from '@kbi/utility-library';
import {useSelector} from 'react-redux';

const HerboldCumberlandLogTable = ({data=[], title, openModal, handleBack}) => {
  const currentUser = useSelector(state => state.auth.currentUser);

  const logTableProps = {
    data,
    columns: [
      {Header: 'Date', accessor: 'Date', type: 'datetime'},
      {Header: 'Meter Start', accessor: 'MeterStart', type: 'numeric'},
      {Header: 'Meter End', accessor: 'MeterEnd', type: 'numeric'},
      {Header: 'Total Hours', accessor: 'Hours', type: 'numeric'},
      {Header: 'Start Weight', accessor: 'WeightStart', type: 'numeric'},
      {Header: 'End Weight', accessor: 'WeightEnd', type: 'numeric'},
      {Header: 'Total Weight', accessor: 'WeightTotal', type: 'numeric'},
      {Header: 'Short No.', accessor: 'ShortNo'},
    ],
    sortInitial: [{id: 'MeterEnd', desc: true}],
    title,
    actionsBar: [
      {
        icon: ChevronLeft,
        text: 'Back',
        onClick: handleBack,
      },
      {
        icon: ImportExport,
        text: 'Export Table',
        onClick: () => exportToCSV(data, [
          {Header: 'Date', accessor: 'Date', type: 'datetime'},
          {Header: 'Meter Start', accessor: 'MeterStart', type: 'numeric'},
          {Header: 'Meter End', accessor: 'MeterEnd', type: 'numeric'},
          {Header: 'Total Hours', accessor: 'Hours', type: 'numeric'},
          {Header: 'Start Weight', accessor: 'WeightStart', type: 'numeric'},
          {Header: 'End Weight', accessor: 'WeightEnd', type: 'numeric'},
          {Header: 'Total Weight', accessor: 'WeightTotal', type: 'numeric'},
          {Header: 'Short No.', accessor: 'ShortNo'},
        ]),
      },
    ],
  };
  if (determineIfUserIsManager(currentUser)) {
    logTableProps.actionsPerRow = [
      {
        icon: Edit,
        tooltip: 'Edit Log',
        onClick: ({rowData}) => openModal(rowData),
      },
    ];
  }

  return (
    <TableWithPaper {...logTableProps} />
  );
};

HerboldCumberlandLogTable.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string.isRequired,
  openModal: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
};

export default HerboldCumberlandLogTable;
