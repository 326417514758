import React, {useState} from 'react';
import {TableWithPaper} from 'components/';
import {LinearProgress, Grid} from '@material-ui/core';
import {Add, Search} from '@material-ui/icons';
import {useHistory} from 'react-router-dom';
import {RootCauseModal} from './RootCauseAnalysis/';
import {useUsers, useRootCauses} from 'hooks/';

const RootCauseAnalysis = () => {
  const [newRootCauseModalOpen, setNewRootCauseModalOpen] = useState(false);

  useUsers();
  const rootCauses = useRootCauses();
  const history = useHistory();

  const rootCauseTable = {
    data: rootCauses?.list || [],
    columns: [
      {Header: 'Start Date', accessor: 'StartDate', type: 'datetime', datetimeFormat: 'MM/DD/YYYY'},
      {Header: 'Status', accessor: 'Status'},
      {Header: 'Analysis', accessor: 'Analysis'},
    ],
    sortInitial: [{id: 'StartDate', desc: true}],
    paginationInitialSize: 10,
    actionsBar: [
      {
        icon: Add,
        text: 'Add New Analysis',
        onClick: () => setNewRootCauseModalOpen(true),
      },
    ],
    actionsPerRow: [{
      icon: Search,
      tooltip: 'View Root Cause Analysis',
      onClick: ({rowData}) => history.push(`/root-cause/${rowData.RootCauseId}`),
    }],
    title: 'Root Cause Analyses',
    paperProps: {
      style: {
        margin: '0px',
      },
    },
  };

  if (!rootCauses || !rootCauses.list) return <LinearProgress />;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <TableWithPaper {...rootCauseTable} />
      </Grid>

      {newRootCauseModalOpen && <RootCauseModal close={() => setNewRootCauseModalOpen(false)} />}
    </Grid>
  );
};

export default RootCauseAnalysis;
