import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import get from 'lodash.get';


const useModuleAccessCheck = () => {
  const currentUser = useSelector(state => state.auth.currentUser);
  const history = useHistory();

  useEffect(() => {
    const roleEHS = get(currentUser, 'roleEHS', 'No Access');
    if (currentUser && roleEHS === 'No Access') {
      alert(`You do not have access to KBI's EHS module. Contact IT if you would like to request access.`);
      window.location.href = 'https://auth.kbi.works';
    }
  }, [currentUser, history]);
};

export default useModuleAccessCheck;
