import React, {Fragment, useState} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {SubmitConfirmation} from 'components/';
import {
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  DialogActions,
  MenuItem,
  Grid,
} from '@material-ui/core';
import * as yup from 'yup';
import {Formik, Alert, Collapse} from '@kbi/component-library';
import {useAxios} from 'hooks/';
import {useSelector} from 'react-redux';

const {FormikForm, FormButton, SubmitButton, SelectField} = Formik;

const NewInspectionModal = ({close}) => {
  const [formError, setFormError] = useState('');
  const [stage, setStage] = useState(0);
  const [newFormUrl, setNewFormUrl] = useState(null);

  const currentUser = useSelector(state => state.auth.currentUser);
  const history = useHistory();
  const {axios, getAuthToken, appEngine} = useAxios();

  const formik = {
    initialValues: {
      inspectionName: '',
    },
    validationSchema: yup.object().shape({
      inspectionName: yup.string().required('Form Name is a required field.'),
    }),
    onSubmit: async (values, actions) => {
      try {
        const dataForFirestore = createFirestoreDocData(values.inspectionName, currentUser);

        const submitResponse = await axios({
          method: 'post',
          url: `${appEngine}/ehs/firestore/NewInspection`,
          withCredentials: true,
          headers: {
            'Authorization': await getAuthToken(),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          data: dataForFirestore,
        });

        setNewFormUrl(`/inspections/${submitResponse.data.formId}`);
        setFormError('');
        setStage(stage + 1);
      }
      catch (err) {
        setFormError('There was an error during submission. Please try again.');
      }
    },
  };

  const core = {
    dialog: {
      open: true,
      scroll: 'body',
      transitionDuration: {exit: 0},
      maxWidth: 'sm',
      fullWidth: true,
    },
    submitConfirmation: {
      text: 'Inspection successfully created. You will be redirected there upon close.',
      stage: stage === 1 ? 'success' : 'not success',
    },
  };

  return (
    <Dialog {...core.dialog}>
      <FormikForm {...formik}>
        {() => (
          <Fragment>
            {stage !== 1 && <DialogTitle>Select Inspection to Create</DialogTitle>}
            <DialogContent>
              <Collapse in={stage === 0}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SelectField name='inspectionName' label='Inspection Name' required>
                      <MenuItem value='Daily Catalyst Area'>Daily Catalyst Area</MenuItem>
                      <MenuItem value='Daily Lead Acid Battery Area'>Daily Lead Acid Battery Area</MenuItem>
                      <MenuItem value='Daily Maintenance Area'>Daily Maintenance Area</MenuItem>
                      <MenuItem value='Daily Non-Lead Area'>Daily Non-Lead Area</MenuItem>
                      <MenuItem value='Daily Precious Metals Area'>Daily Precious Metals Area</MenuItem>
                      <MenuItem value='Daily Shipping & Receiving Area'>Daily Shipping & Receiving Area</MenuItem>
                      <MenuItem value='Daily Wastewater Treatment Area'>Daily Wastewater Treatment Area</MenuItem>
                      <MenuItem value='Weekly Eyewash'>Weekly Eyewash</MenuItem>
                      <MenuItem value='Monthly Emergency Equipment'>Monthly Emergency Equipment</MenuItem>
                      <MenuItem value='Monthly Enclosure'>Monthly Enclosure</MenuItem>
                      <MenuItem value='Monthly Security'>Monthly Security</MenuItem>
                      <MenuItem value='Quarterly Duct'>Quarterly Duct</MenuItem>
                    </SelectField>
                  </Grid>
                </Grid>
              </Collapse>
              <SubmitConfirmation {...core.submitConfirmation} />
              <Alert in={Boolean(formError)} text={formError} severity='error' />
            </DialogContent>
            {stage === 0 ? (
              <DialogActions style={{justifyContent: 'space-between'}}>
                <FormButton variant='text' color='secondary' onClick={close}>Cancel</FormButton>
                <SubmitButton variant='text' color='primary'>Submit</SubmitButton>
              </DialogActions>
            ) : (
              <DialogActions style={{justify: 'space-between'}}>
                <Button variant='text' color='primary' onClick={() => history.push(newFormUrl)}>Close</Button>
              </DialogActions>
            )}
          </Fragment>
        )}
      </FormikForm>
    </Dialog>
  );
};

function createFirestoreDocData(inspectionName, currentUser) {
  const [title, version, frequency] = determineFormTitleVersionAndFrequency(inspectionName);
  const dataForFirestore = {
    FormTitle: title,
    FormVersion: version,
    Frequency: frequency,
    currentUser,
  };
  return dataForFirestore;
}
// this must be updated when a form gets a new version number.
function determineFormTitleVersionAndFrequency(inspectionName) {
  switch (inspectionName) {
  case 'Daily Catalyst Area':
    return ['Daily Catalyst Area Inspection', 1, 0];
  case 'Daily Lead Acid Battery Area':
    return ['Daily Lead Acid Battery Area Inspection', 1, 0];
  case 'Daily Maintenance Area':
    return ['Daily Maintenance Area Inspection', 1, 0];
  case 'Daily Non-Lead Area':
    return ['Daily Non-Lead Area Inspection', 1, 0];
  case 'Daily Precious Metals Area':
    return ['Daily Precious Metals Area Inspection', 1, 0];
  case 'Daily Shipping & Receiving Area':
    return ['Daily Shipping & Receiving Area Inspection', 1, 0];
  case 'Daily Wastewater Treatment Area':
    return ['Daily Wastewater Treatment Area Inspection', 1, 0];
  case 'Weekly Eyewash':
    return ['Weekly Eyewash Inspection', 1, 7];
  case 'Monthly Emergency Equipment':
    return ['Monthly Emergency Equipment Inspection', 1, 30];
  case 'Monthly Enclosure':
    return ['Monthly Enclosure Inspection', 1, 30];
  case 'Monthly Security':
    return ['Monthly Security Inspection', 1, 30];
  case 'Quarterly Duct':
    return ['Quarterly Duct Inspection', 1, 90];
  default:
    break;
  }
};

NewInspectionModal.propTypes = {
  close: PropTypes.func.isRequired,
};

export default NewInspectionModal;
