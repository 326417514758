import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {CircularProgress, Button} from '@material-ui/core';
import {Delete, AddCircle, Check} from '@material-ui/icons';
import green from '@material-ui/core/colors/green';

class SubmitButton extends React.Component {
  handleButtonClick = e => {
    if (this.props.onClick && typeof this.props.onClick === 'function') {
      this.props.onClick(e);
    }
  };
  checkIfDisabled() {
    const {loading, disabled} = this.props;
    if (loading || disabled) {
      return true;
    }
    else return false;
  }
  determineIcon() {
    const {icon, classes} = this.props;
    if (icon === 'Delete') {
      return <Delete className={classes.extendedIcon} />;
    }
    else if (icon === 'AddCircle') {
      return <AddCircle className={classes.extendedIcon} />;
    }
    else if (icon === 'Check') {
      return <Check className={classes.extendedIcon} />;
    }
  }
  render() {
    const {loading, classes, text, color, variant, style, size, className, type = 'submit'} = this.props;

    const core = {
      submitButton: {
        size: size || 'medium',
        color: color || 'default',
        variant: variant || 'text',
        disabled: this.checkIfDisabled(),
        type: type,
        className: className || '',
        style: {...style},
        onClick: e => this.handleButtonClick(e),
      },
    };

    return (
      <Button {...core.submitButton}>
        {this.determineIcon()}
        {text} {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </Button>
    );
  }
}

const styles = theme => ({
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
});

SubmitButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  text: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
  variant: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
};

export default withStyles(styles)(SubmitButton);
