import React, {useState, Fragment} from 'react';
import {TopNavBar, HerboldView, CumberlandView, TorchHoodView} from './AirPollutionControl/';
import {Collapse} from '@kbi/component-library';
import {useApcLogs} from '../hooks/';

const AirPollutionControl = () => {
  const [viewIndex, setViewIndex] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const apcLogs = useApcLogs();

  return (
    <Fragment>
      <TopNavBar viewIndex={viewIndex} setViewIndex={(newIndex) => setViewIndex(newIndex)} />
      <Collapse in={viewIndex === 0}>
        <CumberlandView />
      </Collapse>
      <Collapse in={viewIndex === 1}>
        <HerboldView />
      </Collapse>
      <Collapse in={viewIndex === 2}>
        <TorchHoodView />
      </Collapse>
    </Fragment>
  );
};

export default AirPollutionControl;
